import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  getSaleReports,
  nightAuditReportService,
  getBookingListReportsService,
  getRoomChangeReportService,
  getOccupancyReportService,
  getSalesByCustomerReportService,
  getBookingSourceReportService,
  getTaxDetailsReportService,
  getPayableReportService,
  getReceivableReportService,
  getPaymentReportService,
  getVendorSummaryReportService,
} from "../services/reports";
import { message } from "antd";

export const getReport = createAsyncThunk(
  "report",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getSaleReports(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }
      return error;
    }
  }
);

export const getNightAuditReport = createAsyncThunk(
  "nightAuditReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await nightAuditReportService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getBookingListReport = createAsyncThunk(
  "bookingListReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getBookingListReportsService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getRoomChangeReport = createAsyncThunk(
  "getRoomChangeReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/roomChange";
      const response = await getRoomChangeReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getOccupancyReport = createAsyncThunk(
  "getOccupancyReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/occupancy";
      const response = await getOccupancyReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getSalesByCustomerReport = createAsyncThunk(
  "getSalesByCustomerReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/sales/customer";
      const response = await getSalesByCustomerReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getBookingSourceReport = createAsyncThunk(
  "getBookingSourceReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/bookingSource";
      const response = await getBookingSourceReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getTaxDetailsReport = createAsyncThunk(
  "getTaxDetailsReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/taxDetails";
      const response = await getTaxDetailsReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getPayableReport = createAsyncThunk(
  "getPayableReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/payable";
      const response = await getPayableReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getReceivableReport = createAsyncThunk(
  "getReceivableReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/receivable";
      const response = await getReceivableReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getPaymentsReport = createAsyncThunk(
  "getPaymentsReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/payment-report";
      const response = await getPaymentReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getVendorSummaryReport = createAsyncThunk(
  "getVendorSummaryReport",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const endpoint = "report/vendor-summary";
      const response = await getVendorSummaryReportService(endpoint, payload);
      return fulfillWithValue(response);
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false,
  response: {},
  apiError: null,
  startDate: moment().startOf("day"),
  endDate: moment().endOf("day"),
  showNoReport: true,
  showSalesReport: false,
  showOnGoingReport: false,
  showUpcomingReport: false,
  showCompletedReport: false,
  showNoShowReport: false,
  showRoomChangeReport: false,
  showOccupancyReport: false,
  showEarlyCheckinReport: false,
  showLateCheckoutReport: false,
  showGrcReport: false,
  showBookingSourceReport: false,
  showRateReport: false,
  showCancelledReport: false,
  showItemWiseReport: false,
  showCategoryWiseReport: false,
  showSourceWiseReport: false,
  showNightAuditReport: false,
  csvData: [],
  nightAuditResponse: {},
  nightAuditLoading: false,
  nightAuditError: null,
  isExportButtonExpanded: false,

  showStockReport: false,
  showOrderSourceReport: false,

  showSalesByItemReport: false,
  showSalesByCustomerReport: false,
  showSalesBySalesPersonReport: false,

  showVendor: false,
  ShowPurchaseByProduct: false,
  showPurchaseByCategory: false,
  showEmployee: false,
  showRateComparisonReport: false,
  showVendorSummaryReport: false,

  showPaymentsReport: false,
  showReceivableReport: false,
  showPayableReport: false,
  showRefundReport: false,
  showPaymentTypeSourceReport: false,

  // tax report
  showTaxDetailsReport: false,

  //
  fromDate: moment().startOf("day").valueOf(),
  toDate: moment().startOf("day").valueOf(),
  checkIn: new Date(),
  checkOut: new Date(),
  reportsVisibleCalendar: false,
  //

  // BookingList Of Report
  lodingReport: false,
  bookingListOfreport: [],
  apiErrorReport: null,

  // infinate scrolling
  pageNumber: 0,
  infinateScroll: false,
  infinateScrollLoading: false,

  // room change report
  roomChangeReportResponse: [],
  roomChangeReportLoading: false,
  roomChangeReportError: null,

  // room change report
  occupancyReportResponse: [],
  occupancyReportLoading: false,
  occupancyReportError: null,

  // sales by customer report
  salesByCustomerReportResponse: [],
  salesByCustomerReportLoading: false,
  salesByCustomerReportError: null,

  // booking source report
  bookingSourceReportResponse: [],
  bookingSourceReportLoading: false,
  bookingSourceReportError: null,

  // tax details report
  taxDetailsReportResponse: [],
  taxDetailsReportLoading: false,
  taxDetailsReportError: null,

  // payable report
  payableReportResponse: [],
  payableReportLoading: false,
  payableReportError: null,

  // receivable report
  receivableReportResponse: [],
  receivableReportLoading: false,
  receivableReportError: null,

  // source filter
  sourceFilter: "",

  // filter in tax report
  invoiceTypeFilter: "All",
  posIdFilter: "",

  // payment report
  paymentReportResponse: [],
  paymentReportLoading: false,
  paymentReportError: null,

  paymentModeFilter: "All",

  // vendor summary report
  vendorSummaryReportResponse: [],
  vendorSummaryReportLoading: false,
  vendorSummaryReportError: null,

  expenseAccountFilter: "",
  vendorNameFilter: "",
  taxIncludedInPriceFilter: "noFilter",
  partyFilterName: "",
  partyFilterId: "",
  isPartyInputFocused: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_36: () => initialState,

    updateVendorNameFilter: (state, action) => {
      state.vendorNameFilter = action.payload;
    },
    updateExpenseAccountFilter: (state, action) => {
      state.expenseAccountFilter = action.payload;
    },
    updatePaymentModeFilter: (state, action) => {
      state.paymentModeFilter = action.payload;
    },
    updateInvoiceTypeFilter: (state, action) => {
      state.invoiceTypeFilter = action.payload;
    },

    updatePosIdFilter: (state, action) => {
      state.posIdFilter = action.payload;
    },

    updateTaxIncludedInPriceFilter: (state, action) => {
      state.taxIncludedInPriceFilter = action.payload;
    },

    updateSourceFilter: (state, action) => {
      state.sourceFilter = action.payload;
    },

    updateReportDates: (state, actions) => {
      if (actions.payload.from) {
        state.fromDate = actions.payload.from;
        state.toDate = actions.payload.to;
      } else if (actions.payload.to) {
        state.toDate = actions.payload.to;
      }
    },
    updateInFinateScroll: (state, action) => {
      state.infinateScroll = action.payload;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },

    updatePageNo: (state, action) => {
      state.pageNumber = action.payload;
    },

    closeAllReports: (state, action) => {
      state.csvData = [];

      state.showSalesReport = false;
      state.showOnGoingReport = false;
      state.showUpcomingReport = false;
      state.showCompletedReport = false;
      state.showNoShowReport = false;
      state.showRoomChangeReport = false;
      state.showOccupancyReport = false;
      state.showEarlyCheckinReport = false;
      state.showLateCheckoutReport = false;
      state.showGrcReport = false;
      state.showBookingSourceReport = false;
      state.showRateReport = false;
      state.showCancelledReport = false;

      state.showItemWiseReport = false;
      state.showCategoryWiseReport = false;
      state.showSourceWiseReport = false;
      state.showNoReport = false;
      state.showNightAuditReport = false;
      state.showOrderSourceReport = false;
      state.showStockReport = false;
      state.showSalesByItemReport = false;
      state.showSalesByCustomerReport = false;
      state.showSalesBySalesPersonReport = false;

      state.showVendor = false;
      state.ShowPurchaseByProduct = false;
      state.showPurchaseByCategory = false;
      state.showPurchaseByCategory = false;
      state.showEmployee = false;
      state.showRateComparisonReport = false;
      state.showVendorSummaryReport = false;

      state.showPaymentsReport = false;
      state.showReceivableReport = false;
      state.showPayableReport = false;
      state.showRefundReport = false;
      state.showPaymentTypeSourceReport = false;

      state.showTaxDetailsReport = false;

      // state.fromDate = moment().startOf("day");
      // state.toDate = moment().startOf("day");
      // state.startDate = moment().startOf("day");
      // state.endDate = moment().endOf("day");

      state.sourceFilter = "";
      state.invoiceTypeFilter = "All";
      state.posIdFilter = "";
      state.paymentModeFilter = "All";
      state.sourceFilter = "";
      state.expenseAccountFilter = "";
      state.vendorNameFilter = "";

      state.partyFilterName = "";
      state.partyFilterId = "";
      state.isPartyInputFocused = false;
    },
    openNoReport: (state, action) => {
      state.showNoReport = true;
    },
    openSalesReport: (state, action) => {
      state.showSalesReport = true;
    },
    openOnGoingReport: (state, action) => {
      state.showOnGoingReport = true;
    },
    openUpcomingReport: (state, action) => {
      state.showUpcomingReport = true;
    },
    openCompletedReport: (state, action) => {
      state.showCompletedReport = true;
    },
    openNoShowReport: (state, action) => {
      state.showNoShowReport = true;
    },
    openRoomChangeReport: (state, action) => {
      state.showRoomChangeReport = true;
    },
    openOccupancyReport: (state, action) => {
      state.showOccupancyReport = true;
    },
    openEarlyCheckinReport: (state, action) => {
      state.showEarlyCheckinReport = true;
    },
    openLateCheckoutReport: (state, action) => {
      state.showLateCheckoutReport = true;
    },
    openGrcReport: (state, action) => {
      state.showGrcReport = true;
    },
    openBookingSourceReport: (state, action) => {
      state.showBookingSourceReport = true;
    },
    openRateReport: (state, action) => {
      state.showRateReport = true;
    },
    openCancelledReport: (state, action) => {
      state.showCancelledReport = true;
    },
    openItemWiseReport: (state, action) => {
      state.showItemWiseReport = true;
    },
    openCategoryWiseReport: (state, action) => {
      state.showCategoryWiseReport = true;
    },
    openSourceWiseReport: (state, action) => {
      state.showSourceWiseReport = true;
    },
    openNightAuditReport: (state, action) => {
      state.showNightAuditReport = true;
    },
    openStockReport: (state, action) => {
      state.showStockReport = true;
    },
    openOrderSourceReport: (state, action) => {
      state.showOrderSourceReport = true;
    },
    openSalesByCustomersReport: (state, action) => {
      state.showSalesByCustomerReport = true;
    },
    openSalesByItemReport: (state, action) => {
      state.showSalesByItemReport = true;
    },
    openSalesBySalesPersonReport: (state, action) => {
      state.showSalesBySalesPersonReport = true;
    },

    openVendorReport: (state, action) => {
      state.showVendor = true;
    },
    openPurchaseByProduct: (state, action) => {
      state.ShowPurchaseByProduct = true;
    },
    openPurchaseByCategory: (state, action) => {
      state.showPurchaseByCategory = true;
    },
    openRateComparisonReport: (state, action) => {
      state.showRateComparisonReport = true;
    },
    openVendorSummaryReport: (state, action) => {
      state.showVendorSummaryReport = true;
    },
    openEmployee: (state, action) => {
      state.showEmployee = true;
    },

    openPaymentsReport: (state, action) => {
      state.showPaymentsReport = true;
    },

    openReceivableReport: (state, action) => {
      state.showReceivableReport = true;
    },
    openPayableReport: (state, action) => {
      state.showPayableReport = true;
    },
    openRefundReport: (state, action) => {
      state.showRefundReport = true;
    },
    openPaymentTypeSourceReport: (state, action) => {
      state.showPaymentTypeSourceReport = true;
    },

    openTaxDetailsReport: (state, action) => {
      state.showTaxDetailsReport = true;
      state.fromDate = moment().startOf("month");
      state.toDate = moment().endOf("month");
      state.startDate = moment().startOf("month");
      state.endDate = moment().endOf("month");
    },

    updateCsvData: (state, action) => {
      state.csvData = action.payload;
    },
    // updateReportsVisibleCalendar: (state, action) => {
    //   state.reportsVisibleCalendar = action.payload;
    // },
    setIsExportButtonExpanded: (state, action) => {
      state.isExportButtonExpanded = action.payload;
    },
    updatePartyFilterName: (state, action) => {
      state.partyFilterName = action.payload;
    },
    updatePartyFilterId: (state, action) => {
      state.partyFilterId = action.payload;
    },
    setIsPartyInputFocused: (state, action) => {
      state.isPartyInputFocused = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state, action) => {
      state.loading = true;
      state.apiError = false;
      state.response = {};
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.apiError = false;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.loading = false;
      state.apiError = true;
      state.response = {};
    });
    builder.addCase(getNightAuditReport.pending, (state, action) => {
      state.nightAuditLoading = true;
      state.nightAuditError = false;
      state.nightAuditResponse = {};
    });
    builder.addCase(getNightAuditReport.fulfilled, (state, action) => {
      state.nightAuditLoading = false;
      state.nightAuditResponse = action.payload;
      state.nightAuditError = false;
    });
    builder.addCase(getNightAuditReport.rejected, (state, action) => {
      state.nightAuditLoading = false;
      state.nightAuditError = true;
      state.nightAuditResponse = {};
    });

    builder.addCase(getBookingListReport.pending, (state, action) => {
      state.lodingReport = true;
      state.bookingListOfreport = [];
      state.apiErrorReport = false;
    });
    builder.addCase(getBookingListReport.fulfilled, (state, action) => {
      state.lodingReport = false;
      state.bookingListOfreport = action.payload;
      state.apiErrorReport = false;
    });
    builder.addCase(getBookingListReport.rejected, (state, action) => {
      state.lodingReport = false;
      state.bookingListOfreport = [];
      state.apiErrorReport = true;
    });
    builder.addCase(getRoomChangeReport.pending, (state, action) => {
      state.roomChangeReportLoading = true;
    });
    builder.addCase(getRoomChangeReport.fulfilled, (state, action) => {
      state.roomChangeReportLoading = false;
      state.roomChangeReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getRoomChangeReport.rejected, (state, action) => {
      state.roomChangeReportLoading = false;
      state.roomChangeReportError = action.payload;
    });

    builder.addCase(getOccupancyReport.pending, (state, action) => {
      state.occupancyReportLoading = true;
    });
    builder.addCase(getOccupancyReport.fulfilled, (state, action) => {
      state.occupancyReportLoading = false;
      state.occupancyReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getOccupancyReport.rejected, (state, action) => {
      state.occupancyReportLoading = false;
      state.occupancyReportError = action.payload;
    });

    builder.addCase(getSalesByCustomerReport.pending, (state, action) => {
      state.salesByCustomerReportLoading = true;
    });
    builder.addCase(getSalesByCustomerReport.fulfilled, (state, action) => {
      state.salesByCustomerReportLoading = false;
      state.salesByCustomerReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getSalesByCustomerReport.rejected, (state, action) => {
      state.salesByCustomerReportLoading = false;
      state.salesByCustomerReportError = action.payload;
    });

    builder.addCase(getBookingSourceReport.pending, (state, action) => {
      state.bookingSourceReportLoading = true;
    });
    builder.addCase(getBookingSourceReport.fulfilled, (state, action) => {
      state.bookingSourceReportLoading = false;
      state.bookingSourceReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getBookingSourceReport.rejected, (state, action) => {
      state.bookingSourceReportLoading = false;
      state.bookingSourceReportError = action.payload;
    });

    builder.addCase(getTaxDetailsReport.pending, (state, action) => {
      state.taxDetailsReportLoading = true;
    });
    builder.addCase(getTaxDetailsReport.fulfilled, (state, action) => {
      state.taxDetailsReportLoading = false;
      state.taxDetailsReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getTaxDetailsReport.rejected, (state, action) => {
      state.taxDetailsReportLoading = false;
      state.taxDetailsReportError = action.payload;
    });

    builder.addCase(getPayableReport.pending, (state, action) => {
      state.payableReportLoading = true;
    });
    builder.addCase(getPayableReport.fulfilled, (state, action) => {
      state.payableReportLoading = false;
      state.payableReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getPayableReport.rejected, (state, action) => {
      state.payableReportLoading = false;
      state.payableReportError = action.payload;
    });

    builder.addCase(getReceivableReport.pending, (state, action) => {
      state.receivableReportLoading = true;
    });
    builder.addCase(getReceivableReport.fulfilled, (state, action) => {
      state.receivableReportLoading = false;
      state.receivableReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getReceivableReport.rejected, (state, action) => {
      state.receivableReportLoading = false;
      state.receivableReportError = action.payload;
    });

    builder.addCase(getPaymentsReport.pending, (state, action) => {
      state.paymentReportLoading = true;
    });
    builder.addCase(getPaymentsReport.fulfilled, (state, action) => {
      state.paymentReportLoading = false;
      state.paymentReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getPaymentsReport.rejected, (state, action) => {
      state.paymentReportLoading = false;
      state.paymentReportError = action.payload;
    });

    builder.addCase(getVendorSummaryReport.pending, (state, action) => {
      state.vendorSummaryReportLoading = true;
    });
    builder.addCase(getVendorSummaryReport.fulfilled, (state, action) => {
      state.vendorSummaryReportLoading = false;
      state.vendorSummaryReportResponse = Array.isArray(action.payload?.data)
        ? action.payload?.data
        : [];
    });
    builder.addCase(getVendorSummaryReport.rejected, (state, action) => {
      state.vendorSummaryReportLoading = false;
      state.vendorSummaryReportError = action.payload;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_36,
  updateInFinateScroll,
  updateInFinateScrollLoading,
  updatePageNo,
  updateSaleReportDate,
  openNoReport,
  closeAllReports,
  openSalesReport,
  openItemWiseReport,
  openCategoryWiseReport,
  openSourceWiseReport,
  openNightAuditReport,
  updateCsvData,
  updateReportDates,
  updateReportsVisibleCalendar,
  setIsExportButtonExpanded,
  openUpcomingReport,
  openOnGoingReport,
  openCompletedReport,
  openNoShowReport,
  openRoomChangeReport,
  openEarlyCheckinReport,
  openLateCheckoutReport,
  openGrcReport,
  openBookingSourceReport,
  openRateReport,
  openCancelledReport,
  openStockReport,
  openOrderSourceReport,
  openSalesByItemReport,
  openSalesByCustomersReport,
  openSalesBySalesPersonReport,

  openVendorReport,
  openPurchaseByProduct,
  openPurchaseByCategory,
  openRateComparisonReport,
  openEmployee,
  openVendorSummaryReport,

  openPaymentsReport,
  openReceivableReport,
  openPayableReport,
  openRefundReport,
  openPaymentTypeSourceReport,
  openOccupancyReport,
  updateSourceFilter,

  openTaxDetailsReport,
  updateInvoiceTypeFilter,
  updatePaymentModeFilter,
  updatePosIdFilter,
  updateExpenseAccountFilter,
  updateVendorNameFilter,
  updateTaxIncludedInPriceFilter,
  updatePartyFilterName,
  updatePartyFilterId,
  setIsPartyInputFocused,
} = reportSlice.actions;

export default reportSlice.reducer;
