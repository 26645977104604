import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateWalkinDetails,
  updateRoomAvailabiltyArray,
  updateFilteredRoomAvailabiltyArray,
} from "../../reducer/walkinReducer";
import {
  GET_ISO_FORMAT,
  GET_TODAY_DATE_WITH_ISO_FORMAT,
  GET_TOMORROW_DATE,
} from "../../utils/helper";
import { getRoomsAvailability } from "../../services/walkin";

const useRoomAvailabiltyLogic = () => {
  const dispatch = useDispatch();

  const { hotelDetails, occupancyPlanRate } = useSelector(
    (store) => store.login
  );
  const rooms = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];

  const {
    checkIn,
    checkOut,
    response,
    roomPriceStructure,
    mealPlan,
    selectedRoomWTypeAndNum,
    roomAvailabiltyArray,
    filteredRoomAvailabilityArray,
  } = useSelector((store) => store.walkin);

  /* eslint-disable */
  useEffect(() => {
    performApi();
  }, [checkIn, checkOut]);

  useEffect(() => {
    const localroomAvailability = response
      ?.map((avail) => {
        const roomDetails = rooms?.find((room) => room?.name === avail?._id);

        let defaultOccupancy = Boolean(roomDetails?.defaultOccupancy)
          ? roomDetails?.defaultOccupancy
          : 2;

        let maxGuestAllowed = Boolean(roomDetails?.guestsAllowed)
          ? roomDetails?.guestsAllowed
          : 3;

        let rate =
          occupancyPlanRate?.[roomDetails?._id]?.[mealPlan]?.[defaultOccupancy];

        let coountButtonVisible = selectedRoomWTypeAndNum[avail?._id]?.length;

        const availObj = {
          name: avail?._id,
          availability: avail?.availability,
          rooms: avail?.rooms,
          rate: rate,
          breakfastPrice: roomDetails
            ? Number(roomDetails?.breakfastPrice)
            : null,
          mealPrice: roomDetails ? Number(roomDetails?.mealPrice) : null,
          defaultOccupancy: defaultOccupancy,
          maxGuestAllowed: maxGuestAllowed,
          taxIds: Array.isArray(roomDetails?.taxIds) ? roomDetails?.taxIds : [],
          countButtonVisible: coountButtonVisible,
        };
        return availObj;
      })
      ?.filter((item) => rooms?.map((item) => item.name)?.includes(item?.name));

    dispatch(updateRoomAvailabiltyArray(localroomAvailability));
    dispatch(updateFilteredRoomAvailabiltyArray(localroomAvailability));
  }, [response, roomPriceStructure, occupancyPlanRate]);

  useEffect(() => {
    let localOriginalRoomAvaArray = roomAvailabiltyArray?.map((Obj) =>
      Object.keys(selectedRoomWTypeAndNum).includes(Obj?.name)
        ? { ...Obj, countButtonVisible: true }
        : { ...Obj }
    );

    let localFilteredRoomAvaArray = filteredRoomAvailabilityArray?.map((Obj) =>
      Object.keys(selectedRoomWTypeAndNum).includes(Obj?.name)
        ? { ...Obj, countButtonVisible: true }
        : { ...Obj }
    );

    dispatch(updateRoomAvailabiltyArray(localOriginalRoomAvaArray));
    dispatch(updateFilteredRoomAvailabiltyArray(localFilteredRoomAvaArray));
  }, [selectedRoomWTypeAndNum]);

  const performApi = async () => {
    // loading phase
    dispatch(
      updateWalkinDetails({
        loading: true,
        response: [],
        apiError: false,
      })
    );

    const payload = {
      startDate: Boolean(checkIn)
        ? GET_ISO_FORMAT(checkIn)
        : GET_TODAY_DATE_WITH_ISO_FORMAT(),
      endDate: Boolean(checkOut)
        ? GET_ISO_FORMAT(checkOut)
        : GET_ISO_FORMAT(GET_TOMORROW_DATE()),
      hotelId: hotelDetails?.id,
    };

    let response = await getRoomsAvailability(payload, "bookings/availability");

    if (Boolean(response) && response?.status === 200) {
      // api success
      dispatch(
        updateWalkinDetails({
          loading: false,
          response: response?.data,
          apiError: false,
        })
      );
    } else {
      // api failure
      dispatch(
        updateWalkinDetails({
          loading: false,
          response: [],
          apiError: response?.response?.data?.message,
        })
      );
    }
  };
};

export default useRoomAvailabiltyLogic;
