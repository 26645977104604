import Home from "../assetsV2/SideBarImage/Dashbaord.svg";
import Booking from "../assetsV2/SideBarImage/Booking.svg";
import Pos from "../assetsV2/SideBarImage/POS.svg";
import ChannelManager from "../assetsV2/SideBarImage/Channel manager.svg";
import Clients from "../assetsV2/SideBarImage/Clients.svg";
import HouseKeeping from "../assetsV2/SideBarImage/Housekeeping.svg";
import Reports from "../assetsV2/SideBarImage/Reports.svg";
import Party from "../assetsV2/SideBarImage/Party.svg";
import PaymentsSideBarIcon from "../assetsV2/SideBarImage/Payments.svg";
import Expense from "../assetsV2/SideBarImage/Expense.svg";
import Invoice from "../assetsV2/SideBarImage/Invoice.svg";
import ManageStocks from "../assetsV2/SideBarImage/stocks.svg";

import Globe from "../assets/images/svgs/dashboard-sidebar-images/globe.svg";
import { TranslateText } from "../utils/translation";

export const DASHBOARD_NAVIGATION_OPTIONS = [
  {
    id: "1",
    title: "Dashboard",
    img: Home,
    defaultImg: Home,
    link: "",
    jsx: "Home",
    translatedTitle: <TranslateText textKey={"dashboard"} namespace="menu" />,
  },
  {
    id: "2",
    title: "Bookings",
    img: Booking,
    defaultImg: Booking,
    link: "bookings",
    translatedTitle: <TranslateText textKey={"bookings"} namespace="menu" />,
  },
  {
    id: "3",
    title: "POS",
    img: Pos,
    defaultImg: Pos,
    link: "all-orders",
    translatedTitle: <TranslateText textKey={"pos"} namespace="menu" />,
  },
  {
    id: "5",
    title: "Channel Manager",
    img: ChannelManager,
    defaultImg: ChannelManager,
    link: "channel-manager",
    translatedTitle: (
      <TranslateText textKey={"channel_manager"} namespace="menu" />
    ),
  },
  {
    id: "11",
    title: "Reports",
    img: Reports,
    defaultImg: Reports,
    link: "reports",
    translatedTitle: <TranslateText textKey={"reports"} namespace="menu" />,
  },
  {
    id: "15",
    title: "Invoice",
    img: Invoice,
    defaultImg: Invoice,
    link: "invoice",
    translatedTitle: <TranslateText textKey={"invoice"} namespace="menu" />,
  },
  {
    id: "6",
    title: "Clients",
    img: Clients,
    defaultImg: Clients,
    link: "clients",
    translatedTitle: <TranslateText textKey={"clients"} namespace="menu" />,
  },
  {
    id: "8",
    title: "Housekeeping",
    img: HouseKeeping,
    defaultImg: HouseKeeping,
    link: "house-keeping",
    translatedTitle: (
      <TranslateText textKey={"housekeeping"} namespace="menu" />
    ),
  },

  {
    id: "12",
    title: "Ledger",
    img: Party,
    defaultImg: Party,
    link: "party",
    translatedTitle: <TranslateText textKey={"ledger"} namespace="menu" />,
  },
  {
    id: "13",
    title: "Payments",
    img: PaymentsSideBarIcon,
    defaultImg: PaymentsSideBarIcon,
    link: "payments",
    translatedTitle: <TranslateText textKey={"payments"} namespace="menu" />,
  },
  {
    id: "14",
    title: "Expense",
    img: Expense,
    defaultImg: Expense,
    link: "expense",
    translatedTitle: <TranslateText textKey={"expense"} namespace="menu" />,
  },

  {
    id: "16",
    title: "Manage Stocks",
    img: ManageStocks,
    defaultImg: ManageStocks,
    link: "manageStocks",
    translatedTitle: (
      <TranslateText textKey={"manage_stocks"} namespace="menu" />
    ),
  },
  {
    id: "17",
    title: "Booking Engine",
    img: Globe,
    defaultImg: Globe,
    jsx: "booking-engine",
    link: "booking-engine",
    translatedTitle: (
      <TranslateText textKey={"booking_engine"} namespace="menu" />
    ),
  },
];
