import { bookingList, deleteBooking, modifyBooking } from "../services/booking";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GET_ONE_YEAR_LATER_DATE_WITH_ISO_FORMAT,
  GET_TODAY_DATE_WITH_ISO_FORMAT,
} from "../utils/helper";
import { message } from "antd";
import { createPayment } from "../services/payment";
import { allInvoices } from "../services/invoice";

export const getBookingList = createAsyncThunk(
  "getBookingList",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const data = {
      startDate: Boolean(payload)
        ? payload.startDate
        : payload.statuses === "confirmed"
        ? GET_TODAY_DATE_WITH_ISO_FORMAT()
        : null,
      endDate: Boolean(payload.endDate)
        ? payload.endDate
        : payload.statuses === "confirmed"
        ? GET_ONE_YEAR_LATER_DATE_WITH_ISO_FORMAT()
        : null,
      statuses: payload.statuses,
      hotelId: payload.hotelId,
      searchText: Boolean(payload.searchText) ? payload.searchText : "",

      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
      sortingDirection: payload.sortingDirection,
      sortingField: payload.sortingField,
      hotelIds: payload.hotelIds,
    };

    try {
      const response = await bookingList({ ...payload, ...data }, "bookings");
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error.message);
    }
  }
);

export const updateBookingDetails = createAsyncThunk(
  "updateBookingDetails",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await modifyBooking(payload, `/bookings/${payload.id}`);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
      }

      throw rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const deleteBookingDetails = createAsyncThunk(
  "deleteBookingDetails",
  async (payload) => {
    try {
      const response = await deleteBooking(payload);
      return {
        data: response.data,
        navigate: payload.navigate,
      };
    } catch (error) {
      message.error("Something went wrong!");
      return error;
    }
  }
);

export const postPaymentAndUpdateInvoiceDetails = createAsyncThunk(
  "postPaymentAndUpdateInvoiceDetails",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const paymentResponse = await createPayment(payload.paymentPayload);
      const invoiceResponse = await allInvoices(payload.invoicePayload);

      const store = getState();
      const allPaymentArray = store?.booking?.paymentDetails;
      let updatedPaytmentArray = [...allPaymentArray, paymentResponse.data];

      Boolean(store?.booking?.response) &&
        Object.keys(store?.booking?.response).length !== 0 &&
        dispatch(
          updateBookingListData({
            ...store?.booking?.response,
            payments: [
              ...store?.booking?.response?.payments,
              paymentResponse?.data,
            ],
          })
        );

      dispatch(updateInvoiceDetails(invoiceResponse.data));
      return fulfillWithValue(updatedPaytmentArray);
    } catch (error) {
      if (error?.response?.status == 401) localStorage.removeItem("token");
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  title: "upcoming",
  loading: true, // before api call
  response: {}, // store  booking list json data into it
  apiError: false, // check api status
  pageName: "bookingList",

  roomAvailabiltyError: null,

  bookingDetailsLoading: true,
  loadingDetails: false, // when booking details api is pending
  responseDetails: {}, // store booking details json data into it
  responseDetailsApiError: false, // check api status
  visibleRoomNumberModal: false, // after clicking on the room number button in booking details page , modal will model

  // for search booking list
  checkIn: null,
  checkOut: null,

  selectedBookingSource: [],
  selectedRoomType: [],
  selectedBookingStatus: [],

  isSearch: false,

  isIdSignitureVisible: false, // show or hide id signiture modal
  isExtraFieldModalVisible: false, // show or hide booking details exra field modal

  otherIDS: [],

  pageNumber: 0,
  pageSize: 20,
  infinateScroll: false,
  infinateScrollLoading: false,

  // delete booking
  loadingDeleteApi: false,
  responseDeleteApi: {},
  errorDeleteApi: false,

  // Nationals Guest
  selectedDateOfIssue: null,
  selectedDateOfExpire: null,
  selectedDateOfArrival: null,
  selectedDateOfDeparture: null,
  nationality: null,
  passportNo: null,
  visaNo: null,
  visaType: null,
  localContactNo: null,
  companyName: null,
  purposeOfStay: null,

  // bookingV2
  bookingDetails: {},
  customerDetails: [],
  invoiceDetails: [],
  paymentDetails: [],

  postPaymentLoading: false,
  paymentApiError: false,

  showRoomNumberModal: false,
  showMainBookingDetailsDrawer: {
    visible: false,
    bookingId: "",
    bookingType: "",
    newBookingId: "",
  },
  showPrimaryGuestDrawer: {
    visible: false,
    bookingId: "",
    customerType: "",
    customerId: "",
  },
  showAddonsDrawer: false,

  showExtraOptionDrawer: false,

  showTakePaymentDrawer: false,
  showRefundDrawer: false,

  showApplyDiscountDrawer: false,
  showFolio: false,
  visibleBookingDetailsCalendar: false,
  showGrcDrawer: false,

  displayNoShowModal: false,
  displayCancelBookingModal: false,
  displayDeleteBookingModal: false,

  displayUndoCompletedBookingModal: false,
  displayUndoNoShowBookingModal: false,

  displaydeletePartyModal: { display: false, partyName: "" },

  displayCheckInDrawer: false,
  displayCheckOutDrawer: false,

  displayIdModal: false,

  displayIdDrawer: false,

  displayGuestModal: false,
  displayRoomDrawer: false,

  displayOTARoomModal: false,

  displayGuestIdModal: false,

  roomList: [],
  discountInfo: {
    type: null,
    value: 0,
  },

  selectedRoomWTypeAndNum: {},
  tempSelectedRoomWTypeAndNum: {}, // temp store selected room for further change

  roomPriceStructure: [],
  gstPercentage: null,

  //Room Edit Drawer State
  selectedCheckInDateTime: "",
  selectedCheckOutDateTime: "",
  mealPlan: "EP",

  visibleAddonsModal: {
    visible: false,
    roomtype: "",
  },

  nightsCount: 1,

  roomTotal: 0,
  subTotal: 0,
  total: 0,
  sgst: 0,

  displayPrimaryGuestDoa: false,
  displayPrimaryGuestDob: false,
  displayPrimaryGuestDateArrival: false,
  displayPrimaryGuestDateDeparture: false,
  displayPrimaryGuestDateIssue: false,
  displayPrimaryGuestDateExpire: false,

  displayFilter: false,

  displayMobileFilterDrawer: false,

  displayInvoiceDrawer: {
    display: false,
    invoiceDetails: [],
    roomDetails: {},
    posNames: {},
    roomBillInvoices: {},
    customerDetails: [],
    bookingDetails: {},
  },

  changeDateBoolean: false,

  displayPaymentPrintDrawer: {},
  taxIncludedInPriceLocalState: false,

  mobileBookingDetailsId: "",
  guestSectionVisible: false,
  roomSectionVisible: false,
  viewBillSectionVisible: false,

  roomTotalDrawer: false,
  showAttachToPartyDrawer: false,

  attachPartyString: "",
  attachPartyValue: "",

  displayDetaileBreakupModal: false,
  detailedPriceBreakup: [],
  removeRoomModalVisible: false,

  commonBookingListData: [],
  upcomingBookingListData: [],
  upcomingBookingStartIndex: 0,
  bookingHotelId: null,

  editPaymentDrawer: {
    display: false,
    paymentObj: {},
  },

  displayMobileViewBillsModal: false,

  roomAvailabiltyArray: [],
  filteredRoomAvailabilityArray: [],

  roomRateEditable: false,
  roomChangeMade: false,

  adults: 0,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_12: () => initialState,

    updateBookingHotelId: (state, action) => {
      state.bookingHotelId = action.payload;
    },

    updateRoomAvailabiltyApiError: (state, action) => {
      state.roomAvailabiltyError = action.payload;
    },

    updateRemoveRoomModalVisible: (state, action) => {
      state.removeRoomModalVisible = !state.removeRoomModalVisible;
    },

    updateAttachPartyString: (state, action) => {
      state.attachPartyString = action.payload;
    },
    updateEditPaymentDrawer: (state, action) => {
      state.editPaymentDrawer = action.payload;
    },

    updateAttachPartyValue: (state, action) => {
      state.attachPartyValue = action.payload;
    },

    updateActiveTab: (state, action) => {
      state.pageNumber = 0;
      state.loading = true;
      state.title = action.payload;
    },
    updateBookingListData: (state, action) => {
      state.loading = action.payload.loading;
      state.response = action.payload;
      state.apiError = action.payload.apiError;
    },

    updateBookingDetailsData: (state, action) => {
      state.loadingDetails = action.payload.loadingDetails;
      state.responseDetails = action.payload.responseDetails;
      state.responseDetailsApiError = action.payload.responseDetailsApiError;
    },

    updateBookingDetailsDataLoading: (state, action) => {
      state.bookingDetailsLoading = action.payload;
    },

    updateInFinateScroll: (state, action) => {
      state.infinateScroll = action.payload;
    },
    updateInFinateScrollLoading: (state, action) => {
      state.infinateScrollLoading = action.payload;
    },

    updatePageNo: (state, action) => {
      state.pageNumber = state.pageNumber + 1;
    },

    updateBookingDetailsStateAfterCustomerUpdate: (state, action) => {
      state.responseDetails = action.payload;
    },

    updateShowingRoomNumberModal: (state, action) => {
      state.visibleRoomNumberModal = action.payload.modal;
    },

    updateDisplayMobileViewBillsModal: (state, action) => {
      state.displayMobileViewBillsModal = action.payload;
    },
    updateDisplayUndoCompletedBookingModal: (state, action) => {
      state.displayUndoCompletedBookingModal = action.payload;
    },
    updateDisplayUndoNoShowBookingModal: (state, action) => {
      state.displayUndoNoShowBookingModal = action.payload;
    },

    updateChechInCheckOutDate: (state, action) => {
      state.pageNumber = 0;
      state.infinateScroll = false;

      if (action.payload.checkIn) {
        state.checkIn = action.payload.checkIn;
      } else if (action.payload.checkOut) {
        state.checkOut = action.payload.checkOut;
      }
    },

    updateFilterData: (state, action) => {
      state.isSearch = true;

      let source = action.payload.source;
      let roomType = action.payload.roomType;
      let status = action.payload.status;
      state.pageNumber = 0;
      state.infinateScroll = false;

      if (source) {
        let index = state.selectedBookingSource.indexOf(source);
        if (index == -1) {
          state.selectedBookingSource = [
            ...state.selectedBookingSource,
            source,
          ];
        } else {
          state.selectedBookingSource = state.selectedBookingSource.filter(
            (item) => item !== source
          );
        }
      } else if (roomType) {
        let index = state.selectedRoomType.indexOf(roomType);

        if (index < 0) {
          state.selectedRoomType = [...state.selectedRoomType, roomType];
        } else {
          state.selectedRoomType = state.selectedRoomType.filter(
            (item) => item !== roomType
          );
        }
      } else {
        let index = state.selectedBookingStatus.indexOf(status);

        if (index < 0) {
          state.selectedBookingStatus = [
            ...state.selectedBookingStatus,
            status,
          ];
        } else {
          state.selectedBookingStatus = state.selectedBookingStatus.filter(
            (item) => item !== status
          );
        }
      }
    },

    clearFilterData: (state) => {
      state.checkIn = null;
      state.checkOut = null;
      state.selectedBookingSource = [];
      state.selectedRoomType = [];
      state.selectedBookingStatus = [];
      state.infinateScroll = false;
      state.infinateScrollLoading = false;
      state.displayFilter = false;
    },

    updateOtherIDs: (state, action) => {
      let files = Boolean(state.otherIDS) ? state.otherIDS : [];
      state.otherIDS = [...files, action.payload];
    },

    removeOtherId: (state, action) => {
      const url = action.payload;
      state.otherIDS = state.otherIDS.filter((item) => item !== url);
    },

    updateWholeOthersId: (state, action) => {
      state.otherIDS = action.payload;
    },

    updateIdSignitureModal: (state, action) => {
      state.isIdSignitureVisible = action.payload;
    },

    updateExtraFieldModal: (state, action) => {
      state.isExtraFieldModalVisible = action.payload;
    },

    // Nationals Guest

    updateNationality: (state, action) => {
      state.nationality = action.payload;
    },
    updatePassportNo: (state, action) => {
      state.passportNo = action.payload;
    },
    updateVisaNo: (state, action) => {
      state.visaNo = action.payload;
    },
    updateVisaType: (state, action) => {
      state.visaType = action.payload;
    },
    updateLocalContactNo: (state, action) => {
      state.localContactNo = action.payload;
    },
    updateCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    updatePurposeOfStay: (state, action) => {
      state.purposeOfStay = action.payload;
    },
    updateDateOfIssue: (state, action) => {
      if (action.payload.dateOfIssue) {
        state.selectedDateOfIssue = action.payload.dateOfIssue;
      }
    },
    updateDateOfExpire: (state, action) => {
      if (action.payload.dateOfExpire) {
        state.selectedDateOfExpire = action.payload.dateOfExpire;
      }
    },
    updateDateOfArrival: (state, action) => {
      if (action.payload.dateOfArrival) {
        state.selectedDateOfArrival = action.payload.dateOfArrival;
      }
    },
    updateDateOfDeparture: (state, action) => {
      if (action.payload.dateOfDeparture) {
        state.selectedDateOfDeparture = action.payload.dateOfDeparture;
      }
    },
    //bookingV2
    updateShowRoomNumberModal: (state, action) => {
      state.showRoomNumberModal = !state.showRoomNumberModal;
    },
    updateShowMainBookingDetailsDrawer: (state, action) => {
      const { visible, bookingId, bookingType, newBookingId } = action.payload;
      return {
        ...state,
        showMainBookingDetailsDrawer: {
          ...state.showMainBookingDetailsDrawer,
          visible,
          bookingId,
          bookingType,
          newBookingId,
        },
      };
    },

    updateShowPrimaryGuestDrawer: (state, action) => {
      const { visible, bookingId, customerType, customerId } = action.payload;
      return {
        ...state,
        showPrimaryGuestDrawer: {
          ...state.showPrimaryGuestDrawer,
          visible,
          bookingId,
          customerType,
          customerId,
        },
      };
    },
    updateShowAddonsDrawer: (state, action) => {
      state.showAddonsDrawer = !state.showAddonsDrawer;
    },
    updateShowExtraOptionDrawer: (state, action) => {
      state.showExtraOptionDrawer = action.payload;
    },

    updateShowTakePaymentDrawer: (state, action) => {
      state.showTakePaymentDrawer = !state.showTakePaymentDrawer;
    },
    updateShowRefundPaymentDrawer: (state, action) => {
      state.showRefundDrawer = !state.showRefundDrawer;
    },
    updateShowApplyDiscountDrawer: (state, action) => {
      state.showApplyDiscountDrawer = !state.showApplyDiscountDrawer;
    },
    updateShowGrcDrawer: (state, action) => {
      state.showGrcDrawer = !state.showGrcDrawer;
    },
    updateShowFolioDrawer: (state, action) => {
      state.showFolio = Boolean(action?.payload) ? action?.payload : false;
    },

    updateVisibleBookingDetailsCalendar: (state, action) => {
      state.visibleBookingDetailsCalendar = action.payload;
    },
    updateBookingDetailsEditable: (state, action) => {
      state.bookingDetails = action.payload;
    },
    updateCustomerDetails: (state, action) => {
      state.customerDetails = action.payload;
    },
    updateInvoiceDetails: (state, action) => {
      state.invoiceDetails = action.payload;
    },
    updatePaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
    updateDisplayNoShowModal: (state, action) => {
      state.displayNoShowModal = !state.displayNoShowModal;
    },
    updateDisplayCancelBookingModal: (state, action) => {
      state.displayCancelBookingModal = !state.displayCancelBookingModal;
    },
    updateDisplayDeleteBookingModal: (state, action) => {
      state.displayDeleteBookingModal = !state.displayDeleteBookingModal;
    },
    updateDisplayDeletePartyModal: (state, action) => {
      state.displaydeletePartyModal = action.payload;
    },

    updateDisplayCheckInDrawer: (state, action) => {
      state.displayCheckInDrawer = !state.displayCheckInDrawer;
    },
    updateDisplayCheckOutDrawer: (state, action) => {
      state.displayCheckOutDrawer = !state.displayCheckOutDrawer;
    },
    updateDisplayIdModal: (state, action) => {
      state.displayIdModal = !state.displayIdModal;
    },
    updateDisplayGuestModal: (state, action) => {
      state.displayGuestModal = !state.displayGuestModal;
    },

    updateDisplayGuestIdModal: (state, action) => {
      state.displayGuestIdModal = !state.displayGuestIdModal;
    },
    updateDisplayRoomDrawer: (state, action) => {
      state.displayRoomDrawer = !state.displayRoomDrawer;
    },
    updateDisplayOTARoomModal: (state, action) => {
      state.displayOTARoomModal = !state.displayOTARoomModal;
    },

    // Room Drawer
    updateRoomDetails: (state, action) => {
      state.roomList = action.payload.response;
    },
    updateSelectedRoomWTypeAndNum: (state, action) => {
      const { selectedRoomType, selectedRoomNumber } = action.payload;

      // Merge the new room type and number into the existing data
      const updatedData = {
        ...state.selectedRoomWTypeAndNum,
        [selectedRoomType]: selectedRoomNumber,
      };

      // Filter out any entries where the room number array is empty
      const filteredRooms = Object.entries(updatedData)
        .filter(([_, roomNumbers]) => roomNumbers.length > 0)
        .reduce((acc, [roomType, roomNumbers]) => {
          acc[roomType] = roomNumbers;
          return acc;
        }, {});

      // Update the state with the filtered rooms
      state.tempSelectedRoomWTypeAndNum = filteredRooms;
      state.selectedRoomWTypeAndNum = filteredRooms;
    },

    saveSelectedRoomWTypeAndNum: (state, action) => {
      state.tempSelectedRoomWTypeAndNum = state.selectedRoomWTypeAndNum;
    },

    // after room number change in "tempSelectedRoomWTypeAndNum", save it in "selectedRoomWTypeAndNum"
    saveTempSelectedRoomWTypeAndNum: (state, action) => {
      state.selectedRoomWTypeAndNum = state.tempSelectedRoomWTypeAndNum;
    },

    updateTempSelectedRoomWTypeAndNum: (state, action) => {
      state.tempSelectedRoomWTypeAndNum = action.payload;
    },
    updateSelectedRoomNumberForModify: (state, action) => {
      state.selectedRoomWTypeAndNum = action.payload;
      state.tempSelectedRoomWTypeAndNum = action.payload;
    },
    updateSelectedRoomNumber: (state, action) => {
      let selectedRoomType = action.payload.selectedRoomType;
      let selectedRoomNumber = action.payload.selectedRoomNumber;
      let selectedRoomTypeInOject =
        state.selectedRoomWithType[selectedRoomType];

      state.selectedRoomWithType[selectedRoomType] =
        selectedRoomTypeInOject !== undefined &&
        selectedRoomTypeInOject.indexOf(selectedRoomNumber) > -1
          ? selectedRoomTypeInOject.filter(
              (item) => item !== selectedRoomNumber
            )
          : selectedRoomTypeInOject !== undefined &&
            selectedRoomTypeInOject.indexOf(selectedRoomNumber) === -1
          ? [...selectedRoomTypeInOject, selectedRoomNumber]
          : [selectedRoomNumber];
    },
    updateRoomType: (state, action) => {
      state.selectedRoomType = action.payload;
    },
    updateRoomPriceStructure: (state, action) => {
      state.roomPriceStructure = action.payload;
    },
    updateGstPercentage: (state, action) => {
      state.gstPercentage = action.payload;
    },
    updateDisplayIdDrawer: (state, action) => {
      state.displayIdDrawer = !state.displayIdDrawer;
    },
    updateSelectedCheckInDateTime: (state, action) => {
      state.selectedCheckInDateTime = action.payload;
    },
    updateSelectedCheckOutDateTime: (state, action) => {
      state.selectedCheckOutDateTime = action.payload;
    },
    updateMealPlan: (state, action) => {
      state.mealPlan = action.payload;
    },
    updateVisibleAddonsModal: (state, action) => {
      state.visibleAddonsModal.visible = action.payload.visible;
      state.visibleAddonsModal.roomtype = action.payload.roomtype;
    },
    updateNightsCount: (state, action) => {
      let count = Boolean(action?.payload) ? action.payload : 1;
      state.nightsCount = count;
    },

    updateRoomTotal: (state, action) => {
      state.roomTotal = action.payload;
    },
    updateSubTotal: (state, action) => {
      state.roomTotal = action.payload;
    },
    updateTotal: (state, action) => {
      state.roomTotal = action.payload;
    },
    updateSgst: (state, action) => {
      state.roomTotal = action.payload;
    },
    updateDisplayPrimaryGuestDoa: (state, action) => {
      state.displayPrimaryGuestDoa = action.payload;
    },

    updateDisplayPrimaryGuestDob: (state, action) => {
      state.displayPrimaryGuestDob = action.payload;
    },
    updateDisplayPrimaryGuestDateArrival: (state, action) => {
      state.displayPrimaryGuestDateArrival = action.payload;
    },
    updateDisplayPrimaryGuestDateDeparture: (state, action) => {
      state.displayPrimaryGuestDateDeparture = action.payload;
    },
    updateDisplayPrimaryGuestDateIssue: (state, action) => {
      state.displayPrimaryGuestDateIssue = action.payload;
    },
    updateDisplayPrimaryGuestDateExpire: (state, action) => {
      state.displayPrimaryGuestDateExpire = action.payload;
    },
    updateDisplayFilter: (state, action) => {
      state.displayFilter = action.payload;
    },
    updateDisplayMobileFilterDrawer: (state, action) => {
      state.displayMobileFilterDrawer = action.payload;
    },
    updateDisplayInvoiceDrawer: (state, action) => {
      state.displayInvoiceDrawer = action.payload;
    },
    updateChangeDateBoolean: (state, action) => {
      state.changeDateBoolean = action.payload;
    },
    resetState: (state, action) => {
      state.pageNumber = 0;
    },
    updateDisplayPaymentPrintDrawer: (state, action) => {
      state.displayPaymentPrintDrawer = action.payload;
    },
    updatetaxIncludedInPriceLocalState: (state, action) => {
      state.taxIncludedInPriceLocalState = action.payload;
    },
    resetBookingDetails: (state, action) => {
      state.showMainBookingDetailsDrawer = {
        visible: false,
        bookingId: "",
        bookingType: "",
        newBookingId: "",
      };
      state.bookingHotelId = null;
      state.invoiceDetails = [];
      state.changeDateBoolean = false;
      state.bookingDetailsLoading = true;

      state.loadingDetails = false;
      state.responseDetails = {};

      state.responseDetailsApiError = false;

      state.visibleRoomNumberModal = false; // after clicking on the room number button in booking
      state.customerDetails = [];
      state.paymentDetails = [];

      state.showPrimaryGuestDrawer = {
        visible: false,
        bookingId: "",
        customerType: "",
        customerId: "",
      };

      state.showAddonsDrawer = false;
      state.showTakePaymentDrawer = false;
      state.showApplyDiscountDrawer = false;
      state.showFolio = false;
      state.visibleBookingDetailsCalendar = false;
      state.showGrcDrawer = false;

      state.displayNoShowModal = false;
      state.displayCancelBookingModal = false;
      state.displayCheckInDrawer = false;
      state.displayCheckOutDrawer = false;

      state.displayIdDrawer = false;
      state.displayRoomDrawer = false;

      state.discountInfo = {
        type: null,
        value: 0,
      };

      state.selectedRoomWTypeAndNum = {};
      state.tempSelectedRoomWTypeAndNum = {};

      state.roomPriceStructure = [];
      state.selectedCheckInDateTime = "";
      state.selectedCheckOutDateTime = "";
      state.mealPlan = "EP";

      state.visibleAddonsModal = {
        visible: false,
        roomtype: "",
      };

      state.nightsCount = 1;

      state.roomTotal = 0;
      state.subTotal = 0;
      state.total = 0;

      state.displayPrimaryGuestDoa = false;
      state.displayPrimaryGuestDob = false;
      state.displayPrimaryGuestDateArrival = false;
      state.displayPrimaryGuestDateDeparture = false;
      state.displayPrimaryGuestDateIssue = false;
      state.displayPrimaryGuestDateExpire = false;

      state.displayFilter = false;

      state.displayInvoiceDrawer = {
        display: false,
        invoiceDetails: [],
        roomDetails: {},
        posNames: {},
        roomBillInvoices: {},
        customerDetails: [],
        bookingDetails: {},
      };

      state.changeDateBoolean = false;

      state.displayPaymentPrintDrawer = {};
      state.taxIncludedInPriceLocalState = false;
      state.bookingDetails = {};

      state.roomSectionVisible = false;
      state.guestSectionVisible = false;
      state.viewBillSectionVisible = false;

      state.mobileBookingDetailsId = "";
      state.roomAvailabiltyError = null;
    },

    updateMobileBookingDetailsId: (state, action) => {
      state.mobileBookingDetailsId = action.payload;
    },

    updateGuestSectionVisible: (state, action) => {
      state.guestSectionVisible = action.payload;
    },
    updateRoomSectionVisible: (state, action) => {
      state.roomSectionVisible = action.payload;
    },
    updateViewBillSectionVisible: (state, action) => {
      state.viewBillSectionVisible = action.payload;
    },
    updateRoomTotalDrawer: (state, action) => {
      state.roomTotalDrawer = action.payload;
    },
    updateShowAttachToPartyDrawer: (state, action) => {
      state.showAttachToPartyDrawer = action.payload;
    },
    updateDetailedPriceBreakup: (state, action) => {
      state.detailedPriceBreakup = action.payload;
    },
    updateDisplayDetailedBreakupModal: (state, action) => {
      state.displayDetaileBreakupModal = action.payload;
    },
    updateCommonBookingListData: (state, action) => {
      state.commonBookingListData = action.payload;
    },
    updateUpcomingBookingListData: (state, action) => {
      state.upcomingBookingListData = action.payload;
    },
    updateUpcomingBookingStartIndex: (state, action) => {
      state.upcomingBookingStartIndex = action.payload;
    },

    updateRoomAvailabiltyArray: (state, action) => {
      state.roomAvailabiltyArray = action.payload;
    },
    updateFilteredRoomAvailabiltyArray: (state, action) => {
      state.filteredRoomAvailabilityArray = action.payload;
    },
    updateRoomRateEditable: (state, action) => {
      state.roomRateEditable = action.payload;
    },

    updateRoomChangeMade: (state, action) => {
      state.roomChangeMade = action.payload;
    },
    updateAdults: (state, action) => {
      state.adults = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBookingList.pending, (state, action) => {
      state.loading = state.infinateScroll ? false : true;
      state.response = state.infinateScroll ? { ...state.response } : {};
      state.apiError = false;
    });
    builder.addCase(getBookingList.fulfilled, (state, action) => {
      state.loading = false;

      state.infinateScroll =
        action.payload?.bookings?.length > 0 ? true : false;

      state.infinateScrollLoading =
        action.payload?.bookings?.length > 0 ? true : false;

      let bookingList = Boolean(state.response.bookings)
        ? state.response.bookings
        : [];

      let customerList = Boolean(state.response.customers)
        ? state.response.customers
        : [];

      let invoiceList = Boolean(state.response.invoices)
        ? state.response.invoices
        : [];

      let paymentsList = Boolean(state.response.payments)
        ? state.response.payments
        : [];

      const resData = Boolean(action.payload.bookings)
        ? {
            ...state.response,
            bookings: [...bookingList, ...action.payload.bookings],
            customers: [...customerList, ...action.payload.customers],
            invoices: [...invoiceList, ...action.payload.invoices],
            payments: [...paymentsList, ...action.payload.payments],
          }
        : {};

      state.response = Boolean(state?.pageNumber)
        ? resData
        : action.payload || {};

      state.apiError = false;
    });
    builder.addCase(getBookingList.rejected, (state, action) => {
      state.infinateScrollLoading = false;
      state.loading = false;
      state.response = {};
      state.apiError = true;
    });

    builder.addCase(updateBookingDetails.pending, (state, action) => {
      state.loadingDetails = false;
      state.responseDetailsApiError = false;
    });
    builder.addCase(updateBookingDetails.fulfilled, (state, action) => {
      state.loadingDetails = false;

      state.responseDetails = {
        ...state.responseDetails,
        bookingDetails: action.payload,
      };
      // booking V2
      state.bookingDetails = action.payload;
      state.responseDetailsApiError = false;
    });
    builder.addCase(updateBookingDetails.rejected, (state, action) => {
      state.loadingDetails = false;
      // message.info(action?.payload);
      state.responseDetailsApiError = true;
    });

    builder.addCase(deleteBookingDetails.pending, (state, action) => {
      state.loadingDeleteApi = true;
      state.responseDeleteApi = {};
      state.errorDeleteApi = false;
    });
    builder.addCase(deleteBookingDetails.fulfilled, (state, action) => {
      state.loadingDeleteApi = false;
      state.responseDeleteApi = action.payload;
      state.errorDeleteApi = false;
    });
    builder.addCase(deleteBookingDetails.rejected, (state, action) => {
      state.loadingDeleteApi = false;
      state.responseDeleteApi = {};
      state.errorDeleteApi = true;
    });

    builder.addCase(
      postPaymentAndUpdateInvoiceDetails.pending,
      (state, action) => {
        state.postPaymentLoading = true;
        state.apiError = false;
      }
    );
    builder.addCase(
      postPaymentAndUpdateInvoiceDetails.fulfilled,
      (state, action) => {
        state.postPaymentLoading = false;

        message.success("Bill Payment Successful");
        state.paymentDetails = action.payload;

        state.apiError = false;
      }
    );
    builder.addCase(
      postPaymentAndUpdateInvoiceDetails.rejected,
      (state, action) => {
        state.postPaymentLoading = false;
        message.info("Something went wrong");
        state.paymentApiError = true;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_12,
  updateActiveTab,
  updateBookingListData,

  updateBookingDetailsStateAfterCustomerUpdate,
  updateExtraFieldModal,
  updateShowingRoomNumberModal,
  updateChechInCheckOutDate,
  updateFilterData,
  updateInFinateScroll,
  updateInFinateScrollLoading,
  updatePageNo,
  updateOtherIDs,
  clearFilterData,
  updateWholePrimaryId,
  removeOtherId,
  updateWholeOthersId,
  updateIdSignitureModal,

  // Nationals Guest
  updateDateOfIssue,
  updateDateOfExpire,
  updateDateOfArrival,
  updateDateOfDeparture,
  updateNationality,
  updatePassportNo,
  updateVisaNo,
  updateVisaType,
  updateCompanyName,
  updateLocalContactNo,
  updatePurposeOfStay,
  //bookingV2
  updateShowRoomNumberModal,
  updateShowMainBookingDetailsDrawer,
  updateShowPrimaryGuestDrawer,
  updateShowAddonsDrawer,
  updateShowTakePaymentDrawer,
  updateShowApplyDiscountDrawer,
  updateShowGrcDrawer,
  updateShowFolioDrawer,
  updateVisibleBookingDetailsCalendar,

  updateBookingDetailsEditable,
  updateCustomerDetails,
  updateInvoiceDetails,
  updatePaymentDetails,

  updateDisplayNoShowModal,
  updateDisplayCancelBookingModal,

  updateDisplayCheckInDrawer,
  updateDisplayIdModal,
  updateDisplayGuestModal,

  updateDisplayRoomDrawer,
  updateRoomDetails,
  updateDiscountInfo,

  updateSelectedRoomWTypeAndNum,
  saveSelectedRoomWTypeAndNum,
  saveTempSelectedRoomWTypeAndNum,

  updateTempSelectedRoomWTypeAndNum,
  updateSelectedRoomNumberForModify,
  updateSelectedRoomNumber,
  updateRoomType,

  updateRoomPriceStructure,
  updateGstPercentage,

  updateDisplayIdDrawer,

  updateSelectedCheckInDateTime,
  updateSelectedCheckOutDateTime,
  updateMealPlan,

  updateVisibleAddonsModal,
  updateNightsCount,

  updateRoomTotal,
  updateSubTotal,
  updateTotal,
  updateSgst,

  updateDisplayPrimaryGuestDoa,
  updateDisplayPrimaryGuestDob,

  updateDisplayPrimaryGuestDateArrival,
  updateDisplayPrimaryGuestDateDeparture,

  updateDisplayPrimaryGuestDateIssue,
  updateDisplayPrimaryGuestDateExpire,
  updateDisplayFilter,
  updateDisplayCheckOutDrawer,
  updateDisplayInvoiceDrawer,

  updateBookingDetailsData,
  updateChangeDateBoolean,
  resetState,

  updateBookingDetailsDataLoading,

  updateDisplayPaymentPrintDrawer,
  updatetaxIncludedInPriceLocalState,
  resetBookingDetails,

  updateShowRefundPaymentDrawer,
  updateDisplayMobileFilterDrawer,

  updateMobileBookingDetailsId,
  updateGuestSectionVisible,

  updateRoomSectionVisible,
  updateViewBillSectionVisible,

  updateRoomTotalDrawer,
  updateShowAttachToPartyDrawer,

  updateAttachPartyString,
  updateAttachPartyValue,

  updateDetailedPriceBreakup,
  updateDisplayDetailedBreakupModal,
  updateDisplayGuestIdModal,
  updateRemoveRoomModalVisible,

  updateCommonBookingListData,
  updateUpcomingBookingListData,
  updateUpcomingBookingStartIndex,
  updateBookingHotelId,
  updateDisplayOTARoomModal,

  updateShowExtraOptionDrawer,
  updateDisplayDeletePartyModal,

  updateEditPaymentDrawer,
  updateDisplayMobileViewBillsModal,

  updateDisplayDeleteBookingModal,

  updateDisplayUndoCompletedBookingModal,
  updateDisplayUndoNoShowBookingModal,

  updateRoomAvailabiltyArray,
  updateFilteredRoomAvailabiltyArray,
  updateRoomChangeMade,
  updateRoomRateEditable,

  updateAdults,

  updateRoomAvailabiltyApiError,
} = bookingSlice.actions;

export default bookingSlice.reducer;
