import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Button, Calendar, Col, Row, Select } from "antd";
import { BlueCircle, RedCircle, Sort, YellowCicle } from "../Icon1";
import moment from "moment";
import { useSelector } from "react-redux";
import { getRoomsData } from "../../services/channelManager";
import CalendarCell from "./CalendarCell";
import RoomDetailBar from "./RoomDetailBar";
import UpdateRoom from "./UpdateRoom";

function CMViewPage() {
  const { hotelDetails } = useSelector((store) => store.login);
  const { apiLoading } = useSelector((store) => store.channelManager);

  const [currentMonth, setCurrentMonth] = useState(moment().month());

  const [currentMonthStart, setCurrentMonthStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );

  const [currentMonthEnd, setCurrentMonthEnd] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [cellData, setCellData] = useState([]);

  const [selectedDateData, setSelectedDateData] = useState([]);

  const [roomType, setRoomType] = useState();

  const [currentSelectedDate, setCurrentSelectedDate] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [updateRoomKey, setUpdateRoomKey] = useState(0);

  // const [selectedFetchingDate, setSelectedFetchingDate] = useState("");

  const roomOptions = [];
  hotelDetails?.roomTypes?.forEach((item) => {
    if (Boolean(item.cmInvCode)) {
      roomOptions.push({
        label: item.name,
        value: item.name,
        cmInvCode: item.cmInvCode,
      });
    }
  });

  useEffect(() => {
    if (hotelDetails?.roomTypes) {
      const initializationPromises = hotelDetails.roomTypes.map((item) => {
        const type = item.name;
        return performInitialization({ type });
      });

      Promise.all(initializationPromises)
        .then((results) => {
          const flattenedResults = [].concat(...results);
          setCellData(flattenedResults);
          // setCellData((prev) => [...prev, ...flattenedResults]);
        })
        .catch((err) => {});
    }
    setRoomType(hotelDetails?.roomTypes?.[0]?.name);
  }, [hotelDetails?.roomTypes, apiLoading]);

  const performInitialization = async ({ type }) => {
    const response = await getRoomsData("cm/availabilityRate", {
      dynamicPricing: false,
      endDate: currentMonthEnd,
      hotelId: hotelDetails?.id,
      page: 0,
      roomType: type,
      size: currentMonthEnd.split("-")[2],
      startDate: currentMonthStart,
    });
    return response?.content;
  };

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];
    let current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current = current.month(i);
      months.push(localeData.monthsShort(current));
    }
    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i} className="month-item">
          {months[i]}
        </Select.Option>
      );
    }
    const year = value.year();
    const month = value.month();

    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }

    return (
      <div
        style={{
          padding: 8,
        }}
      >
        <Row className="flex justify-between my-calendar" gutter={8}>
          <Col>
            <Select
              className="w-[224px]"
              size="large"
              suffixIcon={<Sort />}
              value={roomType}
              onChange={(value, option) => {
                setRoomType(value);
                setSelectedDateData([]);
                setCurrentSelectedDate("");
              }}
              options={roomOptions}
            />
          </Col>
          <Col className="flex gap-2">
            <Select
              size="large"
              className="my-year-select"
              value={year}
              onChange={(newYear) => {
                const now = value.clone().year(newYear);
                const newMonth = value.month();
                const startOfMonth = moment()
                  .month(newMonth)
                  .year(newYear)
                  .startOf("month")
                  .format("YYYY-MM-DD");
                const endOfMonth = moment()
                  .month(newMonth)
                  .year(newYear)
                  .endOf("month")
                  .format("YYYY-MM-DD");
                if (hotelDetails?.roomTypes) {
                  const initializationPromises = hotelDetails.roomTypes.map(
                    (item) => {
                      const type = item.name;
                      return performApi({ startOfMonth, endOfMonth, type });
                    }
                  );

                  Promise.all(initializationPromises)
                    .then((results) => {
                      const flattenedResults = [].concat(...results);
                      setCellData(flattenedResults);
                    })
                    .catch((err) => {});
                }
                onChange(now);
              }}
            >
              {options}
            </Select>
            <Select
              size="large"
              value={month}
              onChange={(newMonth) => {
                const now = value.clone().month(newMonth);
                const startOfMonth = moment()
                  .month(newMonth)
                  .startOf("month")
                  .format("YYYY-MM-DD");
                const endOfMonth = moment()
                  .month(newMonth)
                  .endOf("month")
                  .format("YYYY-MM-DD");

                if (hotelDetails?.roomTypes) {
                  const initializationPromises = hotelDetails.roomTypes.map(
                    (item) => {
                      const type = item.name;
                      return performApi({ startOfMonth, endOfMonth, type });
                    }
                  );

                  Promise.all(initializationPromises)
                    .then((results) => {
                      const flattenedResults = [].concat(...results);
                      setCellData(flattenedResults);
                    })
                    .catch((err) => {});
                }
                onChange(now);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  };

  const performApi = async (params) => {
    const { startOfMonth, endOfMonth, type } = params;
    let response = await getRoomsData("cm/availabilityRate", {
      dynamicPricing: false,
      endDate: endOfMonth,
      hotelId: hotelDetails?.id,
      page: 0,
      roomType: type,
      roomType: type,
      size: endOfMonth.split("-")[2],
      startDate: startOfMonth,
    });
    setCurrentSelectedDate("");

    return response?.content;
  };

  const onPanelChange = (value) => {
    setCurrentSelectedDate("");
    setCurrentSelectedDate("");
    const currentMonthValue = value.month();
    const startOfMonth = value.clone().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = value.clone().endOf("month").format("YYYY-MM-DD");
    setCurrentMonthStart(startOfMonth);
    setCurrentMonthEnd(endOfMonth);
    setCurrentMonth(currentMonthValue);
    setRoomType(hotelDetails?.roomTypes?.[0].name);
  };

  useEffect(() => {
    setCurrentSelectedDate("");
  }, []);

  const fullCellRender = (current, info) => {
    const eventData = cellData?.find((item) => {
      return (
        moment(item?.forDate).format("YYYY-MM-DD") ===
          current.format("YYYY-MM-DD") && roomType === item?.roomType
      );
    });

    return (
      <CalendarCell
        cellData={cellData}
        current={current}
        data={eventData}
        currentMonth={currentMonth}
        currentSelectedDate={currentSelectedDate}
      />
    );
  };

  const dateSelectHandler = (value) => {
    const selectedCurrDate = moment(value["$d"]).format("YYYY-MM-DD");
    const filteredDateArray = cellData?.filter(
      (date) => moment(date.forDate).format("YYYY-MM-DD") === selectedCurrDate
    );
    setSelectedDateData(filteredDateArray);
    setCurrentSelectedDate(selectedCurrDate);
  };

  return (
    <div className="h-full flex flex-col justify-between p-10 gap-8 bg-[#EAF2FD]">
      <div className="flex justify-between gap-3 items-center bg-[#ffffff] rounded-lg p-2">
        <div className="w-full flex justify-between ">
          <Header heading="Channel Manager" />
          <Button
            type="primary"
            shape="round"
            className="bg-[#2F80ED] text-[#ffffff] font-nunito"
            size="large"
            onClick={() => {
              setUpdateRoomKey(1);
              setExpanded(!expanded);
            }}
          >
            Update Rooms
          </Button>
        </div>
      </div>
      <div className="bg-[#ffffff] px-2 rounded-lg">
        <UpdateRoom setExpanded={setExpanded} expanded={expanded} />
      </div>
      <div className="flex flex-col gap-2 border  p-4 shadow-md bg-[#ffffff] rounded-lg">
        <Calendar
          className="my-calendar"
          headerRender={headerRender}
          fullCellRender={fullCellRender}
          onPanelChange={onPanelChange}
          onSelect={(value) => dateSelectHandler(value)}
        />
        <div className="w-full flex justify-end items-center gap-4">
          <span className="flex items-center gap-2">
            <RedCircle />
            Slow Booking
          </span>
          <span className="flex items-center gap-2">
            {" "}
            <YellowCicle /> Fast Filling
          </span>
          <span className="flex items-center gap-2">
            {" "}
            <BlueCircle /> Sold Out
          </span>
        </div>

        <div className="py-3">
          <hr />
        </div>

        {selectedDateData.length > 0 &&
          selectedDateData.map((item, index) => {
            return <RoomDetailBar key={index} item={item} />;
          })}
      </div>
    </div>
  );
}

export default CMViewPage;
