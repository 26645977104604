import { updateDisplayFilter } from "../../reducer/bookingReducer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading";
import React, { lazy, Suspense } from "react";
import useGetBookingListLogic from "../../hooks/bookingCustomHook/useGetBookingList";
import useStoreBookingDetailsLogic from "../../hooks/bookingCustomHook/useStoreBookingDetails";

const BookingListV2 = lazy(() => import("../../components/bookingListV2"));
const MobileScreenBookingList = lazy(() =>
  import("../../mobileComponents/mobileScreenBookingList/index")
);

const Index = () => {
  const dispatch = useDispatch();
  const { displayFilter, roomPriceStructure } = useSelector(
    (store) => store.booking
  );
  const { isMobileScreen } = useSelector((store) => store.appHeader);

  useGetBookingListLogic();
  useStoreBookingDetailsLogic();

  return (
    <Suspense fallback={<Loading />}>
      <div
        onClick={() => displayFilter && dispatch(updateDisplayFilter(false))}
      >
        {isMobileScreen ? <MobileScreenBookingList /> : <BookingListV2 />}
      </div>
    </Suspense>
  );
};
export default Index;
