import React, { useEffect, Suspense, lazy } from "react";
import Loading from "../../pages/loading";
import { useDispatch, useSelector } from "react-redux";

import {
  refreshWalkinData,
  updateRoomPriceStructure,
  updateSelectedRoomNumberForModify,
  updateTotal,
  updateRoomTotal,
  updateSubTotal,
  updateBalanceDue,
  updateDiscount,
  updateTaxIncludedInPrice,
  updateAdults,
  updateRoomStoredFromBookingCalendar,
  updateAddOnsTotalWithoutTax,
  updateAddOnsTax,
  updateTotalTax,
  updateDoa,
  updateDob,
  updateDetailedPriceBreakup,
  updateRoomChangeMade,
  updateFilteredRoomAvailabiltyArray,
  updateRoomAvailabiltyArray,
  CLEAR_REDUX_STORE_46,
} from "../../reducer/walkinReducer";

import useRoomAvailabiltyLogic from "../../hooks/walkinCustomHook/useAvailabilyLogic";
import {
  addOnsCalculation,
  bookingPriceCalculation,
  mealPlanChangeCalculation,
  modifyDetailedPriceBrekup,
} from "../../utils/bookingHelper";
import useGetMealPlanRate from "../../hooks/bookingCustomHook/useGetPlanRate";

const WalkinContainer = lazy(() =>
  import("../../components/walkinV3/WalkinContainer")
);
const MobileScreenWalkinContainer = lazy(() =>
  import(
    "../../mobileComponents/mobileScreenWalkin/MobileScreenWalkinContainer"
  )
);

const WalkinPageV3 = () => {
  const dispatch = useDispatch();

  const {
    walkin: {
      roomPriceStructure,
      selectedRoomWTypeAndNum,
      mealPlan,
      nightsCount,
      discountInfo,
      checkIn,
      checkOut,
      paymentBreakUps,
      taxIncludedInPriceLocalState,
      detailedPriceBreakup,
      roomsStoredFromBookingReservationCalendar,
      addOnsArray,
      tempSelectedRoomWTypeAndNum,
      roomChangeMade,
      filteredRoomAvailabilityArray,
      roomAvailabiltyArray,
    },
    tax: { taxResponse },
    appHeader: { isMobileScreen },
    login: { hotelDetails, occupancyPlanRate },
  } = useSelector((store) => store);

  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const taxIncludedInPrice = Boolean(hotelDetails?.taxIncludedInPrice);

  /* eslint-disable */
  useEffect(() => {
    const localRoomPriceStructure = roomTypes.map((room) => {
      let rate = room?.rate;

      return {
        name: room.name,
        rate: Boolean(rate) ? rate : 0,
      };
    });

    dispatch(updateRoomPriceStructure(localRoomPriceStructure));

    if (roomsStoredFromBookingReservationCalendar) {
      dispatch(updateRoomStoredFromBookingCalendar(false));

      const currentroomType = roomTypes?.find(
        (Obj) => Obj?.name === Object?.keys(tempSelectedRoomWTypeAndNum)?.[0]
      );
      dispatch(updateAdults(currentroomType?.defaultOccupancy ?? 1));
    } else {
      dispatch(updateSelectedRoomNumberForModify({}));
    }
  }, [
    hotelDetails?.id,
    checkIn,
    checkOut,
    roomTypes?.map((Obj) => Obj.rate).join(","),
  ]);

  useEffect(() => {
    if (Array.isArray(roomPriceStructure) && roomPriceStructure.length) {
      const updatedRoomPriceStructure = mealPlanChangeCalculation(
        roomPriceStructure,
        roomTypes,
        mealPlan,
        occupancyPlanRate
      );

      if (
        Array.isArray(updatedRoomPriceStructure) &&
        updatedRoomPriceStructure.length
      )
        dispatch(updateRoomPriceStructure(updatedRoomPriceStructure));
    }
  }, [mealPlan]);

  // Calculate Prices and Taxes
  useEffect(() => {
    if (roomPriceStructure.length && detailedPriceBreakup.length) {
      const roomPriceCalcResult = bookingPriceCalculation(
        selectedRoomWTypeAndNum,
        roomTypes,
        nightsCount,
        discountInfo,
        paymentBreakUps,
        detailedPriceBreakup,
        taxIncludedInPriceLocalState,
        false,
        taxResponse
      );

      const addOnsCalcResult = addOnsCalculation(addOnsArray, taxResponse);

      dispatch(updateRoomTotal(roomPriceCalcResult?.roomTotal));
      dispatch(updateDiscount(roomPriceCalcResult?.discount));
      dispatch(updateSubTotal(roomPriceCalcResult?.subTotal));
      dispatch(
        updateTotalTax(
          roomPriceCalcResult?.totalRoomTax + addOnsCalcResult?.totalAddOnsTax
        )
      );
      dispatch(
        updateTotal(roomPriceCalcResult?.total + addOnsCalcResult?.addOnsTotal)
      );
      dispatch(
        updateBalanceDue(
          roomPriceCalcResult?.balanceLeft + +addOnsCalcResult?.addOnsTotal
        )
      );
      dispatch(
        updateAddOnsTotalWithoutTax(addOnsCalcResult?.addOnCostWithoutTax)
      );
      dispatch(updateAddOnsTax(addOnsCalcResult?.totalAddOnsTax));
    } else {
      dispatch(updateRoomTotal(0));
      dispatch(updateDiscount(0));
      dispatch(updateSubTotal(0));
      dispatch(updateTotalTax(0));
      dispatch(updateTotal(0));
      dispatch(updateBalanceDue(0));
      dispatch(updateAddOnsTotalWithoutTax(0));
      dispatch(updateAddOnsTax(0));
    }
  }, [
    roomPriceStructure,
    selectedRoomWTypeAndNum,
    nightsCount,
    paymentBreakUps,
    discountInfo,
    taxIncludedInPriceLocalState,
    detailedPriceBreakup,
    addOnsArray,
    taxResponse,
  ]);

  useEffect(() => {
    dispatch(updateTaxIncludedInPrice(taxIncludedInPrice));
    handleOnClick();

    return () => {
      dispatch(refreshWalkinData());
      dispatch(CLEAR_REDUX_STORE_46());
    };
  }, [hotelDetails?.id]);

  useEffect(() => {
    modifyDetailedPriceBrekup(
      detailedPriceBreakup,
      roomTypes,
      occupancyPlanRate,
      mealPlan,
      dispatch,
      updateDetailedPriceBreakup
    );
  }, [occupancyPlanRate]);

  const handleOnClick = () => {
    dispatch(updateDoa(false));
    dispatch(updateDob(false));

    if (roomChangeMade) {
      let localOriginalRoomAvaArray = roomAvailabiltyArray?.map((Obj) =>
        Object.keys(selectedRoomWTypeAndNum).includes(Obj?.name)
          ? { ...Obj, countButtonVisible: true }
          : { ...Obj }
      );

      let localFilteredRoomAvaArray = filteredRoomAvailabilityArray?.map(
        (Obj) =>
          Object.keys(selectedRoomWTypeAndNum).includes(Obj?.name)
            ? { ...Obj, countButtonVisible: true }
            : { ...Obj, countButtonVisible: false }
      );

      dispatch(updateRoomAvailabiltyArray(localOriginalRoomAvaArray));
      dispatch(updateFilteredRoomAvailabiltyArray(localFilteredRoomAvaArray));

      dispatch(updateRoomChangeMade(false));
    }
  };

  useRoomAvailabiltyLogic();
  useGetMealPlanRate(roomPriceStructure, mealPlan);

  return (
    <Suspense fallback={<Loading />}>
      {isMobileScreen ? (
        <MobileScreenWalkinContainer />
      ) : (
        <div onClick={handleOnClick} className="bg-[#F2F4FC] h-[100%]">
          <WalkinContainer />
        </div>
      )}
    </Suspense>
  );
};

export default WalkinPageV3;
