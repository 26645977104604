import { Input, message } from "antd";
import React from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { MdOutlineGTranslate } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CircularProgress, Tooltip } from "@mui/material";
import cookies from "js-cookie";
import { TranslateText } from "../../utils/translation";
import {
  updateOrganisationDropDownDisplay,
  updateNightAuditModalDisplay,
  updateDisplayOverLayingHamburgerMenu,
  updateGloabalSearchText,
  getCurrentNightAuditDate,
} from "../../reducer/appHeaderReducer";
import {
  updateHotelsWithPermission,
  updateSelectedHotels,
} from "../../reducer/loginReducer";
import { IoIosArrowRoundBack } from "react-icons/io";

import Refresh from "../../assetsV2/appHeader/REfresh.svg";
import Settings from "../../assetsV2/appHeader/setting-2.svg";
import Support from "../../assetsV2/appHeader/Support.svg";

import useScreen from "../../hooks/useScreen";
import { getInitials, SCREEN_TYPE } from "../../utils/helper";
import { RxHamburgerMenu } from "react-icons/rx";
import useScriptLoader from "../../hooks/useScriptLoader";
import { updateIsChatActive } from "../../reducer/appReducer";
import { IoMdRefresh } from "react-icons/io";
import Apartments from "../../assets/images/pngs/apartments.png";
import { Dropdown, Space, Typography } from "antd";
import { SUPPORTED_LANGUAGE, LANGUAGES_CODE } from "../../utils/language";

const permissionsMap = {
  Bookings: "BOOKING_VIEW",
};

const activeMenuWithoutPermissions = ["Dashboard", "POS", "Reports"];

const Index = (prop) => {
  const { t, i18n } = useTranslation();
  const currentLanguageCode = cookies.get("i18nextLng") || "en";

  const dispatch = useDispatch();
  const {
    hotelDetails,
    allHotelDetails,
    hotelsWithPermission,
    isAllHotelsSelected,
  } = useSelector((store) => store.login);
  const { menuDrawer, isChatActive, activeMenu } = useSelector(
    (store) => store.app
  );
  const { hotelWiseUserPermissions } = useSelector(
    (store) => store.usersRestriction
  );
  const navigate = useNavigate();

  useScriptLoader(); // load chat script

  const searchBarAllowed = prop?.searchBarAllowed;
  const {
    organisationDropDownDisplay,
    nightAuditDateLoading,
    globalsearchText,
    currentNightAuditDate,
    currentNightAuditDateLoading,
    currentNightAuditDateError,
  } = useSelector((store) => store.appHeader);

  const {
    viewHotelDetails,
    viewRommAllowed,
    editPOSAllowed,
    editRateAndAvailabiltyAllowed,
    editUserAllowed,
    isOwner,
  } = useSelector((store) => store.usersRestriction);

  const handleNightAudit = () => dispatch(updateNightAuditModalDisplay(true));

  let settingsAllowed =
    !isAllHotelsSelected &&
    (viewHotelDetails ||
      viewRommAllowed ||
      editPOSAllowed ||
      editRateAndAvailabiltyAllowed ||
      editUserAllowed ||
      isOwner);

  const hotelInitials = getInitials(hotelDetails?.name);
  const screen = useScreen();

  let isTableOrMobileScreen = Boolean(
    screen === SCREEN_TYPE[0] || screen === SCREEN_TYPE[1]
  );

  let isTabletleScreen = Boolean(screen === SCREEN_TYPE[1]);

  // this will run whenever we select "All Hotels" in organisation dropdown
  useEffect(() => {
    if (isAllHotelsSelected) {
      const hotelsWithPermissionIds = hotelsWithPermission.map(({ id }) => id);
      dispatch(updateSelectedHotels(hotelsWithPermissionIds));
    }
  }, [isAllHotelsSelected, hotelsWithPermission, dispatch]);

  // useEffect hook to filter and set the list of hotels based on the current tab (activeMenu) and user permissions
  useEffect(() => {
    if (
      Array.isArray(allHotelDetails) &&
      allHotelDetails.length > 0 &&
      typeof hotelWiseUserPermissions === "object" &&
      Object.keys(hotelWiseUserPermissions).length > 0
    ) {
      const updatedHotelsWithPermission = allHotelDetails.filter(({ id }) => {
        if (activeMenuWithoutPermissions.includes(activeMenu)) return true;
        return Array.isArray(hotelWiseUserPermissions[id])
          ? hotelWiseUserPermissions[id]?.includes(permissionsMap[activeMenu])
          : false;
      });
      dispatch(updateHotelsWithPermission(updatedHotelsWithPermission));
    }
  }, [allHotelDetails, hotelWiseUserPermissions, activeMenu, dispatch]);

  useEffect(() => {
    dispatch(updateSelectedHotels([hotelDetails?.id]));
  }, [hotelDetails, dispatch]);

  const isValidDateString = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };

  const formatDate = (dateString) => {
    if (isValidDateString(dateString)) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    } else return dateString;
  };

  const items = SUPPORTED_LANGUAGE?.map((item) => {
    return {
      key: item,
      label: (
        <div onClick={() => changeLangauge(item)}>
          {" "}
          {/* <ReactCountryFlag countryCode={item?.toUpperCase()} />{" "} */}
          <span>{LANGUAGES_CODE?.[item]}</span>{" "}
        </div>
      ),
    };
  });

  const changeLangauge = (lanCode) => {
    message.success(
      <TranslateText
        textKey={"language_changed_successfully"}
        namespace="common"
      />
    );
    i18n.changeLanguage(lanCode);
    cookies.set("i18nextLng", lanCode); // Set cookie when language changes
  };

  return (
    <header
      className={`bg-[#EAF2FD] z-[1000] flex justify-between items-center py-2 h-[58px]
       ${
         isTableOrMobileScreen
           ? "left-0 right-0 w-[100%]"
           : menuDrawer
           ? "left-[5rem]"
           : "left-[13rem]"
       }   px-[1.5rem] fixed top-0 right-0 `}
    >
      {Boolean(isTabletleScreen) ? (
        <RxHamburgerMenu
          className="text-[#007FFF] cursor-pointer"
          onClick={() => dispatch(updateDisplayOverLayingHamburgerMenu(true))}
        />
      ) : (
        <></>
      )}

      {Boolean(searchBarAllowed) ? (
        <div className="flex  w-[30%] relative items-center">
          <BsSearch className="z-[10] text-1xl absolute top-[7  px] left-3 text-[#B3B3B3]" />
          <div className=" absolute top-[5px] left-9 w-[0.5px] h-[70%] bg-[#B3B3B3] z-[10]" />

          <Input
            value={globalsearchText}
            onChange={(e) => dispatch(updateGloabalSearchText(e.target.value))}
            placeholder="Search"
            className="bg-[#FAFAFA]   w-full pl-[3rem]  focus:outline-none text-sm border-r-[1px] border-[#00000026] rounded-[5px]"
          />
        </div>
      ) : prop?.page === "walkin" ? (
        <div className="flex items-center gap-2">
          <IoIosArrowRoundBack
            className="cursor-pointer text-[28px] text-[#545454]"
            onClick={() => navigate(-1)}
          />
          <div className="  font-outfit font-[600]  text-[22px] leading-[33px] text-[#545454]      ">
            Create Booking
          </div>
        </div>
      ) : prop?.page === "reports" ? (
        <div className="  font-outfit font-[600]  text-[22px] leading-[33px] text-[#545454]      ">
          Reports
        </div>
      ) : (
        <div></div>
      )}

      <div className="flex gap-8">
        {nightAuditDateLoading ? (
          <button
            className="font-[Popins] font-[400]  leading-[24px] bg-[#eaf2fd] text-[#007fff]
           border-[0.5px] border-[#2f80ed] rounded-[5px] text-[12px] lg:text-[14px]  xl:text-[16px] p-3
         "
          >
            <CircularProgress size={16} color="inherit" />
          </button>
        ) : (
          !isAllHotelsSelected && (
            <div>
              {nightAuditDateLoading ? (
                <button
                  className="font-[Popins] font-[400]  leading-[24px] bg-[#eaf2fd] text-[#007fff]
           border-[0.5px] border-[#2f80ed] rounded-[5px] text-[12px] lg:text-[14px]  xl:text-[16px] p-3
         "
                >
                  <CircularProgress size={16} color="inherit" />
                </button>
              ) : (
                <div className="flex justify-between items-center gap-[16px] py-[7px] px-[10px] bg-[#FFF] rounded-[8px]">
                  <div className="flex flex-col justify-start items-start">
                    <div className="font-outfit font-[500] text-[8px] leading-[12px] tracking-wider text-[#8F9BB3] uppercase">
                      <TranslateText
                        textKey={"current_night_audit"}
                        namespace="common"
                      />
                    </div>
                    <div className="font-outfit font-[500] text-[10px] whitespace-nowrap leading-[15.12px] tracking-[1px] text-[#222B45]">
                      {Boolean(currentNightAuditDateError) ? (
                        <span
                          onClick={() =>
                            dispatch(
                              getCurrentNightAuditDate({
                                hotelId: hotelDetails?.id,
                              })
                            )
                          }
                          className="text-[red] flex items-center gap-[2px] cursor-pointer"
                        >
                          <TranslateText textKey={"error"} namespace="common" />
                          <IoMdRefresh className="text-[12px]" />
                        </span>
                      ) : Boolean(currentNightAuditDateLoading) ? (
                        <CircularProgress size={12} />
                      ) : Boolean(currentNightAuditDate) ? (
                        formatDate(currentNightAuditDate)
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {Boolean(hotelDetails?.nightAuditAutoUpdate) ? (
                    <></>
                  ) : (
                    <button
                      className="font-outfit font-[500]  leading-[17.64px] bg-[#007FFF]
               text-[#FFF]   border-[1px] border-[#007FFF] rounded-[6px] whitespace-nowrap text-[10px] lg:text-[12px] xl:text-[14px] px-[12px] py-[6px] 
         "
                      onClick={handleNightAudit}
                    >
                      <TranslateText
                        textKey={"complete_night_audit"}
                        namespace="common"
                      />
                    </button>
                  )}
                </div>
              )}
            </div>
          )
        )}

        <div className="flex gap-2 items-center justify-between ">
          <Tooltip title="Refresh">
            <div>
              <img
                src={Refresh}
                alt=""
                onClick={() => window.location.reload(true)}
                className="cursor-pointer text-4xl"
              />
            </div>
          </Tooltip>

          <div className="  w-[1px] h-[70%] bg-[#8F9BB3] " />

          {Boolean(settingsAllowed) && (
            <Tooltip title="Settings">
              <div>
                <img
                  alt=""
                  src={Settings}
                  onClick={() => navigate("/dashboard/settings")}
                  className=" cursor-pointer text-4xl"
                />
              </div>
            </Tooltip>
          )}

          {!isAllHotelsSelected && (
            <div className="w-[1px] h-[70%] bg-[#8F9BB3]" />
          )}

          <div
            onClick={() => dispatch(updateIsChatActive(!isChatActive))}
            className="relative"
          >
            <div>
              {isChatActive && (
                <badge className="absolute bg-green-400 text-white px-2 rounded-md top-6 text-xs">
                  <TranslateText textKey={"active"} namespace="common" />
                </badge>
              )}

              <img
                src={Support}
                alt=""
                className="text-[#B3B3B3] hover:text-primary cursor-pointer text-4xl"
              />
            </div>
          </div>

          <div className="w-[1px] h-[70%] bg-[#8F9BB3] " />

          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [currentLanguageCode],
            }}
          >
            <Typography.Link>
              <Space>
                <MdOutlineGTranslate className="text-[#4d4d4d] cursor-pointer text-4xl mt-[10px]" />
              </Space>
            </Typography.Link>
          </Dropdown>
        </div>

        <div
          className="flex gap-2 items-center font-outfit font-[600] text-[12px] lg:text-[16px]  xl:text-[18px] 
         leading-[27px] text-[#4d4d4d] cursor-pointer"
          onClick={(e) => {
            dispatch(
              updateOrganisationDropDownDisplay(!organisationDropDownDisplay)
            );
            e.stopPropagation();
          }}
        >
          <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
            {isAllHotelsSelected ? (
              <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                <img
                  src={Apartments}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            ) : Boolean(hotelDetails?.logo) ? (
              <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                <img
                  src={hotelDetails?.logo}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <>
                {" "}
                <button
                  className="w-[36px] h-[36px] rounded-full whitespace-nowrap
                          border border-black text-black mr-3  text-[12px] font-semibold uppercase"
                >
                  {hotelInitials}
                </button>
              </>
            )}
          </div>
          <span
            className="whitespace-nowrap font-outfit font-[600] text-[12px] lg:text-[16px]  xl:text-[18px] 
         leading-[27px] text-[#4d4d4d]"
          >
            {isAllHotelsSelected
              ? "All Properties"
              : Boolean(hotelDetails) && hotelDetails?.name?.substring(0, 15)}
          </span>
          <FaCaretDown color="#007fff" className="cursor-pointer" />
        </div>
      </div>
    </header>
  );
};

export default Index;
