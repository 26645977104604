import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateActiveFooter } from "../../reducer/appHeaderReducer";
import { POS_TYPE } from "../../utils/posHelper";

import { ReactComponent as homeOutline } from "../../assets/images/mobileDashBoard/homeOutline.svg";
import { ReactComponent as homeFill } from "../../assets/images/mobileDashBoard/homeFill.svg";

import { ReactComponent as bookingOutline } from "../../assets/images/mobileDashBoard/bookingOutline.svg";
import { ReactComponent as bookingFill } from "../../assets/images/mobileDashBoard/bookingFill.svg";

import { ReactComponent as menuFill } from "../../assets/images/mobileDashBoard/menuFill.svg";
import { ReactComponent as menuOutline } from "../../assets/images/mobileDashBoard/menuOutline.svg";

import { ReactComponent as posFill } from "../../assets/images/mobileDashBoard/posFill.svg";
import { ReactComponent as posOutline } from "../../assets/images/mobileDashBoard/posOutline.svg";

function Index() {
  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );

  const { activeFooter } = useSelector((store) => store.appHeader);

  const footerOptions = [
    {
      title: "Dashboard",
      InactiveIcon: homeOutline,
      ActiveIcon: homeFill,
      link: "",
      id: 1,
    },
    {
      title: "Bookings",
      InactiveIcon: bookingOutline,
      ActiveIcon: bookingFill,
      link:
        hotelDetails?.bookingView === "calender"
          ? "/bookingReservation"
          : "/bookings",
      id: 2,
    },
    {
      title: "POS",
      InactiveIcon: posOutline,
      ActiveIcon: posFill,
      link: "",
      id: 3,
    },
    {
      title: "Menu",
      InactiveIcon: menuOutline,
      ActiveIcon: menuFill,
      link: "/menu",
      id: 4,
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activePosSlug, activePosType } = useSelector(
    (store) => store.posSetting
  );

  const isBanquetNewOrder =
    activePosType == POS_TYPE[1] ||
    activePosType == POS_TYPE[3] ||
    activePosType == POS_TYPE[4] ||
    activePosType == POS_TYPE[5]
      ? footerOptions.title?.toLowerCase() == "new order"
      : null;

  const redirectPage = (title, index) => {
    // if banquet or spa or salon , new order will open directly
    if (isBanquetNewOrder) {
      // handleBanquetOrder();
    } else {
      if (isAllHotelsSelected && index == 2) index = 3;

      let link =
        title?.toLowerCase() === "pos"
          ? `/dashboard/${activePosSlug}/`
          : "/dashboard/" + footerOptions[index]?.link;

      navigate(link);
    }
    dispatch(updateActiveFooter(title));
  };

  return (
    <footer className="fixed bottom-0">
      <div className="flex justify-between fixed bottom-[0] w-[100%] h-[3.6rem] no-scrollbar::-webkit-scrollbar no-scrollbar overflow-x-auto scrollbar-hidden bg-[#FFF]">
        {footerOptions
          ?.filter((obj) => (isAllHotelsSelected ? obj.title !== "POS" : true))
          ?.map((Obj, index) => {
            return (
              <div
                onClick={() => redirectPage(Obj?.title, index)}
                className={`${
                  activeFooter === Obj?.title
                    ? "border-t-[#292D32]"
                    : " border-t-[#FFF]"
                } "bg-[#FFF] border-t-[2px] w-[50%]  border-0 px-4  flex flex-col gap-1 justify-center items-center`}
              >
                <div
                  className={`${
                    activeFooter === Obj?.title
                      ? "text-[#292D32] "
                      : " text-[#8F9BB3]"
                  } "w-[100px] border-0 `}
                >
                  {Obj?.title === activeFooter ? (
                    <Obj.ActiveIcon
                      className={` ${
                        activeFooter === Obj?.title
                          ? " text-[#292D32] "
                          : " text-[#8F9BB3]"
                      } text-[20px]`}
                    />
                  ) : (
                    <Obj.InactiveIcon className="text-[20px]" />
                  )}
                </div>
                <div
                  className={`${
                    activeFooter === Obj?.title
                      ? "font-[600] text-[#111111]"
                      : "font-[500] text-[#8F9BB3]"
                  }  font-outfit text-[10px] leading-[15px] `}
                >
                  {Obj?.title}
                </div>
              </div>
            );
          })}
      </div>
    </footer>
  );
}

export default Index;
