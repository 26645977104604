import React from "react";
import { Button, Upload } from "antd";

function UploadFile({ handleUpload, handleImageBeforeUpload, width }) {
  return (
    <div>
      <Upload
        multiple={true}
        customRequest={handleUpload}
        beforeUpload={handleImageBeforeUpload}
        showUploadList={false}
        accept=".png,.jpg,.jpeg,.svg" // Updated file types
      >
        <Button
          className={`flex items-center justify-center w-full max-w-[231px] h-[54px]  p-[24px] text-lg font-semibold text-center rounded-[10px] shadow-custom uppercase text-[#007FFF] cursor-pointer`}
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
}

export default UploadFile;
