import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import "../assets/styles/global.css";

import Login from "../pages/login";
import Signup from "../pages/signup";
import OnBoarding from "../pages/onboarding";
import DashBoard from "../pages/dashboard";
import Confirm from "../pages/confirm/index";
import ProtectedRoute from "../components/ProtectredRoute/ProtectedRoute";
import NotFoundPage from "../pages/notFound";
import POS from "../pages/pos/Index";
import BookingListV2 from "../pages/bookingListV2";
import TableView from "../pages/pos/TableView";
import NewOrder from "../pages/pos/NewOrder";
import CloseOrder from "../pages/pos/CloseOrder";
import ErrorBoundary from "../components/ErrorBoundary";
import CMViewPage from "../components/channelManager/CMViewPage";
import ComingSoon from "../pages/comingSoon";
import Settings from "../pages/profile";
import MainReportsPage from "../pages/report/MainReportsPage";
import Home from "../pages/home";
import BookingReservationPage from "../pages/bookingReservation";
import KitchenDisplaySystem from "../pages/pos/kitchenDisplaySystem";
import { pathPrefix } from "../constants/hiddenConstants";
import WalkinPageV3 from "../pages/walkinV3";
import AllItems from "../pages/pos/AllItems";
import PosSetting from "../pages/pos/PosSetting";
import CreateNewPos from "../pages/pos/CreateNewPos";
import ClientPage from "../pages/clients";
import RatesAndAvailabilityPage from "../pages/ratesAndAvailability";

import HousekeepingPage from "../pages/housekeepingV2";

import Party from "../pages/party";
import Expense from "../pages/expense";

import MobileMenu from "../pages/mobileMenu/Index";
import ManageStocksV2 from "../pages/manageStocksV2";

import Payments from "../pages/payments/Index";
import Invoice from "../pages/invoice/Index";

import { EmptyPosScreen } from "../pages/pos/EmptyPosScreen";

export default function Router() {
  return (
    <BrowserRouter basename={pathPrefix}>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary>
              <Login />
            </ErrorBoundary>
          }
        />
        <Route
          path="/login"
          element={
            <ErrorBoundary>
              <Login />
            </ErrorBoundary>
          }
        />
        <Route
          path="/signup"
          element={
            <ErrorBoundary>
              <Signup />
            </ErrorBoundary>
          }
        />
        <Route
          path="/onBoarding"
          element={
            <ProtectedRoute>
              <OnBoarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirmed"
          element={
            <ProtectedRoute>
              <Confirm />
            </ProtectedRoute>
          }
        />

        <Route path="/dashboard" element={<DashBoard />}>
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path="walkin"
            element={
              <ProtectedRoute>
                <WalkinPageV3 />
              </ProtectedRoute>
            }
          />

          <Route
            path="bookings"
            element={
              <ProtectedRoute>
                <BookingListV2 />
              </ProtectedRoute>
            }
          />

          <Route
            path="bookingReservation"
            element={
              <ProtectedRoute>
                <BookingReservationPage />
              </ProtectedRoute>
            }
          />

          <Route
            path=":slug"
            element={
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<EmptyPosScreen />} />
            <Route path="new-order" element={<NewOrder />} />
            <Route path="ingredient" element={<ComingSoon />} />
            <Route path="close-order" element={<CloseOrder />} />
            <Route path="all-items" element={<AllItems />} />
            <Route path="pos-settings" element={<PosSetting />} />

            <Route path="menu-settings" element={<ComingSoon />} />
            <Route path="inventory-purchase" element={<ComingSoon />} />
            <Route path="table-view" element={<TableView />} />
            <Route path="all-orders" element={<POS />} />
            <Route
              path="kitchen-display-system"
              element={<KitchenDisplaySystem />}
            />
          </Route>

          <Route
            path="add-new-pos"
            element={
              <ProtectedRoute>
                <CreateNewPos />
              </ProtectedRoute>
            }
          />
          <Route
            path="channel-manager-old-version"
            element={
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<CMViewPage />} />
          </Route>

          <Route
            path="repair"
            element={
              <ProtectedRoute>
                <ComingSoon />
              </ProtectedRoute>
            }
          />
          <Route
            path="transactions"
            element={
              <ProtectedRoute>
                <ComingSoon />
              </ProtectedRoute>
            }
          />

          <Route
            path="clients"
            element={
              <ProtectedRoute>
                <ClientPage />
              </ProtectedRoute>
            }
          />

          {/* Only for Mobile Screen */}
          <Route
            path="menu"
            element={
              <ProtectedRoute>
                <MobileMenu />
              </ProtectedRoute>
            }
          />

          <Route
            path="books"
            element={
              <ProtectedRoute>
                <ComingSoon />
              </ProtectedRoute>
            }
          />
          <Route
            path="reports"
            element={
              <ProtectedRoute>
                <MainReportsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="channel-manager"
            element={
              <ProtectedRoute>
                <RatesAndAvailabilityPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="house-keeping"
            element={
              <ProtectedRoute>
                <HousekeepingPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="party"
            element={
              <ProtectedRoute>
                <Party />
              </ProtectedRoute>
            }
          />

          <Route
            path="expense"
            element={
              <ProtectedRoute>
                <Expense />
              </ProtectedRoute>
            }
          />

          <Route
            path="manageStocks"
            element={
              <ProtectedRoute>
                <ManageStocksV2 />
              </ProtectedRoute>
            }
          />

          <Route
            path="payments"
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path="invoice"
            element={
              <ProtectedRoute>
                <Invoice />
              </ProtectedRoute>
            }
          />

          <Route
            path="settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
