import { authedAxios } from "../utils/customHttpHeader";

const getRoomsAvailability = async (payload, endpoint) => {
  const queryString = `endDate=${payload.endDate}&hotelId=${
    payload.hotelId
  }&startDate=${payload.startDate}&excludeBookingIds=${[payload.bookingId]}`;

  try {
    const response = await authedAxios().get(endpoint + "?" + queryString);
    return response;
  } catch (error) {
    return error;
  }
};

const postWalkin = async (payload, endpoint) => {
  const response = await authedAxios().post(endpoint, payload);
  return response;
};

export { getRoomsAvailability, postWalkin };
