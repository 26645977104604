import { Button } from "antd";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";

import {
  createTableOrder,
  updateTransferTableStatus,
} from "../../reducer/posReducer/restaurant";
import { updateReservationModalStatus } from "../../reducer/reservationReducer";
import useResetState from "../../hooks/useResetState";

const Header = ({ heading, pos, back = null, icon = null, posType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetState } = useResetState();

  const { hotelDetails } = useSelector((store) => store.login);

  const { isTransferTableActive } = useSelector((store) => store.pos);
  const { isReservationModalVisible } = useSelector(
    (store) => store.tableReservation
  );

  const { activePosName, activePosType, activePosSlug } = useSelector(
    (store) => store.posSetting
  );

  const [sticky, setSticky] = useState("");
  let [scroll, setScroll] = useState(0);

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [scroll]);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 150 ? true : false;
    setSticky(stickyClass);
    setScroll(scrollTop);
  };

  const logout = () => {
    resetState();
    window.localStorage.clear("token");
    navigate("/");
  };

  const delivery = () => {
    const payload = {
      tableNumber: null,
      stewardname: "",
      customername: null,
      numberOfPeople: 1,
      type: "delivery",
      customerId: null,
      hotelId: hotelDetails?.hotelId,
      roomId: null,
      bookingId: null,
    };

    dispatch(createTableOrder(payload));
    navigate(`/dashboard/${activePosSlug}/new-order`);
  };

  const pickup = () => {
    const payload = {
      tableNumber: null,
      stewardname: "",
      customername: null,
      numberOfPeople: 1,
      type: "pickup",
      customerId: null,
      hotelId: hotelDetails?.hotelId,
      roomId: null,
      bookingId: null,
    };

    dispatch(createTableOrder(payload));
    navigate(`/dashboard/${activePosSlug}/new-order`);
  };

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [scroll]);

  return (
    <div className="sm:flex sm:justify-between gap-4 sticky mb-5">
      <div className="col-span-12 md-col-span-6">
        <h1 className="text-3xl font-semibold capitalize ml-2 mb-2 flex">
          {" "}
          <IoMdArrowBack
            className="mr-2 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          {heading}
          {Boolean(activePosType) && (
            <span className="text-sm ml-2 mt-4">({activePosType})</span>
          )}
        </h1>
      </div>
      <div>
        {pos && (
          <div className="md-col-span-6 ml-2">
            {(activePosType === "Restaurant" ||
              activePosType === "Laundry") && (
              <div className="flex justify-between">
                {activePosType === "Restaurant" && (
                  <div className="flex mr-1 sm:mr-2">
                    <Button
                      onClick={() =>
                        dispatch(
                          updateTransferTableStatus(!isTransferTableActive)
                        )
                      }
                      shape="round"
                      className={`
                      ${
                        isTransferTableActive ? "bg-primary text-white" : ""
                      } mr-2`}
                    >
                      Transfer Table
                    </Button>

                    <Button
                      onClick={() =>
                        dispatch(
                          updateReservationModalStatus(
                            !isReservationModalVisible
                          )
                        )
                      }
                      shape="round"
                    >
                      Reserve
                    </Button>
                  </div>
                )}

                <div className="flex">
                  {activePosType === "Restaurant" && (
                    <Button
                      className="mr-2"
                      onClick={() => delivery()}
                      type="primary"
                      danger
                    >
                      Delivery
                    </Button>
                  )}

                  <Button
                    onClick={() => pickup()}
                    className="mr-3"
                    danger
                    classNames="bg-red-600"
                  >
                    Pickup
                  </Button>

                  {/* <Button>Dine In</Button> */}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {heading.toLowerCase() == "home" && (
        <div className="text-right">
          <h1 className="text-2xl font-semi-bold capitalize">
            <span className="text-white bg-primary py-0 px-3 rounded mr-1 uppercase">
              {hotelDetails?.name?.split(" ")?.[0]?.[0]}
              {hotelDetails?.name?.split(" ")?.[1]?.[0]}
            </span>
            {hotelDetails?.name}
          </h1>
          {/*<Button className="mt-2" onClick={logout}>
            Log Out
          </Button>*/}
        </div>
      )}
    </div>
  );
};
export default Header;
