import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bookingList } from "../services/bookingReservation";
import moment from "moment";

export const getBookingList = createAsyncThunk(
  "getBookingList",
  async (payload) => {
    const data = {
      startDate: payload.startDate,
      endDate: payload.endDate,
      hotelId: payload.hotelId,
      statuses: payload.statuses,
      pageSize: 1000,
    };

    try {
      const response = await bookingList({ ...payload, ...data }, "bookings");
      return response;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  fromDate: moment().startOf("day"),
  toDate: moment().add(6, "day"),
  bookingResponse: [], // store  booking list json data into it
  searchData: [],
  displayCustomCalendar: false,
  selectedCheckInDate: "",
  selectedCheckOutDate: "",
  openFilter: false,
  filterTitle: "Week",
  displayMobileViewDownloadAndPrint: false,

};

export const bookingReservationSlice = createSlice({
  name: "bookingReservation",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_13: () => initialState,

    updateDates: (state, actions) => {

      if (actions.payload.from) {
        state.fromDate = actions.payload.from;
        state.toDate = actions.payload.to;
      } else if (actions.payload.to) {
        state.toDate = actions.payload.to;
      } else {
        state.fromDate = moment().startOf("day");
        state.toDate = moment().add(6, "day");
      }
    },
    updateDisplayCustomCalendar: (state, action) => {
      state.displayCustomCalendar = action.payload;
    },
    updateSelectedCheckInDate: (state, action) => {
      state.selectedCheckInDate = action.payload;
    },
    updateSelectedCheckOutDate: (state, action) => {
      state.selectedCheckOutDate = action.payload;
    },
    updateDisplayFilter: (state, action) => {
      state.openFilter = action.payload;
    },

    updateSetFilterTitle: (state, action) => {
      state.filterTitle = action.payload;
    },

    searchUser: (state, action) => {
      state.searchData = action.payload;
    },

    updateBookingListData: (state, action) => {
      state.loading = action.payload.loading;
      state.bookingResponse = action.payload;
      state.apiError = action.payload.apiError;
    },
    updateDisplayMobileDownloadAndPrintModal: (state, action)=>{
      state.displayMobileViewDownloadAndPrint = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getBookingList.pending, (state, action) => {
      state.loading = true;
      state.bookingResponse = [];
      state.apiError = false;
    });
    builder.addCase(getBookingList.fulfilled, (state, action) => {
      state.loading = false;
      state.bookingResponse = action.payload;
      // state.bookingResponse = [];
      state.apiError = false;
    });
    builder.addCase(getBookingList.rejected, (state, action) => {
      state.loading = false;
      state.bookingResponse = [];
      state.apiError = true;
    });
  },
});

export const {
  CLEAR_REDUX_STORE_13,
  updateDates,
  searchUser,
  updateDisplayCustomCalendar,
  updateSelectedCheckInDate,
  updateSelectedCheckOutDate,
  updateDisplayFilter,
  updateSetFilterTitle,
  updateBookingListData,
  updateDisplayMobileDownloadAndPrintModal
} = bookingReservationSlice.actions;

export default bookingReservationSlice.reducer;
