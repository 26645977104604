import React, { useState, useEffect } from "react";
import CloseIcon from "../../assets/images/svgs/CloseIcon.svg";
import { Input, Tooltip } from "antd";
import { updateOrganisationDropDownDisplay } from "../../reducer/appHeaderReducer";

import { BsSearch } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import LogoutSymbol from "../../assets/images/svgs/LogoutSymbol.svg";
import { useNavigate } from "react-router-dom";
import { updateHotel } from "../../reducer/loginReducer";
import {
  updateSelectedHotels,
  updateIsAllHotelsSelected,
} from "../../reducer/loginReducer";

import useResetState from "../../hooks/useResetState";
import { getInitials } from "../../utils/helper";
import Apartments from "../../assets/images/pngs/apartments.png";
import { TranslateText } from "../../utils/translation";

const OrganisationDropDown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetState } = useResetState();

  const { hotelDetails, isAllHotelsSelected } = useSelector(
    (store) => store.login
  );
  const { allHotelDetails } = useSelector((store) => store.login);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchText = (e) => {
    let value = e?.target?.value;

    setSearchText(value);

    if (value?.trim() === "") setFilteredData(allHotelDetails);
    else {
      const filteredResult = Array.isArray(allHotelDetails)
        ? allHotelDetails?.filter((hotel) =>
            hotel?.name?.toLowerCase()?.includes(value?.toLowerCase())
          )
        : [];
      setFilteredData(filteredResult);
    }
  };

  const handleLogOut = () => {
    resetState();
    window.localStorage.clear("hotelId");
    window.localStorage.clear("token");
    navigate("/");
  };

  useEffect(() => {
    setFilteredData(allHotelDetails);
  }, [allHotelDetails, hotelDetails]);

  const handleHotelChange = (e, hotel) => {
    e.stopPropagation();
    localStorage.removeItem("hotelId");
    localStorage.setItem("hotelId", hotel?.id);
    dispatch(updateIsAllHotelsSelected(false));

    dispatch(updateSelectedHotels([hotel?.id]));

    dispatch(updateHotel(hotel));
  };

  const handleClickAllHotels = () => {
    dispatch(updateIsAllHotelsSelected(true));
  };

  return (
    <div
      style={{ zIndex: 10000 }}
      className="absolute top-[3.6rem] right-[0] w-[300px] h-[400px]  z-[20]
       bg-[#FFF] shadow-lg overflow-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-[100%] " style={{ zIndex: 10000000 }}>
        <div className="bg-[#D9D9D9] w-[100%] flex justify-between px-2 items-center py-3 ">
          <div className="font-[Poppins] font-[600] text-[1rem] leading-[24px] text-[#4D4D4D]">
            <TranslateText textKey={"organisations"} namespace="manageStocks" />
          </div>
          <div className="flex gap-3">
            <div
              className=" cursor-pointer"
              onClick={() => dispatch(updateOrganisationDropDownDisplay(false))}
            >
              <img src={CloseIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="flex  w-[100%] px-3 py-5 relative items-center">
          <BsSearch className="z-[10] text-1xl absolute top-[1.7rem] left-[18px] text-[#B3B3B3]" />
          <div className=" absolute top-[1.5rem] left-[38px] w-[0.5px] h-[1.4rem] bg-[#B3B3B3] z-[10]" />

          <Input
            value={searchText}
            onChange={(e) => handleSearchText(e)}
            placeholder="Search"
            className="bg-[#FAFAFA]   w-full pl-[30px]  text-sm border-r-[1px] border-[#00000026] rounded-[5px]"
          />
        </div>
        <div
          className="faixed w-[290px] right-0 mr-1  overflow-auto
           max-h-[250px] flex flex-col gap-2"
        >
          {allHotelDetails?.length > 1 && (
            <div
              className={`${
                isAllHotelsSelected
                  ? "bg-[#DFEEFD] border-l-2 border-blue-500 rounded-r-md border-solid  py-2 "
                  : ""
              } flex justify-between items-center px-4 cursor-pointer`}
              onClick={handleClickAllHotels}
            >
              <div className="flex gap-4 items-center">
                <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                  <img
                    src={Apartments}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-outfit font-[600] text-[1rem] leading-[24px] text-[#4D4D4D] flex flex-wrap flex-col  gap-2 items-center ">
                  <TranslateText
                    textKey={"all_aroperties"}
                    namespace="manageStocks"
                  />
                </div>
              </div>

              <div className="font-[Poppins] font-[500] text-[12px] leading-[18px] text-[#4D4D4D]"></div>
            </div>
          )}

          {Array.isArray(filteredData) &&
            filteredData?.map((hotel) => {
              const hotelName = Boolean(hotel?.name)
                ? hotel?.name?.split(" ")
                : "";

              const firstLetters = Boolean(hotelName)
                ? hotelName?.slice(0, 2)?.map((word) => word?.charAt(0))
                : "";

              const hotelAcronym = Boolean(firstLetters)
                ? firstLetters.join("") + "-" + hotel?.hotelNo
                : "";

              const hotelInitials = getInitials(hotel?.name);

              return (
                <div
                  key={hotel?.id}
                  className={`${
                    hotel?.id === hotelDetails?.id && !isAllHotelsSelected
                      ? "bg-[#DFEEFD] border-l-2 border-blue-500 rounded-r-md border-solid  py-2 "
                      : ""
                  } flex justify-between items-center px-4 cursor-pointer`}
                  onClick={(e) => handleHotelChange(e, hotel)}
                >
                  <div className="flex gap-4 items-center">
                    {Boolean(hotel?.logo) ? (
                      <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                        <img
                          src={hotel?.logo}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <button
                          className="w-[36px] h-[36px] rounded-full whitespace-nowrap
                          border border-black text-black mr-3  text-[12px] font-semibold uppercase"
                        >
                          {hotelInitials}
                        </button>
                      </>
                    )}

                    <Tooltip
                      zIndex={1000000}
                      title={hotel?.name}
                      placement="left"
                      className="font-outfit font-[600] text-[1rem] leading-[24px] text-[#4D4D4D] flex flex-wrap flex-col gap-2 items-center"
                    >
                      {hotel?.name && hotel.name.length > 14
                        ? `${hotel.name.substring(0, 14)}...`
                        : hotel?.name}
                    </Tooltip>
                  </div>

                  <div className="font-[Poppins] font-[500] text-[12px] leading-[18px] text-[#4D4D4D]">
                    {hotelAcronym}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="absolute bottom-0 left-0 w-[100%] bg-[#FFF] z-[100]">
          <div className="w-[90%] mx-3 bg-[#D9D9D9] h-[1px] cursor-pointer" />
          <div
            className="w-[100%] flex justify-center my-2 items-center cursor-pointer"
            onClick={handleLogOut}
          >
            <img src={LogoutSymbol} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationDropDown;
