import { useDispatch, useSelector } from "react-redux";
import {
  GET_ITEM_PRICE,
  ITEMS_TOTAL_AMOUNT,
  POS_SERVICE_TYPE,
  POS_TYPE,
} from "../../utils/posHelper";
import {
  updateDisplayCloseOrderDrawer,
  updateDisplayDiscountDrawer,
  updateDisplayTakePaymentDrawer,
  updateExcludeGST,
} from "../../reducer/posReducer/restaurant";
import {
  createNewOrder,
  getInvoiceById,
  takePayment,
  updateAllOrdersByStatus,
  updateInvoice,
  updateOrderAndInvoice,
} from "../../reducer/orderReducer";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { deletePayment, updatePayment } from "../../reducer/paymentReducer";

const useAllPosFunctions = (setLoading) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hotelDetails } = useSelector((store) => store.login);
  const { data: allItems } = useSelector((store) => store.getItems);

  const { selectedItem, customerDetails, selectedSteward, selectedRider } =
    useSelector((store) => store.pos);

  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);

  const {
    response: posData,
    activePosSlug,
    activePosId,
    activePosType,
  } = useSelector((store) => store.posSetting);

  const { data: order, invoicePostData } = useSelector((store) => store.order);

  let currentPos = posData?.find((item) => item.slug === activePosSlug) || {};
  let fallBackTaxIds = currentPos?.taxIds;
  fallBackTaxIds = Array.isArray(fallBackTaxIds) ? fallBackTaxIds : [];

  let selectedItemWithQuantity = [];
  let itemCountMap = {};
  let itemTotalAmount = 0;

  Object.keys(selectedItem)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id && selectedItem[id].count !== 0) {
        selectedItemWithQuantity.push({
          ...item,
          quantity: selectedItem[id].count,
        });

        itemTotalAmount +=
          GET_ITEM_PRICE(tableOrderDetails.type, activePosType, item) *
          selectedItem[id].count;

        itemCountMap[id] = {
          count: selectedItem[id].count,
          instruction: Boolean(selectedItem[id].instruction)
            ? selectedItem[id].instruction
            : null,
        };

        // calculate item addon price
        item.addOns?.forEach((addonItem) => {
          if (Boolean(selectedItem[id][addonItem.name])) {
            //  price * qty
            itemTotalAmount +=
              addonItem.price * selectedItem[id][addonItem.name];

            itemCountMap[id] = {
              ...itemCountMap[id],
              addOns: {
                ...itemCountMap?.addOns,
                [addonItem?.name]: selectedItem[id][addonItem.name],
              },
            };
          }
        });
      }
    });
  });

  const isvalidKOT = () => {
    message.destroy();

    if (activePosType !== POS_TYPE[0]) {
      if (Object.keys(selectedItem).length <= 0) {
        message.error("Please select the item first");
        return false;
      } else if (!customerDetails?.name) {
        message.error("Please Enter customer name ...");
        return false;
      } else if (!customerDetails?.phone) {
        message.error("Please Enter customer phone ...");
        return false;
      } else if (!customerDetails?.address) {
        message.error("Please Enter customer address ...");
        return false;
      } else return true;
    } else if (tableOrderDetails?.type === "delivery") {
      if (Object.keys(selectedItem).length <= 0) {
        message.error("Please select the item first");
        return false;
      } else if (!selectedRider) {
        message.error("Please select a rider ...");
        return false;
      } else if (!customerDetails?.name) {
        message.error("Please Enter customer name ...");
        return false;
      } else if (!customerDetails?.phone) {
        message.error("Please Enter customer phone ...");
        return false;
      } else if (!customerDetails?.address) {
        message.error("Please Enter customer address ...");
        return false;
      } else return true;
    } else if (tableOrderDetails?.type === "pickup") {
      if (Object.keys(selectedItem).length <= 0) {
        message.error("Please select the item first");
        return false;
      } else if (!customerDetails?.name) {
        message.error("Please Enter customer name ...");
        return false;
      } else if (!customerDetails?.phone) {
        message.error("Please Enter customer phone ...");
        return false;
      } else return true;
    } else {
      if (Object.keys(selectedItem).length <= 0) {
        message.error("Please select the item first");
        return false;
      } else return true;
    }
  };

  const handleKOTEdit = async (
    selectedOrderItem,
    priceBreakUp,
    itemCountMap,
    handleCancel
  ) => {
    if (
      !(
        tableOrderDetails.roomId === invoicePostData.roomId ||
        tableOrderDetails.tableId === invoicePostData.tableId
      )
    ) {
      return; // Exit if the room or table doesn't match
    }

    // Create the order payload
    const orderPayload = {
      itemCountMap: itemCountMap,
      id: selectedOrderItem?.id,
      status: "inProgress",
    };

    // Handle price breakdown
    let invoicePriceBreakUp = invoicePostData?.priceBreakup || [];
    let totalPriceBreakUp = [];

    const isItemExistInPriceBreakUp = invoicePriceBreakUp?.some(
      (item) => item.orderId === selectedOrderItem?.id
    );

    if (invoicePriceBreakUp.length > 0) {
      if (isItemExistInPriceBreakUp) {
        // Update the existing order's price breakdown
        totalPriceBreakUp = invoicePriceBreakUp.map((item) =>
          item.orderId === selectedOrderItem?.id
            ? {
                ...item,
                count: priceBreakUp.find((obj) => obj?.id === item?.itemId)
                  ?.count,
                addOns: priceBreakUp.find((obj) => obj?.id === item?.itemId)
                  ?.addOns,
              }
            : item
        );
      } else {
        // Add new items to the price breakdown
        const newItems = priceBreakUp.map((item) => ({
          ...item,
          orderId: selectedOrderItem?.id,
        }));

        totalPriceBreakUp = [...invoicePriceBreakUp, ...newItems];
      }
    } else {
      totalPriceBreakUp = priceBreakUp.map((item) => ({
        ...item,
        orderId: selectedOrderItem?.id,
      }));
    }

    // Filter out items with no count
    totalPriceBreakUp = totalPriceBreakUp.filter((obj) => obj?.count);

    // Calculate the subtotal for the invoice
    const itemSubTotal = ITEMS_TOTAL_AMOUNT(totalPriceBreakUp);

    // Create the invoice payload
    const invoicePayload = {
      ...invoicePostData,
      priceBreakup: totalPriceBreakUp,
      subTotal: itemSubTotal,
      setTotalAsPerTax: true,
    };

    // Dispatch the action to update order and invoice
    const payload = {
      orderPayload: orderPayload,
      invoicePayload: invoicePayload,
    };

    await dispatch(updateOrderAndInvoice(payload));
    handleCancel && handleCancel();
  };

  const handleKOT = (
    status,
    isCallCreateBill = false,
    setOrderDetailScreenVisible,
    setMultipleKOTScreenVsisible
  ) => {
    if (!isvalidKOT()) return;

    const orderPayload = {
      bookingId: tableOrderDetails.bookingId,
      customer: tableOrderDetails.customerId,
      hotelId: hotelDetails?.id,
      itemCountMap: itemCountMap,
      numberOfPeople: tableOrderDetails.numberOfPeople,
      roomId: tableOrderDetails.roomId,
      status: status,
      tableId: tableOrderDetails.tableId,
      posId: activePosId,
      stewardNames: selectedSteward,
      orderSource: getOrderSource(),

      deliveryPickUp:
        tableOrderDetails.type === "pickup"
          ? {
              mode: "pickup",
              source: "app",
            }
          : tableOrderDetails.type === "delivery"
          ? {
              mode: "delivery",
              source: "app",
            }
          : null,
    };

    //  if invoice id present or order for the same table
    if (Boolean(invoicePostData?.id)) {
      if (
        tableOrderDetails.roomId == invoicePostData.roomId ||
        tableOrderDetails.tableId == invoicePostData.tableId
      )
        updateInvoiceWithNewOrder(
          orderPayload,
          isCallCreateBill,
          setOrderDetailScreenVisible,
          setMultipleKOTScreenVsisible
        );
      else
        createOrderWithInvoice(
          orderPayload,
          isCallCreateBill,
          setOrderDetailScreenVisible,
          setMultipleKOTScreenVsisible
        );
    } else
      createOrderWithInvoice(
        orderPayload,
        isCallCreateBill,
        setOrderDetailScreenVisible,
        setMultipleKOTScreenVsisible
      );
  };

  const createOrderWithInvoice = async (
    orderPayload,
    isCallCreateBill,
    setOrderDetailScreenVisible,
    setMultipleKOTScreenVsisible
  ) => {
    let priceBreakup = [];

    if (orderPayload?.status == "inProgress") {
      allItems?.forEach((item) => {
        let type = "";
        let rate = 0;
        let count = 0;
        let addOnPriceBreakDown = [];
        let itemId = "";

        if (
          Boolean(selectedItem[item.id] && selectedItem[item.id]?.count > 0)
        ) {
          type = item.name;
          rate = GET_ITEM_PRICE(tableOrderDetails.type, activePosType, item);
          count = selectedItem[item.id]?.count;
          itemId = item.id;

          item?.addOns?.forEach((addOn) => {
            if (Boolean(selectedItem[item.id][addOn.name])) {
              addOnPriceBreakDown.push({
                count: selectedItem[item.id][addOn.name], // count
                name: addOn.name,
                price: addOn.price,
              });
            }
          });

          let taxIds =
            Array.isArray(item?.taxIds) && item?.taxIds?.length
              ? item?.taxIds
              : fallBackTaxIds;

          priceBreakup.push({
            addOns:
              addOnPriceBreakDown?.length > 0 ? addOnPriceBreakDown : null,
            count: count,
            rate: rate,
            baseRate: rate,
            type: type,
            itemId: itemId,
            taxIds: taxIds,
          });
        }
      });
    }

    // When We create invoice as inpprogress invoice So we dont calculate tax at this point

    // invoice
    const invoicePayload = {
      bookingId: tableOrderDetails?.bookingId,
      customerId: tableOrderDetails?.customerId,
      hotelId: hotelDetails?.id,

      numberOfPeople: tableOrderDetails?.numberOfPeople || 1,
      priceBreakup: priceBreakup?.length > 0 ? priceBreakup : null,

      roomId: tableOrderDetails.roomId,
      status: "inProgress",

      subTotal: orderPayload?.status === "held" ? 0 : itemTotalAmount,
      total: orderPayload?.status === "held" ? 0 : itemTotalAmount,

      posId: activePosId,
      tableId: tableOrderDetails?.tableId,

      totalTax: 0,

      type: "pos",
      invoiceType: "pos",
      deliveryPickUp: orderPayload?.deliveryPickUp,
      orderSource: getOrderSource(),
      setTotalAsPerTax: true,
    };

    // after creating order, create invoice
    const response = await dispatch(
      createNewOrder({
        orderPayload: orderPayload,
        invoicePayload: invoicePayload,
        status: "create", // it will define whether invoice will be created or updated
      })
    );

    if (isCallCreateBill) {
      createBill(
        response?.payload?.invoiceData?.payload,
        response?.payload?.orderData,
        isCallCreateBill
      );
    }

    Boolean(setOrderDetailScreenVisible) && setOrderDetailScreenVisible(false);
    Boolean(setMultipleKOTScreenVsisible) && setMultipleKOTScreenVsisible(true);
  };

  const updateInvoiceWithNewOrder = async (
    orderPayload,
    isCallCreateBill,
    setOrderDetailScreenVisible,
    setMultipleKOTScreenVsisible
  ) => {
    let priceBreakup = [];

    let status = orderPayload.status;

    if (status === "inProgress") {
      allItems.forEach((item) => {
        let type = "";
        let rate = 0;
        let count = 0;
        let itemId = "";

        let addOnPriceBreakDown = [];
        if (
          Boolean(selectedItem[item.id] && selectedItem[item.id]?.count > 0)
        ) {
          type = item.name;
          rate = GET_ITEM_PRICE(tableOrderDetails.type, activePosType, item);
          count = selectedItem[item.id]?.count;
          itemId = item.id;

          item.addOns?.forEach((addOn) => {
            if (Boolean(selectedItem[item.id][addOn.name])) {
              addOnPriceBreakDown.push({
                count: selectedItem[item.id][addOn.name], // count
                name: addOn.name,
                price: addOn.price,
              });
            }
          });

          let taxIds =
            Array.isArray(item?.taxIds) && item?.taxIds?.length
              ? item?.taxIds
              : fallBackTaxIds;

          priceBreakup.push({
            addOns:
              addOnPriceBreakDown?.length > 0 ? addOnPriceBreakDown : null,
            count: count,
            rate: rate,
            baseRate: rate,
            type: type,
            itemId: itemId,
            taxIds: taxIds,
          });
        }
      });
    }

    let itemTotal = Boolean(status === "held") ? 0 : +itemTotalAmount;

    // invoice
    const invoicePayload = {
      ...invoicePostData,
      id: invoicePostData.id,
      bookingId: tableOrderDetails.bookingId,
      customerId: tableOrderDetails.customerId,
      hotelId: hotelDetails?.id,
      numberOfPeople: tableOrderDetails.numberOfPeople,

      priceBreakup: Array.isArray(invoicePostData?.priceBreakup)
        ? [...invoicePostData?.priceBreakup, ...priceBreakup]
        : priceBreakup,

      orders: invoicePostData?.orders,
      status: invoicePostData?.status,
      posName: invoicePostData?.posName,

      subTotal: invoicePostData.subTotal + +itemTotal,

      tableId: tableOrderDetails.tableId,
      posId: activePosId,

      invoiceType: "pos",
      type: tableOrderDetails.type,
      orderSource: getOrderSource(),
      setTotalAsPerTax: true,
    };

    // after creating order, create invoice
    await dispatch(
      createNewOrder({
        orderPayload: orderPayload,
        invoicePayload: invoicePayload,
        status: "update", // it will define weather invoice will be created or updated
      })
    );

    Boolean(setOrderDetailScreenVisible) && setOrderDetailScreenVisible(false);
    Boolean(setMultipleKOTScreenVsisible) && setMultipleKOTScreenVsisible(true);
  };

  const createBill = (
    isCustomPayload,
    isCustomOrderPayload,
    isCallCreateBill = false
  ) => {
    let updatedInvoicePostData = Boolean(isCallCreateBill)
      ? isCustomPayload
      : invoicePostData;

    let updatedOrderData = isCallCreateBill ? [isCustomOrderPayload] : order;

    const areAllOrdersHeld = updatedOrderData?.every(
      (order) => order.status == "held"
    );

    if (areAllOrdersHeld) {
      message.warning("No billing, all orders are on hold.");
      return;
    }

    if (Object.keys(updatedInvoicePostData)?.length > 0) {
      const taxObjects = Array.isArray(updatedInvoicePostData?.taxObjects)
        ? updatedInvoicePostData?.taxObjects
        : [];

      let totalTax = taxObjects?.reduce(
        (acc, Obj) => acc + (Obj?.taxAmount || 0),
        0
      );

      const total = updatedInvoicePostData?.subTotal + totalTax || 0;

      const payload = {
        ...updatedInvoicePostData,
        status: "billed",
        billed: true,
        totalTax,
        total,
      };

      dispatch(
        updateAllOrdersByStatus({
          runningOrders: order,
          status: "billed",
        })
      );

      dispatch(updateInvoice(payload));
      dispatch(updateExcludeGST(true));
    } else message.info("There is nothing to bill..");
  };

  const getOrderSource = () => {
    let orderSource = "";

    if (tableOrderDetails?.type === "table") orderSource = POS_SERVICE_TYPE[0];
    else if (activePosType == POS_TYPE[1]) orderSource = "banquet";
    else if (activePosType == POS_TYPE[3]) orderSource = "retail";
    else if (activePosType == POS_TYPE[4]) orderSource = "salon";
    else if (activePosType == POS_TYPE[5]) orderSource = "spa";
    else if (activePosType == POS_TYPE[2]) orderSource = POS_SERVICE_TYPE[0];
    else orderSource = tableOrderDetails?.type;

    orderSource = Boolean(orderSource) ? orderSource : "dineIn";

    return orderSource;
  };

  const handleDiscount = async (discountType, discountAmount, setLoading) => {
    setLoading(true);
    let priceBreakup = invoicePostData?.priceBreakup || [];
    let itemTotal = ITEMS_TOTAL_AMOUNT(priceBreakup);

    if (priceBreakup?.length <= 0) {
      message.info("Something went wrong");
      dispatch(updateDisplayDiscountDrawer(false));
      setLoading(false);
      return false;
    }

    let discount = 0;

    if (discountType === "percentage") {
      discount = Boolean(discountAmount)
        ? itemTotal * (+discountAmount / 100)
        : 0;
    } else discount = +discountAmount;

    discount = Boolean(discount) ? discount : 0;

    if (
      discountType === "percentage" &&
      (discountAmount < 0 || discountAmount > 100)
    ) {
      message.error("Discount range should be between 0 and 100");
      setLoading(false);
      return false;
    } else if (+discount > +itemTotal) {
      message.error("Discount should not be greater than the total amount");
      setLoading(false);
      return false;
    }

    let discountPer = discount / itemTotal;

    let newPriceBreakup = priceBreakup?.map((Obj) => {
      return {
        ...Obj,
        discountedRate: parseFloat(Obj?.rate * (1 - discountPer)),
      };
    });

    let newSubTotal = itemTotal - discount;

    const payload = {
      ...invoicePostData,
      priceBreakup: newPriceBreakup,
      subTotal: newSubTotal,
      setTotalAsPerTax: true,

      discount: discount,
      discountInfo: {
        type: discountType,
        value: discountAmount,
      },
    };

    await dispatch(updateInvoice(payload));

    setLoading(false);
    message.success("Discount has been successfully added");
    updateDisplayDiscountDrawer && dispatch(updateDisplayDiscountDrawer(false));
  };

  const handleServiceCharge = async (
    serviceChargeAmount,
    setIsServiceCharge
  ) => {
    let { total } = invoicePostData || {};

    let ServiceCharge = Boolean(serviceChargeAmount) ? serviceChargeAmount : 0;
    let updatedTotal = total + +ServiceCharge;

    const payload = {
      ...invoicePostData,
      hotelId: hotelDetails?.id,
      serviceCharge: +ServiceCharge,
      total: updatedTotal,
    };

    await dispatch(updateInvoice(payload));
    setIsServiceCharge && setIsServiceCharge(false);
    message.success("Service charge has been successfully added");
  };

  const handleTakePayment = async (
    amount,
    hotelId,
    invoice,
    paymentMethod,
    paymentModePartyId,
    transactionId
  ) => {
    setLoading(true);

    if (amount <= 0) {
      message.destroy();
      message.info("Please enter Payment Amount");
      setLoading(false);
      return false;
    }

    const payload = {
      amount: amount,
      bookingId: Boolean(tableOrderDetails.bookingId)
        ? tableOrderDetails.bookingId
        : null,
      hotelId: hotelId,
      invoiceId: invoice.id,
      method: paymentMethod,
      status: "completed",
      transactionNo: transactionId,
      paymentModePartyId,
    };

    let response = await dispatch(takePayment(payload));

    if (response?.payload?.id) {
      await dispatch(
        getInvoiceById({
          hotelId: hotelDetails?.id,
          id: invoice.id,
        })
      );

      message.success(`Bill Payment of ${Math.ceil(amount)} Successfull`);
      dispatch(updateDisplayTakePaymentDrawer(false));
      dispatch(updateDisplayCloseOrderDrawer(false));
    } else {
      message.info("Something went wrong");
    }

    setLoading(false);
  };

  const updateInvoiceWithRoomIdAndBookingId = async (
    roomId,
    hotelId,
    bookingId,
    invoice,
    orders,
    selectedRoom,
    updateDisplayAttachPartyDrawer = false,
    updateAddPartyDropdownVisible = false,
    allPayments
  ) => {
    if (!Boolean(roomId)) {
      message.info("Please select Room");
      return false;
    }
    setLoading(true);

    let btcPayment = allPayments?.find((Obj) => Obj?.method === "btc");

    const payload = {
      ...invoice,
      status: "due",
      hotelId: hotelId,
      bookingId: bookingId,
      roomId: roomId,
      navigate: navigate,
      navigateTo: "dashboard/pos/table-view",
    };

    delete payload["partyId"];

    if (btcPayment) {
      const paymentPayload = {
        hotelId: hotelDetails?.id,
        id: btcPayment?.id,
      };

      await dispatch(deletePayment(paymentPayload));
    }

    dispatch(
      updateAllOrdersByStatus({
        runningOrders: orders,
        status: "completed",
      })
    );

    const response = await dispatch(updateInvoice(payload));
    if (response?.payload?.id) {
      message.success("Bill Attached to  Room " + selectedRoom);
      updateDisplayAttachPartyDrawer &&
        dispatch(updateDisplayAttachPartyDrawer(false));
      updateAddPartyDropdownVisible &&
        dispatch(updateAddPartyDropdownVisible(false));
    }

    setLoading(false);
  };

  const updateInvoiceWithPartyId = async (
    partyId,
    allPayments,
    hotelId,
    total,
    invoice,
    orders,
    partyName,
    updateDisplayAttachPartyDrawer = false,
    updateAddPartyDropdownVisible = false
  ) => {
    if (!Boolean(partyId)) {
      message.info("Please Select Party from the list");
      return false;
    }
    setLoading(true);

    let isBtcPaymentExist = allPayments?.find((Obj) => Obj?.method === "btc");
    let paymentResponse;

    if (isBtcPaymentExist) {
      const paymentObj = { ...isBtcPaymentExist, paymentModePartyId: partyId };

      const payload = {
        hotelId: hotelId,
        paymentObj,
        message: "Party updated successfully",
      };

      paymentResponse = await dispatch(updatePayment(payload));
    } else {
      const alreadyPaid = allPayments?.reduce((acc, curr) => {
        acc += Number(curr.amount);
        return acc;
      }, 0);

      const finalAmount = total - alreadyPaid;

      const payload = {
        amount: finalAmount,
        hotelId: hotelId,
        invoiceId: invoice.id,
        status: "completed",
        paymentModePartyId: partyId,
        method: "btc",
      };

      paymentResponse = await dispatch(takePayment(payload));
    }

    if (!paymentResponse?.payload?.id) {
      message.info("Something went wrong");
      setLoading(false);
      return;
    }

    const payload = {
      ...invoice,
      status: "paid",
      paid: invoice?.total,
      hotelId: hotelId,
      partyId: partyId,
      navigate: navigate,
      navigateTo: "dashboard/pos/table-view",
    };

    delete payload["roomId"];

    dispatch(
      updateAllOrdersByStatus({
        runningOrders: orders,
        status: "completed",
      })
    );

    const response = await dispatch(updateInvoice(payload));

    setLoading(false);
    message.success("Bill Attached to  " + partyName + "(Party)");
    dispatch(updateDisplayAttachPartyDrawer(false));
    dispatch(updateAddPartyDropdownVisible(false));
  };

  const deletePartyAndRoomFromPosInvoice = async (
    invoice,
    allPayments,
    setDeletePartyModal
  ) => {
    let btcPayment = allPayments?.find((Obj) => Obj?.method === "btc");

    if (btcPayment) {
      const paymentPayload = {
        hotelId: hotelDetails?.id,
        id: btcPayment?.id,
      };

      await dispatch(deletePayment(paymentPayload));
    }

    const invoiceResponse = await dispatch(
      getInvoiceById({
        hotelId: hotelDetails?.id,
        id: invoice?.id,
      })
    );

    if (invoiceResponse?.payload?.id) {
      const payload = {
        ...invoiceResponse?.payload,
        status: "billed",
      };

      delete payload["partyId"];
      delete payload["bookingId"];
      delete payload["roomId"];
      delete payload["customerId"];

      const response = await dispatch(updateInvoice(payload));
    }

    message.destroy();
    message.success("Invoice updated");

    setDeletePartyModal && setDeletePartyModal(false);
  };

  const handleUndoBilled = () => {
    if (Object.keys(invoicePostData)?.length > 0) {
      const payload = {
        ...invoicePostData,
        status: "inProgress",
      };

      dispatch(
        updateAllOrdersByStatus({
          runningOrders: order,
          status: "inProgress",
        })
      );

      dispatch(updateInvoice(payload));
    } else {
      message.info("Something went wrong");
    }
  };

  return {
    createBill,
    handleKOT,
    handleDiscount,
    handleServiceCharge,
    handleTakePayment,
    handleKOTEdit,
    updateInvoiceWithRoomIdAndBookingId,
    updateInvoiceWithPartyId,
    deletePartyAndRoomFromPosInvoice,
    handleUndoBilled,
  };
};

export default useAllPosFunctions;
