import React, { useEffect ,lazy, Suspense  } from "react";
import {
  updateDates,
  updateDisplayCustomCalendar,
  updateSelectedCheckInDate,
  updateSelectedCheckOutDate,
  updateDisplayFilter,
  updateSetFilterTitle,
} from "../../reducer/bookingReservationReducer";

import { useDispatch, useSelector } from "react-redux";
import useStoreBookingDetailsLogic from "../../hooks/bookingCustomHook/useStoreBookingDetails";
import moment from "moment";
const BookingReservationContainer = lazy(() =>
  import("../../components/BookingReservationV2/BookingReservationContainer")
);
const MobileBookingReservation = lazy(() =>
  import(
    "../../mobileComponents/mobileBookingReservation/MobileBookingReservation"
  )
);

const BookingReservationPage = () => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useSelector((store) => store.appHeader);


  useEffect(() => {
    return () => {
      dispatch(
        updateDates({
          from: moment().startOf("day"),
        })
      );
      dispatch(
        updateDates({
          to: moment().add(6, "day"),
        })
      );
      dispatch(updateSelectedCheckInDate(""));
      dispatch(updateSelectedCheckOutDate(""));

      dispatch(updateDisplayCustomCalendar(false));

      dispatch(updateDisplayFilter(false));
      dispatch(updateSetFilterTitle("Week"));
    };
  }, []);

  useStoreBookingDetailsLogic();

  return (
    <>
      <Suspense>
        {isMobileScreen ? (
          <MobileBookingReservation />
        ) : (
          <BookingReservationContainer />
        )}
      </Suspense>
    </>
  );
};

export default BookingReservationPage;



// import BookingReservationContainer from "../../components/BookingReservationV2/BookingReservationContainer";
// import React, { useEffect ,lazy, Suspense  } from "react";
// import { useDispatch, useSelector } from "react-redux";

// import {
//   updateDates,
//   updateDisplayCustomCalendar,
//   updateSelectedCheckInDate,
//   updateSelectedCheckOutDate,
//   updateDisplayFilter,
//   updateSetFilterTitle,
// } from "../../reducer/bookingReservationReducer";


// import useStoreBookingDetailsLogic from "../../hooks/bookingCustomHook/useStoreBookingDetails";
// import moment from "moment";

// const BookingReservationContainer = lazy(() =>
//   import("../../components/BookingReservationV2/BookingReservationContainer")
// );
// const MobileBookingReservation = lazy(() =>
//   import(
//     "../../mobileComponents/mobileBookingReservation/MobileBookingReservation"
//   )
// );

// const BookingReservationPage = () => {
//   const dispatch = useDispatch();
//   const { isMobileScreen } = useSelector((store) => store.appHeader);

//   useEffect(() => {
//     return () => {
//       dispatch(
//         updateDates({
//           from: moment().startOf("day"),
//         })
//       );
//       dispatch(
//         updateDates({
//           to: moment().add(6, "day"),
//         })
//       );
//       dispatch(updateSelectedCheckInDate(""));
//       dispatch(updateSelectedCheckOutDate(""));

//       dispatch(updateDisplayCustomCalendar(false));

//       dispatch(updateDisplayFilter(false));
//       dispatch(updateSetFilterTitle("Week"));
//     };
//   }, []);


  
//   useStoreBookingDetailsLogic();

//   return (
//     <>
//       <Suspense>
//         {isMobileScreen ? (
//           <MobileBookingReservation />
//         ) : (
//           <BookingReservationContainer />
//         )}
//       </Suspense>
//     </>
//   );
// };

// export default BookingReservationPage;
