import { useDispatch } from "react-redux";

import { CLEAR_REDUX_STORE_1 } from "../reducer/manageStocksReducer/categoriesReducer";
import { CLEAR_REDUX_STORE_11 } from "../reducer/bankReducer";
import { CLEAR_REDUX_STORE_12 } from "../reducer/bookingReducer";
import { CLEAR_REDUX_STORE_13 } from "../reducer/bookingReservationReducer";
import { CLEAR_REDUX_STORE_14 } from "../reducer/channelManagerReducer";
import { CLEAR_REDUX_STORE_15 } from "../reducer/channelManagerSettingsReducer";
import { CLEAR_REDUX_STORE_16 } from "../reducer/clientsReducer";
import { CLEAR_REDUX_STORE_17 } from "../reducer/customerReducer";
import { CLEAR_REDUX_STORE_18 } from "../reducer/dashboardReducer";
import { CLEAR_REDUX_STORE_19 } from "../reducer/editClientModelReducer";
import { CLEAR_REDUX_STORE_2 } from "../reducer/manageStocksReducer/recountReducer";
import { CLEAR_REDUX_STORE_20 } from "../reducer/editItemReducer";
import { CLEAR_REDUX_STORE_21 } from "../reducer/expenseReducer";
import { CLEAR_REDUX_STORE_22 } from "../reducer/getAllOrdersReducer";
import { CLEAR_REDUX_STORE_23 } from "../reducer/getItemReducer";
import { CLEAR_REDUX_STORE_24 } from "../reducer/grcReducer";
import { CLEAR_REDUX_STORE_25 } from "../reducer/housekeepingReducer";
import { CLEAR_REDUX_STORE_26 } from "../reducer/ingredientsReducer";
import { CLEAR_REDUX_STORE_27 } from "../reducer/invoiceAndPaymentReducer";
import { CLEAR_REDUX_STORE_28 } from "../reducer/invoiceReducer";
import { CLEAR_REDUX_STORE_29 } from "../reducer/loginReducer";
import { CLEAR_REDUX_STORE_3 } from "../reducer/manageStocksReducer/suppliersReducer";
import { CLEAR_REDUX_STORE_30 } from "../reducer/newBookingDetailsReducer";
import { CLEAR_REDUX_STORE_31 } from "../reducer/orderReducer";
import { CLEAR_REDUX_STORE_32 } from "../reducer/parkedOrderReducer";
import { CLEAR_REDUX_STORE_33 } from "../reducer/partyReducer";
import { CLEAR_REDUX_STORE_34 } from "../reducer/paymentReducer";
import { CLEAR_REDUX_STORE_35 } from "../reducer/ratesAndAvailabilityReducer";
import { CLEAR_REDUX_STORE_36 } from "../reducer/reportReducer";
import { CLEAR_REDUX_STORE_37 } from "../reducer/reservationReducer";
import { CLEAR_REDUX_STORE_38 } from "../reducer/reviewOrderReducer";
import { CLEAR_REDUX_STORE_39 } from "../reducer/roomsReducer";
import { CLEAR_REDUX_STORE_4 } from "../reducer/manageStocksReducer/unitsReducer";
import { CLEAR_REDUX_STORE_40 } from "../reducer/settingsReducer";
import { CLEAR_REDUX_STORE_41 } from "../reducer/singleSelectedOrderReducer";
import { CLEAR_REDUX_STORE_42 } from "../reducer/staffReducer";
import { CLEAR_REDUX_STORE_43 } from "../reducer/uploadReducer";
import { CLEAR_REDUX_STORE_44 } from "../reducer/userItemsReducer";
import { CLEAR_REDUX_STORE_45 } from "../reducer/usersRole";
import { CLEAR_REDUX_STORE_46 } from "../reducer/walkinReducer";
import { CLEAR_REDUX_STORE_5 } from "../reducer/posReducer/restaurant";
import { CLEAR_REDUX_STORE_6 } from "../reducer/posReducer/posSettingReducer";
import { CLEAR_REDUX_STORE_7 } from "../reducer/addItemReducer";
import { CLEAR_REDUX_STORE_8 } from "../reducer/alertReducer";
import { CLEAR_REDUX_STORE_9 } from "../reducer/appHeaderReducer";
// import { CLEAR_REDUX_STORE_10 } from "../../reducer/appReducer";

const useResetState = () => {
  const dispatch = useDispatch();

  const resetState = () => {
    dispatch(CLEAR_REDUX_STORE_1());
    dispatch(CLEAR_REDUX_STORE_2());
    dispatch(CLEAR_REDUX_STORE_3());
    dispatch(CLEAR_REDUX_STORE_4());
    dispatch(CLEAR_REDUX_STORE_5());
    dispatch(CLEAR_REDUX_STORE_6());
    dispatch(CLEAR_REDUX_STORE_7());
    dispatch(CLEAR_REDUX_STORE_8());
    dispatch(CLEAR_REDUX_STORE_9());
    // dispatch(CLEAR_REDUX_STORE_10());
    dispatch(CLEAR_REDUX_STORE_11());
    dispatch(CLEAR_REDUX_STORE_12());
    dispatch(CLEAR_REDUX_STORE_13());
    dispatch(CLEAR_REDUX_STORE_14());
    dispatch(CLEAR_REDUX_STORE_15());
    dispatch(CLEAR_REDUX_STORE_16());
    dispatch(CLEAR_REDUX_STORE_17());
    dispatch(CLEAR_REDUX_STORE_18());
    dispatch(CLEAR_REDUX_STORE_19());
    dispatch(CLEAR_REDUX_STORE_20());
    dispatch(CLEAR_REDUX_STORE_21());
    dispatch(CLEAR_REDUX_STORE_22());
    dispatch(CLEAR_REDUX_STORE_23());
    dispatch(CLEAR_REDUX_STORE_24());
    dispatch(CLEAR_REDUX_STORE_25());
    dispatch(CLEAR_REDUX_STORE_26());
    dispatch(CLEAR_REDUX_STORE_27());
    dispatch(CLEAR_REDUX_STORE_28());
    dispatch(CLEAR_REDUX_STORE_29());
    dispatch(CLEAR_REDUX_STORE_30());
    dispatch(CLEAR_REDUX_STORE_31());
    dispatch(CLEAR_REDUX_STORE_32());
    dispatch(CLEAR_REDUX_STORE_33());
    dispatch(CLEAR_REDUX_STORE_34());
    dispatch(CLEAR_REDUX_STORE_35());
    dispatch(CLEAR_REDUX_STORE_36());
    dispatch(CLEAR_REDUX_STORE_37());
    dispatch(CLEAR_REDUX_STORE_38());
    dispatch(CLEAR_REDUX_STORE_39());
    dispatch(CLEAR_REDUX_STORE_40());
    dispatch(CLEAR_REDUX_STORE_41());
    dispatch(CLEAR_REDUX_STORE_42());
    dispatch(CLEAR_REDUX_STORE_43());
    dispatch(CLEAR_REDUX_STORE_44());
    dispatch(CLEAR_REDUX_STORE_45());
    dispatch(CLEAR_REDUX_STORE_46());
  };

  return { resetState };
};

export default useResetState;
