import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBookingDetailsData,
  updateCustomerDetails,
  updatePaymentDetails,
  updateInvoiceDetails,
  updateBookingDetailsEditable,
} from "../../reducer/bookingReducer";
import { bookingDetailsById } from "../../services/booking";

const useStoreBookingDetailsLogic = () => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const { showMainBookingDetailsDrawer, mobileBookingDetailsId } = useSelector(
    (store) => store.booking
  );

  const bookingId = Boolean(showMainBookingDetailsDrawer?.bookingId)
    ? showMainBookingDetailsDrawer?.bookingId
    : mobileBookingDetailsId;

  // Api Hitting for Booking details for both screen
  useEffect(() => {
    if (Boolean(hotelDetails?.id) && Boolean(bookingId)) performApi();
  }, [bookingId, mobileBookingDetailsId]);

  const performApi = async () => {
    const bookingDetailsPayload = {
      id: bookingId,
      hotelId: hotelDetails.id,
    };

    dispatch(updateInvoiceDetails([]));
    dispatch(updateBookingDetailsEditable({}));
    dispatch(updateCustomerDetails({}));
    dispatch(updatePaymentDetails());

    dispatch(
      updateBookingDetailsData({
        loadingDetails: true,
        responseDetails: {},
        responseDetailsApiError: false,
      })
    );

    const response = await bookingDetailsById(
      bookingDetailsPayload,
      "bookings"
    );

    if (Boolean(response.data)) {
      // api success phase
      dispatch(
        updateBookingDetailsData({
          loadingDetails: false,
          responseDetails: response.data,
          responseDetailsApiError: false,
        })
      );
      let bookingDetails = Boolean(response.data)
        ? response?.data?.bookingDetails
        : {};
      dispatch(updateBookingDetailsEditable(bookingDetails));

      let customerDetails = Boolean(response.data)
        ? response?.data?.customerDetails
        : {};
      dispatch(updateCustomerDetails(customerDetails));

      let invoiceDetails = Boolean(response.data)
        ? response?.data?.invoiceDetails
        : [];
      dispatch(updateInvoiceDetails(invoiceDetails));

      let paymentDetails = Boolean(response.data)
        ? response?.data?.paymentDetails
        : [];
      dispatch(updatePaymentDetails(paymentDetails));
    } else {
      dispatch(
        updateBookingDetailsData({
          loadingDetails: false,
          responseDetails: {},
          responseDetailsApiError: true,
        })
      );
    }
  };
};

export default useStoreBookingDetailsLogic;
