import { useEffect, Suspense, lazy } from "react";
import { useDispatch } from "react-redux";
import { CLEAR_REDUX_STORE_36 } from "../../reducer/reportReducer";
import Loading from "../../pages/loading";
const ReportHeader = lazy(() =>
  import("../../components/report/reportHeader/ReportHeader")
);

const MainReportsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(CLEAR_REDUX_STORE_36());
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ReportHeader />
    </Suspense>
  );
};

export default MainReportsPage;
