import SideBarImage from "./SideBarImage";
import NavigationBarList from "./NavigationBarList";
import { useEffect, useState } from "react";
import { BiSolidLeftArrow } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { updateMenuDrawer } from "../../reducer/appReducer";
import menu from "../../assetsV2/SideBarImage/menu.svg";

const SidebarGeneric = ({ dashboardSelectHandler }) => {
  const categories = useSelector((state) => state.categories.data);
  const { menuDrawer } = useSelector((store) => store.app);

  const dispatch = useDispatch();

  let Categories = categories?.length > 0 ? ["All Menu", ...categories] : [];

  const cats = Categories.map((category, index) => ({
    id: index,
    title: category,
    image: category,
    type: "category",
  }));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    if (windowWidth >= 1200) updateMenuDrawer(false);
    else updateMenuDrawer(true);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const renderDashBoard = () => {
    return (
      <div
        className={`onboarding-sidebar-dashboard bg-red-200 ${
          menuDrawer ? "w-[5rem]" : "w-[13rem]"
        } fixed top-0 bottom-0 overflow-hidden hover:overflow-y-auto z-[300]`}
      >
        <div className="flex">
          {!menuDrawer && <SideBarImage />}

          {menuDrawer ? (
            <img
              src={menu}
              alt=""
              onClick={() => dispatch(updateMenuDrawer(!menuDrawer))}
              className="text-white mt-[4.6rem] ml-[30px]  cursor-pointer text-1xl"
            />
          ) : (
            <BiSolidLeftArrow
              onClick={() => dispatch(updateMenuDrawer(!menuDrawer))}
              className="text-white mt-[3.8rem] md:mt-[4.3rem] lg:mt-[4.6rem] absolute right-[10px] top-[1px] cursor-pointer text-xs"
            />
          )}
        </div>
        <NavigationBarList
          categories={cats}
          dashboardSelectHandler={dashboardSelectHandler}
        />
      </div>
    );
  };

  return renderDashBoard();
};

export default SidebarGeneric;
