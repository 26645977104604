import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getCurrentUser,
  loggedInUserPermissionsService,
  loggedInUserHotelWisePermissionsService,
} from "../services/usersRole";
import { updateFirstLoading } from "./appReducer";

export const getLoggedInUserPermission = createAsyncThunk(
  "getLoggedInUserPermission",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await loggedInUserPermissionsService(payload);
      dispatch(updateFirstLoading());
      return fulfillWithValue(response.data);
    } catch (error) {
      dispatch(updateFirstLoading());
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getCurrentUserPosArray = createAsyncThunk(
  "getCurrentUserPosArray",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getCurrentUser(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

export const getHotelWiseUserPermissions = createAsyncThunk(
  "getHotelWiseUserPermissions",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await loggedInUserHotelWisePermissionsService(payload);
      dispatch(updateFirstLoading());
      return fulfillWithValue(response.data);
    } catch (error) {
      dispatch(updateFirstLoading());
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        message.error("Token expired..");
      } else {
        message.error(error.message);
      }
      throw rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false, // before api call
  apiError: false, // check api status

  loggedInUserPermissions: [],

  isOwner: false,

  viewBookingAllowed: false,
  createBookingAllowed: false,
  editBookingAllowed: false,
  deleteBookingAllowed: false,

  manageCompletedBookingAllowed: false,

  viewRommAllowed: false,
  editRoomAllowed: false,

  viewClientAllowed: false,
  createClientAllowed: false,
  editClientAllowed: false,

  viewHotelDetails: false,
  editHotelDetails: false,

  viewUserAllowed: false,
  editUserAllowed: false,

  createPOSAllowed: false,
  editPOSAllowed: false,

  editKdsAllowed: false,

  editHouseKeepingAllowed: false,

  viewRatedAndAvailabiltyAllowed: false,
  editRateAndAvailabiltyAllowed: false,

  viewPaymentAllowed: false,
  createPaymentAllowed: false,
  editPaymentAllowed: false,
  deletePaymentAllowed: false,

  viewExpenseAllowed: false,
  createExpenseAllowed: false,
  editExpenseAllowed: false,
  deleteExpenseAllowed: false,

  viewLedgerAllowed: false,
  createLedgerAllowed: false,
  editLedgerAllowed: false,

  viewStocksAllowed: false,
  editStocksAllowed: false,

  viewInvoiceAllowed: false,

  editTaxAllowed: false,

  currentUserPosIds: [],

  hotelWiseUserPermissions: {},
};

export const userRestrictionSlice = createSlice({
  name: "userRestriction",
  initialState,
  reducers: {
    updateIsOwner: (state, action) => {
      if (action.payload === true) {
        state.isOwner = action.payload;

        // Booking permissions
        state.viewBookingAllowed = true;
        state.createBookingAllowed = true;
        state.editBookingAllowed = true;
        state.deleteBookingAllowed = true;

        state.manageCompletedBookingAllowed = true;

        // Room permissions
        state.viewRommAllowed = true;
        state.editRoomAllowed = true;

        // Client permissions
        state.viewClientAllowed = true;
        state.createClientAllowed = true;
        state.editClientAllowed = true;

        // Hotel details permissions
        state.viewHotelDetails = true;
        state.editHotelDetails = true;

        // User permissions
        state.viewUserAllowed = true;
        state.editUserAllowed = true;

        // POS permissions
        state.createPOSAllowed = true;
        state.editPOSAllowed = true;

        // KDS permissions
        state.editKdsAllowed = true;

        // Housekeeping permissions
        state.editHouseKeepingAllowed = true;

        // Rate and Availability permissions
        state.viewRatedAndAvailabiltyAllowed = true;
        state.editRateAndAvailabiltyAllowed = true;

        // Payment permissions
        state.viewPaymentAllowed = true;
        state.createPaymentAllowed = true;
        state.editPaymentAllowed = true;
        state.deletePaymentAllowed = true;

        // Expense permissions
        state.viewExpenseAllowed = true;
        state.createExpenseAllowed = true;
        state.editExpenseAllowed = true;
        state.deleteExpenseAllowed = true;

        // Ledger permissions
        state.viewLedgerAllowed = true;
        state.createLedgerAllowed = true;
        state.editLedgerAllowed = true;

        // Stocks permissions
        state.viewStocksAllowed = true;
        state.editStocksAllowed = true;

        // Invoice permissions
        state.viewInvoiceAllowed = true;

        // Tax permissions
        state.editTaxAllowed = true;
      } else {
        state.isOwner = false;
      }
    },

    updateViewBookingAllowed: (state, action) => {
      state.viewBookingAllowed = action.payload;
    },
    updateCreateBookingAllowed: (state, action) => {
      state.createBookingAllowed = action.payload;
    },
    updateEditBookingAllowed: (state, action) => {
      state.editBookingAllowed = action.payload;
    },
    updateDeleteBookingAllowed: (state, action) => {
      state.deleteBookingAllowed = action.payload;
    },
    updateDeleteCompletedBookingAllowed: (state, action) => {
      state.manageCompletedBookingAllowed = action.payload;
    },

    updateViewClientAllowed: (state, action) => {
      state.viewClientAllowed = action.payload;
    },
    updateCreateClientAllowed: (state, action) => {
      state.createClientAllowed = action.payload;
    },
    updateEditClientAllowed: (state, action) => {
      state.editClientAllowed = action.payload;
    },
    updateViewHotelDetails: (state, action) => {
      state.viewHotelDetails = action.payload;
    },
    updateEditHotelDetails: (state, action) => {
      state.editHotelDetails = action.payload;
    },
    updateEditRoomAllowed: (state, action) => {
      state.editRoomAllowed = action.payload;
    },
    updateViewRoomAllowed: (state, action) => {
      state.viewRommAllowed = action.payload;
    },
    updateViewUserAllowed: (state, action) => {
      state.viewUserAllowed = action.payload;
    },
    updateEditUserAllowed: (state, action) => {
      state.editUserAllowed = action.payload;
    },
    updateCreatePosAllowed: (state, action) => {
      state.createPOSAllowed = action.payload;
    },
    updateEditPosAllowed: (state, action) => {
      state.editPOSAllowed = action.payload;
    },
    updateEditKdsAllowed: (state, action) => {
      state.editKdsAllowed = action.payload;
    },

    updateViewRateAndAvailabilty: (state, action) => {
      state.viewRatedAndAvailabiltyAllowed = action?.payload;
    },
    updateEditRateAndAvailabilty: (state, action) => {
      state.editRateAndAvailabiltyAllowed = action?.payload;
    },
    updateEditHouseKeepingAllowed: (state, action) => {
      state.editHouseKeepingAllowed = action?.payload;
    },
    // Payment permissions
    updateViewPaymentAllowed: (state, action) => {
      state.viewPaymentAllowed = action.payload;
    },
    updateCreatePaymentAllowed: (state, action) => {
      state.createPaymentAllowed = action.payload;
    },
    updateEditPaymentAllowed: (state, action) => {
      state.editPaymentAllowed = action.payload;
    },
    updateDeletePaymentAllowed: (state, action) => {
      state.deletePaymentAllowed = action.payload;
    },

    // Expense permissions
    updateViewExpenseAllowed: (state, action) => {
      state.viewExpenseAllowed = action.payload;
    },
    updateCreateExpenseAllowed: (state, action) => {
      state.createExpenseAllowed = action.payload;
    },
    updateEditExpenseAllowed: (state, action) => {
      state.editExpenseAllowed = action.payload;
    },
    updateDeleteExpenseAllowed: (state, action) => {
      state.deleteExpenseAllowed = action.payload;
    },

    // Ledger permissions
    updateViewLedgerAllowed: (state, action) => {
      state.viewLedgerAllowed = action.payload;
    },
    updateCreateLedgerAllowed: (state, action) => {
      state.createLedgerAllowed = action.payload;
    },
    updateEditLedgerAllowed: (state, action) => {
      state.editLedgerAllowed = action.payload;
    },

    // Stocks permissions
    updateViewStocksAllowed: (state, action) => {
      state.viewStocksAllowed = action.payload;
    },
    updateEditStocksAllowed: (state, action) => {
      state.editStocksAllowed = action.payload;
    },

    // Invoice permissions
    updateViewInvoiceAllowed: (state, action) => {
      state.viewInvoiceAllowed = action.payload;
    },

    // Tax permissions
    updateEditTaxAllowed: (state, action) => {
      state.editTaxAllowed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedInUserPermission.pending, (state, action) => {
      state.apiError = false;
    });
    builder.addCase(getLoggedInUserPermission.fulfilled, (state, action) => {
      state.loggedInUserPermissions = action.payload;
      state.apiError = false;
    });
    builder.addCase(getLoggedInUserPermission.rejected, (state, action) => {
      state.loggedInUserPermissions = [];
      state.apiError = true;
    });
    builder.addCase(getCurrentUserPosArray.pending, (state, action) => {
      state.apiError = false;
    });
    builder.addCase(getCurrentUserPosArray.fulfilled, (state, action) => {
      state.currentUserPosIds = action?.payload[0]?.posIds;
      state.apiError = false;
    });
    builder.addCase(getCurrentUserPosArray.rejected, (state, action) => {
      state.currentUserPosIds = [];
      state.apiError = true;
    });

    builder.addCase(getHotelWiseUserPermissions.pending, (state, action) => {
      state.apiError = false;
    });
    builder.addCase(getHotelWiseUserPermissions.fulfilled, (state, action) => {
      state.apiError = false;
      state.hotelWiseUserPermissions = action.payload.reduce((acc, curr) => {
        const { hotelId, permissions } = curr;
        acc[hotelId[0]] = permissions;
        return acc;
      }, {});
    });
    builder.addCase(getHotelWiseUserPermissions.rejected, (state, action) => {
      state.apiError = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateIsOwner,

  updateViewBookingAllowed,
  updateCreateBookingAllowed,
  updateEditBookingAllowed,
  updateDeleteBookingAllowed,
  updateDeleteCompletedBookingAllowed,

  updateViewClientAllowed,
  updateCreateClientAllowed,
  updateEditClientAllowed,

  updateViewHotelDetails,
  updateEditHotelDetails,

  updateEditRoomAllowed,
  updateViewRoomAllowed,

  updateViewUserAllowed,
  updateEditUserAllowed,

  updateCreatePosAllowed,
  updateEditPosAllowed,

  updateEditKdsAllowed,

  updateViewRateAndAvailabilty,
  updateEditRateAndAvailabilty,

  updateEditHouseKeepingAllowed,

  updateViewPaymentAllowed,
  updateCreatePaymentAllowed,
  updateEditPaymentAllowed,
  updateDeletePaymentAllowed,

  updateViewExpenseAllowed,
  updateCreateExpenseAllowed,
  updateEditExpenseAllowed,
  updateDeleteExpenseAllowed,

  updateViewLedgerAllowed,
  updateCreateLedgerAllowed,
  updateEditLedgerAllowed,

  updateViewStocksAllowed,
  updateEditStocksAllowed,

  updateViewInvoiceAllowed,

  updateEditTaxAllowed,
} = userRestrictionSlice.actions;

export default userRestrictionSlice.reducer;
