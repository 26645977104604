import { authedAxios } from "../utils/customHttpHeader";

const bookingList = async (payload, endpoint) => {
  let url = `${endpoint}/`;

  if (payload.hotelIds) {
    url += `?hotelIds=${payload.hotelIds}`;
  } else {
    url += `?hotelId=${payload.hotelId}`;
  }

  if (Boolean(payload.roomType)) url += `&roomTypes=${payload.roomType}`;

  if (payload.startDate) url += `&startDate=${payload.startDate}`;
  if (payload.endDate) url += `&endDate=${payload.endDate}`;

  if (payload.statuses) url += `&statuses=${payload.statuses}`;
  if (Boolean(payload.sources)) url += `&sources=${payload.sources}`;

  if (Boolean(payload.pageNumber)) url += `&pageNumber=${payload.pageNumber}`;
  else url += `&pageNumber=${0}`;

  if (Boolean(payload.pageSize)) url += `&pageSize=${payload.pageSize}`;

  if (payload.searchText) url += `&searchText=${payload.searchText}`;

  if (payload.sortingDirection)
    url += `&sortingDirection=${payload.sortingDirection}`;
  if (payload.sortingField) url += `&sortingField=${payload.sortingField}`;

  if (payload?.createdAtStartDate)
    url += `&createdAtStartDate=${payload.createdAtStartDate}`;
  if (payload?.createdAtEndDate)
    url += `&createdAtEndDate=${payload.createdAtEndDate}`;

  const response = await authedAxios().get(url);
  return response;
};

const bookingDetailsById = async (payload, endpoint) => {
  const id = payload.id;
  const hotelId = payload.hotelId;

  const response = await authedAxios().get(
    `${endpoint}/${id}?hotelId=${hotelId}`
  );
  return response;
};

const modifyBooking = async (payload, endpoint) => {
  const response = await authedAxios().put(endpoint, payload);
  return response;
};

const updateBookingStatus = async (payload, endpoint) => {
  // change status from "confrimed booking" to "checkedIn booking"

  try {
    const response = await authedAxios().put(endpoint, payload);
    return response.data;
  } catch (error) {
    return null;
  }
};

const deleteBooking = async (payload) => {
  const response = await authedAxios().delete(
    `bookings?hotelId=${payload?.hotelId}&bookingIds=${payload?.id}`
  );
  return response;
};

export {
  bookingList,
  bookingDetailsById,
  modifyBooking,
  updateBookingStatus,
  deleteBooking,
};
