import React from "react";
import { Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { BsArrowReturnRight } from "react-icons/bs";

import { updateSelectedOrderItemCountMap } from "../../../reducer/posReducer/restaurant";
import { TranslateText } from "../../../utils/translation";

function ItemCard({ item, handleIsAddOnVisible, isAddOnVisible, status }) {
  const { selectedOrderItemCountMap } = useSelector((store) => store.pos);
  const dispatch = useDispatch();

  const inc = (id) => {
    dispatch(
      updateSelectedOrderItemCountMap({
        id: id,
        itemCountType: "increment",
      })
    );
  };

  const dnc = (id) => {
    dispatch(
      updateSelectedOrderItemCountMap({
        id: id,
        itemCountType: "decrement",
      })
    );
  };

  const addonInc = (id, addOnName) => {
    dispatch(
      updateSelectedOrderItemCountMap({
        id: id,
        addonCountType: "increment",
        addOnItem: addOnName,
      })
    );
  };

  const addonDnc = (id, addOnName) => {
    dispatch(
      updateSelectedOrderItemCountMap({
        id: id,

        addonCountType: "decrement",
        addOnItem: addOnName,
      })
    );
  };

  return (
    <div className="bg-[#ffffff] rounded-lg  mt-1 font-outfit">
      <div className="mb-2">
        <div className="">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center w-[70%]">
              <div className="text-[16px] text-[#000000B2]">{item.name}</div>
              <div className="text-[16px] text-[#00000080] ">{"X "}</div>
              {item.quantity}{" "}
            </div>

            <div className="flex gap-4 mt-2">
              <Button
                className="flex justify-center items-center"
                size="small"
                shape=""
                icon={<MinusOutlined />}
                onClick={() => dnc(item.id)}
                style={{ borderRadius: "8px" }}
              />
              {item.quantity}

              <Button
                className="flex justify-center items-center"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => inc(item.id)}
                style={{ borderRadius: "8px" }}
              />
            </div>
          </div>

          {Boolean(selectedOrderItemCountMap[item?.id]?.addOns) && (
            <>
              {item?.addOns?.map((addonItem) => {
                return (
                  <>
                    {Boolean(
                      selectedOrderItemCountMap[item?.id]["addOns"][
                        addonItem?.name
                      ]
                    ) && (
                      <div className="flex justify-between mt-2">
                        <div className="flex">
                          <span className="mt-1">
                            <BsArrowReturnRight />
                          </span>
                          <span className="text-[14px] text-[#000000B2] ml-2 mr-2 ">
                            {addonItem.name}
                          </span>
                          <span className="text-[14px] text-[#00000080] ">
                            {" X "}
                            {
                              selectedOrderItemCountMap[item?.id]["addOns"][
                                addonItem?.name
                              ]
                            }
                          </span>
                        </div>

                        <div className="flex flex-col gap-1">
                          <div className="flex gap-2">
                            <Button
                              className="flex justify-center items-center text-sm"
                              size="small"
                              shape=""
                              icon={<MinusOutlined />}
                              onClick={() => addonDnc(item.id, addonItem.name)}
                              style={{ borderRadius: "8px" }}
                            />
                            {
                              selectedOrderItemCountMap?.[item.id]?.[
                                addonItem.name
                              ]
                            }
                            <Button
                              className="flex justify-center items-center text-xs"
                              size="small"
                              icon={<PlusOutlined />}
                              onClick={() => addonInc(item.id, addonItem.name)}
                              style={{ borderRadius: "8px" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          )}

          {Boolean(selectedOrderItemCountMap[item?.id]?.instruction) && (
            <div className="flex justify-between mt-1">
              <div className="flex">
                <span className="mt-1">
                  <BsArrowReturnRight />
                </span>
                <span className="text-[14px] text-[#000000B2] ml-2 mr-2 "></span>
                <p className="text-[14px] text-[#00000080] ">
                  {" "}
                  {selectedOrderItemCountMap[item?.id]?.instruction}{" "}
                  <span className="text-xs">
                    <TranslateText textKey={"note"} namespace="pos" />
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>

        <></>
      </div>
      <hr />
    </div>
  );
}

export default ItemCard;
