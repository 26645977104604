import { authedAxios } from "../utils/customHttpHeader";

const getSaleReports = async (payload) => {
  const queryString = `report/sales?hotelIds=${payload.hotelIds}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
  const response = await authedAxios().get(queryString);
  return response;
};

const nightAuditReportService = async (payload) => {
  const queryString = `report/nightAudit?hotelIds=${payload.hotelIds}&date=${payload.startDate}`;
  const response = await authedAxios().get(queryString);
  return response;
};

const getBookingListReportsService = async (payload) => {
  let path = `report/bookingList?hotelIds=${payload.hotelIds}&statuses=${payload.statuses}`;

  if (payload?.startDate) {
    path += `&startDate=${payload.startDate}`;
  }

  if (payload?.endDate) {
    path += `&endDate=${payload.endDate}`;
  }

  if (payload?.startDate) {
    path += `&startDate=${payload.startDate}`;
  }

  if (payload?.earlyCheckIn) {
    path += `&earlyCheckIn=${payload.earlyCheckIn}`;
  }
  if (payload?.lateCheckout) {
    path += `&lateCheckout=${payload.lateCheckout}`;
  }

  const response = await authedAxios().get(path);
  return response;
};

const getRoomChangeReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;

  const response = await authedAxios().get(url);
  return response;
};

const getOccupancyReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;

  const response = await authedAxios().get(url);
  return response;
};

const getSalesByCustomerReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelId=${payload?.hotelId}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;

  const response = await authedAxios().get(url);
  return response;
};

const getBookingSourceReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;

  const response = await authedAxios().get(url);
  return response;
};

const getTaxDetailsReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;
  if (payload?.invoiceType) url += `&invoiceType=${payload?.invoiceType}`;
  if (payload?.posId) url += `&posId=${payload?.posId}`;
  if (typeof payload?.taxIncludedInPrice === "boolean")
    url += `&taxIncludedInPrice=${payload?.taxIncludedInPrice}`;
  if (payload?.partyIds) url += `&partyIds=${payload?.partyIds}`;

  const response = await authedAxios().get(url);
  return response;
};

const getPayableReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  const response = await authedAxios().get(url);
  return response;
};

const getReceivableReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  const response = await authedAxios().get(url);
  return response;
};

const getPaymentReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelIds=${payload?.hotelIds}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;
  if (payload?.paymentMethod) url += `&paymentMethod=${payload?.paymentMethod}`;
  if (payload?.invoiceType) url += `&invoiceType=${payload?.invoiceType}`;
  if (payload?.posId) url += `&posId=${payload?.posId}`;

  const response = await authedAxios().get(url);
  return response;
};

const getVendorSummaryReportService = async (endpoint, payload) => {
  let url = endpoint;
  url += `?hotelId=${payload?.hotelId}`;

  if (payload?.startDate) url += `&startDate=${payload?.startDate}`;
  if (payload?.endDate) url += `&endDate=${payload?.endDate}`;
  if (payload?.expenseAccount)
    url += `&expenseAccount=${payload?.expenseAccount}`;
  if (payload?.partyIds) url += `&partyIds=${payload?.partyIds}`;

  const response = await authedAxios().get(url);
  return response;
};

export {
  getSaleReports,
  nightAuditReportService,
  getBookingListReportsService,
  getRoomChangeReportService,
  getOccupancyReportService,
  getSalesByCustomerReportService,
  getBookingSourceReportService,
  getTaxDetailsReportService,
  getPayableReportService,
  getReceivableReportService,
  getPaymentReportService,
  getVendorSummaryReportService,
};
