import { authedAxios } from "../utils/customHttpHeader";

export const getCustomerService = async (payload) => {
  const response = await authedAxios().get(`customers/search/${payload}`, {
    params: { hotelId: localStorage.getItem("hotelid") },
  });
  return response;
};

const customerDetailsById = async (payload) => {
  let queryString = `?hotelId=${payload.hotelId}`;

  if (payload?.invoiceId) {
    queryString += `&invoiceId=${payload?.invoiceId?.toString()}`;
  }

  const response = await authedAxios().get(
    "customers/" + payload.id + queryString
  );
  return response;
};

const customerByInvoiceId = async (payload) => {
  let queryString = `customers/invoiceCustomerDetails?invoiceIds=${payload}`;
  const response = await authedAxios().get(queryString);
  return response;
};

const putCustomer = async (payload, endpoint) => {
  let queryString = `?hotelId=${payload.hotelId}`;

  if (payload?.invoiceIds) {
    queryString += `&invoiceIds=${payload?.invoiceIds?.toString()}`;
  }

  const newPayload = { ...payload };
  delete newPayload["invoiceIds"];

  try {
    const response = await authedAxios().put(
      "customers/" + payload.id + queryString,
      payload
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const postCustomer = async (payload) => {
  try {
    const response = await authedAxios().post("/customers", payload);
    return response.data;
  } catch (error) {
    return null;
  }
};

const customerDetailsByPhone = async (payload) => {
  let countryCode = Boolean(payload?.countryCode)
    ? payload?.countryCode
    : "+91";
  const path = `customers/phone/${payload.phone}?hotelId=${payload.hotelId}&countryCode=${countryCode}`;
  const response = await authedAxios().get(path);
  return response;
};

const getAllCustomer = async (payload) => {
  let path = `customers?hotelId=${payload.hotelId}`;

  if (payload?.pageSize) path += `&pageSize=${payload.pageSize}`;

  if (payload?.pageNumber) path += `&pageNumber=${payload.pageNumber}`;

  if (payload?.customerIds) path += `&customerIds=${payload.customerIds}`;

  if (payload?.sortingDirection)
    path += `&sortingDirection=${payload.sortingDirection}`;

  if (payload?.sortingField) path += `&sortingField=${payload.sortingField}`;

  if (payload?.searchText) path += `&searchText=${payload.searchText}`;

  const response = await authedAxios().get(path);
  return response;
};

const getAllCustSearch = async (payload) => {
  let path = `customers/search/${payload?.searchText}?hotelId=${payload.hotelId}`;

  const response = await authedAxios().get(path);
  return response;
};

export {
  customerByInvoiceId,
  customerDetailsById,
  putCustomer,
  customerDetailsByPhone,
  postCustomer,
  getAllCustomer,
  getAllCustSearch,
};
