import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserPosArray,
  getLoggedInUserPermission,
  updateCreateBookingAllowed,
  updateCreateClientAllowed,
  updateCreatePosAllowed,
  updateCreatePaymentAllowed,
  updateDeleteBookingAllowed,
  updateEditBookingAllowed,
  updateEditClientAllowed,
  updateEditHotelDetails,
  updateEditHouseKeepingAllowed,
  updateEditKdsAllowed,
  updateEditPosAllowed,
  updateEditRateAndAvailabilty,
  updateEditRoomAllowed,
  updateEditTaxAllowed,
  updateEditUserAllowed,
  updateEditExpenseAllowed,
  updateEditLedgerAllowed,
  updateEditStocksAllowed,
  updateViewBookingAllowed,
  updateViewClientAllowed,
  updateViewHotelDetails,
  updateViewRateAndAvailabilty,
  updateViewRoomAllowed,
  updateViewUserAllowed,
  updateViewPaymentAllowed,
  updateViewExpenseAllowed,
  updateViewLedgerAllowed,
  updateViewStocksAllowed,
  updateViewInvoiceAllowed,
  updateEditPaymentAllowed,
  updateDeletePaymentAllowed,
  updateCreateExpenseAllowed,
  updateCreateLedgerAllowed,
  updateIsOwner,
  updateDeleteExpenseAllowed,
  updateDeleteCompletedBookingAllowed,
} from "../../reducer/userRestriction";
const useRestriction = () => {
  const { hotelDetails: HotelDetail } = useSelector((store) => store.login);

  const { loggedInUserPermissions, allRoles, allUsers } = useSelector(
    (store) => store.usersRestriction
  );

  const dispatch = useDispatch();

  useEffect(() => {
    performRestrictionApi();
  }, [loggedInUserPermissions]);

  useEffect(() => {
    if (Boolean(HotelDetail?.id))
      dispatch(getLoggedInUserPermission({ hotelId: HotelDetail?.id }));
  }, [allRoles, allUsers]);

  const performRestrictionApi = () => {
    let phoneNumber = window.localStorage.getItem("phone");

    dispatch(
      getCurrentUserPosArray({
        hotelId: HotelDetail?.id,
        searchText: phoneNumber,
      })
    );

    let isOwner = loggedInUserPermissions?.includes("OWNER") ?? false;

    // Check if the user is an owner and dispatch appropriate action
    if (Boolean(isOwner)) {
      dispatch(updateIsOwner(true));
    } else {
      // Consolidate permission checks
      const permissions = loggedInUserPermissions || [];

      // Booking permissions
      const bookingViewingAllowed = permissions.some((p) =>
        [
          "BOOKING_VIEW",
          "BOOKING_CREATE",
          "BOOKING_UPDATE",
          "BOOKING_DELETE",
        ].includes(p)
      );
      const bookingCreationAllowed = permissions.includes("BOOKING_CREATE");
      const bookingUpdationAllowed = permissions.includes("BOOKING_UPDATE");
      const bookingDeletionAllowed = permissions.includes("BOOKING_DELETE");

      const completedBookingAltertionAllowed = permissions?.includes(
        "COMPLETED_BOOKINGS_EDIT"
      );

      // Client permissions
      const clientViewAllowed = permissions.some((p) =>
        ["CLIENT_VIEW", "CLIENT_CREATE", "CLIENT_UPDATE"].includes(p)
      );
      const clientCreationAllowed = permissions.includes("CLIENT_CREATE");
      const clientUpdationAllowed = permissions.includes("CLIENT_UPDATE");

      // Hotel permissions
      const hotelViewingAllowed = permissions.some((p) =>
        ["HOTEL_VIEW", "HOTEL_UPDATE"].includes(p)
      );
      const hotelUpdationAllowed = permissions.includes("HOTEL_UPDATE");

      // Room permissions
      const roomViewAllowed = permissions.some((p) =>
        ["ROOM_VIEW", "ROOM_CREATE", "ROOM_UPDATE"].includes(p)
      );
      const roomUpdationAllowed = permissions.includes("ROOM_UPDATE");

      // User permissions
      const userViewAllowed = permissions.some((p) =>
        ["USERS_VIEW", "USERS_MANAGE"].includes(p)
      );
      const userUpdationAllowed = permissions.includes("USERS_MANAGE");

      // POS permissions
      const posCreationAllowed = permissions.includes("POS_CREATE");
      const posUpdationAllowed = permissions.includes("POS_UPDATE");

      // KDS permissions
      const kdsUpdationAllowed = permissions.includes("KDS_MANAGE");

      // Rate and Availability permissions
      const rateAvailabiltyView = permissions.some((p) =>
        ["RATES_AVAILABILITY_VIEW", "RATES_AVAILABILITY_UPDATE"].includes(p)
      );
      const rateAvailabiltyUpdation = permissions.includes(
        "RATES_AVAILABILITY_UPDATE"
      );

      // Housekeeping permissions
      const houseKeepingUpdationAllowed = permissions.includes(
        "HOUSEKEEPING_MANAGE"
      );

      // Tax permissions
      const taxAllowed = permissions.includes("TAX_MANAGE");

      // Payment permissions
      const paymentViewAllowed = permissions.includes("PAYMENT_VIEW");
      const paymentCreationAllowed = permissions.includes("PAYMENT_CREATE");
      const paymentUpdationAllowed = permissions.includes("PAYMENT_UPDATE");
      const paymentDeletionAllowed = permissions.includes("PAYMENT_DELETE");

      // Expense permissions
      const expenseViewAllowed = permissions.includes("EXPENSE_VIEW");
      const expenseCreationAllowed = permissions.includes("EXPENSE_CREATE");
      const expenseUpdationAllowed = permissions.includes("EXPENSE_UPDATE");
      const expenseDeletionAllowed = permissions.includes("EXPENSE_DELETE");

      // Ledger permissions
      const ledgerViewAllowed = permissions.includes("PARTY_VIEW");
      const ledgerCreationAllowed = permissions.includes("PARTY_CREATE");
      const ledgerUpdationAllowed = permissions.includes("PARTY_UPDATE");

      // Stocks permissions
      const stocksViewAllowed = permissions.includes("STOCKS_VIEW");
      const stocksUpdationAllowed = permissions.includes("STOCKS_UPDATE");

      // Invoice permissions
      const invoiceViewAllowed = permissions.includes("INVOICE_VIEW");

      // Dispatch actions
      dispatch(updateViewBookingAllowed(bookingViewingAllowed));
      dispatch(updateCreateBookingAllowed(bookingCreationAllowed));
      dispatch(updateEditBookingAllowed(bookingUpdationAllowed));
      dispatch(updateDeleteBookingAllowed(bookingDeletionAllowed));

      dispatch(
        updateDeleteCompletedBookingAllowed(completedBookingAltertionAllowed)
      );

      dispatch(updateCreateClientAllowed(clientCreationAllowed));
      dispatch(updateEditClientAllowed(clientUpdationAllowed));
      dispatch(updateViewClientAllowed(clientViewAllowed));

      dispatch(updateViewHotelDetails(hotelViewingAllowed));
      dispatch(updateEditHotelDetails(hotelUpdationAllowed));

      dispatch(updateViewRoomAllowed(roomViewAllowed));
      dispatch(updateEditRoomAllowed(roomUpdationAllowed));

      dispatch(updateViewUserAllowed(userViewAllowed));
      dispatch(updateEditUserAllowed(userUpdationAllowed));

      dispatch(updateCreatePosAllowed(posCreationAllowed));
      dispatch(updateEditPosAllowed(posUpdationAllowed));

      dispatch(updateEditKdsAllowed(kdsUpdationAllowed));

      dispatch(updateViewRateAndAvailabilty(rateAvailabiltyView));
      dispatch(updateEditRateAndAvailabilty(rateAvailabiltyUpdation));

      dispatch(updateEditHouseKeepingAllowed(houseKeepingUpdationAllowed));

      dispatch(updateEditTaxAllowed(taxAllowed));

      dispatch(updateViewPaymentAllowed(paymentViewAllowed));
      dispatch(updateCreatePaymentAllowed(paymentCreationAllowed));
      dispatch(updateEditPaymentAllowed(paymentUpdationAllowed));
      dispatch(updateDeletePaymentAllowed(paymentDeletionAllowed));

      dispatch(updateViewExpenseAllowed(expenseViewAllowed));
      dispatch(updateCreateExpenseAllowed(expenseCreationAllowed));
      dispatch(updateEditExpenseAllowed(expenseUpdationAllowed));
      dispatch(updateDeleteExpenseAllowed(expenseDeletionAllowed));

      dispatch(updateViewLedgerAllowed(ledgerViewAllowed));
      dispatch(updateCreateLedgerAllowed(ledgerCreationAllowed));
      dispatch(updateEditLedgerAllowed(ledgerUpdationAllowed));

      dispatch(updateViewStocksAllowed(stocksViewAllowed));
      dispatch(updateEditStocksAllowed(stocksUpdationAllowed));

      dispatch(updateViewInvoiceAllowed(invoiceViewAllowed));

      dispatch(updateIsOwner(false));
    }
  };
};

export default useRestriction;
