import React, { useState, useEffect } from "react";
import SidebarGeneric from "../../components/sidebar";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveMenu } from "../../reducer/appReducer";
import { updateDoa, updateDob } from "../../reducer/walkinReducer";
import {
  updateVisibleBookingDetailsCalendar,
  updateDisplayPrimaryGuestDoa,
  updateDisplayPrimaryGuestDob,
  updateDisplayPrimaryGuestDateIssue,
  updateDisplayPrimaryGuestDateExpire,
  updateDisplayPrimaryGuestDateArrival,
  updateDisplayPrimaryGuestDateDeparture,
} from "../../reducer/bookingReducer";
import {
  updateActivePosId,
  updateIsPosExist,
} from "../../reducer/posReducer/posSettingReducer";
import { setIsExportButtonExpanded } from "../../reducer/reportReducer";
import Loader from "../loading";
import { getHotelContent, getHotelDetails } from "../../reducer/loginReducer";
import {
  getCurrentNightAuditDate,
  updateDisplayOverLayingHamburgerMenu,
  updateGloabalSearchText,
  updateIsMobileAndTabletScreen,
  updateIsMobileScreen,
  updateOrganisationDropDownDisplay,
  updateQuickActionDropDownDisplay,
} from "../../reducer/appHeaderReducer";
import BookingEngine from "../../components/bookingEngine/Index";
import useScreen from "../../hooks/useScreen";
import {
  SCREEN_TYPE,
  activeFooterName,
  activeSideBarName,
  isSerachBarAlowedForThisPage,
} from "../../utils/helper";

import HamburgerDrawer from "../../components/appHeader/HamburgerDrawer";
import NightAuditConfirmationModal from "../../components/appHeader/NightAuditConfirmationModal";
import OrganisationDropDown from "../../components/appHeader/OrganisationDropDown";
import AppFooter from "../../mobileComponents/appFooter/Index";
import ExpandedHotelScreen from "../../mobileComponents/expandedHotelDetailScreen/Index";

import { updateActiveFooter } from "../../reducer/appHeaderReducer";
import AppHeader from "../../components/appHeader/Index";

const DashBoard = () => {
  const { menuDrawer, firstLoading, isBookingEngineBuiding } = useSelector(
    (store) => store.app
  );
  const { hotelDetails: HotelDetails } = useSelector((store) => store.login);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const screen = useScreen();
  const { slug } = useParams();
  let token = localStorage.getItem("token");

  const {
    organisationDropDownDisplay,
    expandedHotelDetailsScreen,
    activeFooter,
  } = useSelector((store) => store.appHeader);

  const { activePosSlug, response, activePosName, activePosGST } = useSelector(
    (store) => store.posSetting
  );

  const { currentUserPosIds } = useSelector((store) => store.usersRestriction);
  const [searchBarAllowed, setSearchBarAllowed] = useState(false);
  const [page, setPage] = useState("");

  useEffect(() => {
    dispatch(getHotelDetails());

    if (HotelDetails?.id) {
      const payload = { hotelId: HotelDetails?.id };
      dispatch(getHotelContent(payload));
      dispatch(getCurrentNightAuditDate(payload));
    }
  }, [HotelDetails?.id]);

  useEffect(() => {
    if (Boolean(slug)) {
      // if comes from url
      let posDetails = response
        ?.filter((item) => item.slug === slug)
        ?.filter((Obj) => currentUserPosIds?.includes(Obj?.id));

      if (posDetails?.length > 0) {
        dispatch(
          updateActivePosId({
            activePosId: posDetails?.[0]?.id,
            activePosType: posDetails?.[0]?.type,
            activePosName: posDetails?.[0]?.name,
            activePosSlug: posDetails?.[0]?.slug,
            activePosGST: posDetails?.[0]?.gstRate,
          })
        );
      } else {
        dispatch(updateIsPosExist(false));
      }
    }
  }, [activePosSlug, activePosName, slug, activePosGST]);

  useEffect(() => {
    if (screen === SCREEN_TYPE[0])
      dispatch(updateDisplayOverLayingHamburgerMenu(false));

    let isMobileAndTabletScreenlocal = Boolean(
      screen === SCREEN_TYPE[0] || screen === SCREEN_TYPE[1]
    );

    let isMobileScreemlocal = Boolean(screen === SCREEN_TYPE[0]);

    dispatch(updateIsMobileScreen(isMobileScreemlocal));
    dispatch(updateIsMobileAndTabletScreen(isMobileAndTabletScreenlocal));
  }, [screen]);

  useEffect(() => {
    const segments = location?.pathname?.split("/") || [];
    const lastSegment = segments.pop(); // Get the last segment
    const activeName = activeSideBarName(lastSegment);

    setPage(lastSegment);

    const activeFooter = activeFooterName(lastSegment);

    let searchBarAllowed =
      lastSegment === "walkin" || lastSegment === "bookingReservation"
        ? false
        : isSerachBarAlowedForThisPage(activeName);

    searchBarAllowed = lastSegment === "all-orders" ? true : searchBarAllowed;

    setSearchBarAllowed(searchBarAllowed);
    dispatch(updateGloabalSearchText(""));
    dispatch(updateActiveFooter(activeFooter));
  }, [navigate]);

  useEffect(() => {
    if (!Boolean(token)) navigate("/");
  }, [token, navigate]);

  const dashBoardHandler = (dashboardNavigationObject) => {
    let searchBarAll = isSerachBarAlowedForThisPage(
      dashboardNavigationObject.title
    );
    setSearchBarAllowed(Boolean(searchBarAll));

    dispatch(updateGloabalSearchText(""));
    dispatch(
      updateActiveMenu({
        activeMenu: dashboardNavigationObject.title,
        pos: false,
      })
    );
  };

  const handleOnClick = () => {
    dispatch(updateVisibleBookingDetailsCalendar(false));
    dispatch(updateDob(false));
    dispatch(updateDoa(false));
    dispatch(setIsExportButtonExpanded(false));
    dispatch(updateDisplayPrimaryGuestDob(false));
    dispatch(updateDisplayPrimaryGuestDoa(false));
    dispatch(updateDisplayPrimaryGuestDateArrival(false));
    dispatch(updateDisplayPrimaryGuestDateDeparture(false));
    dispatch(updateDisplayPrimaryGuestDateIssue(false));
    dispatch(updateDisplayPrimaryGuestDateExpire(false));

    dispatch(updateOrganisationDropDownDisplay(false));
    dispatch(updateQuickActionDropDownDisplay(false));
  };

  return !firstLoading ? (
    <Loader />
  ) : (
    <>
      {/* first time , it will show for buiding booking engine */}
      {isBookingEngineBuiding ? (
        <BookingEngine hotelId={HotelDetails?.id} />
      ) : (
        <>
          {screen === SCREEN_TYPE[0] ? (
            <>
              {expandedHotelDetailsScreen ? (
                <ExpandedHotelScreen />
              ) : (
                <>
                  {" "}
                  <Outlet />
                  {activeFooter === "Dashboard" ||
                  activeFooter === "Bookings" ||
                  activeFooter === "POS" ||
                  activeFooter === "Menu" ||
                  activeFooter === "Invoice" ||
                  activeFooter === "Clients" ? (
                    <AppFooter />
                  ) : null}
                </>
              )}
            </>
          ) : screen === SCREEN_TYPE[1] ? (
            <>
              <AppHeader searchBarAllowed={searchBarAllowed} page={page} />
              {organisationDropDownDisplay && <OrganisationDropDown />}
              <Outlet />

              <HamburgerDrawer setSearchBarAllowed={setSearchBarAllowed} />
            </>
          ) : (
            <div className={`relative h-full`} onClick={handleOnClick}>
              <div className=" h-full  overflow-y-auto  lg:block ">
                <AppHeader searchBarAllowed={searchBarAllowed} page={page} />
                <SidebarGeneric dashboardSelectHandler={dashBoardHandler} />
              </div>

              <NightAuditConfirmationModal />
              {organisationDropDownDisplay && <OrganisationDropDown />}

              <div className={` ${menuDrawer ? "pl-[5rem]" : "pl-[13rem]"} `}>
                <Outlet />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DashBoard;
