import {
  getAllRooms,
  getInactiveRooms,
  updateRoomService,
  saveAllRooms,
  // getRoomByIdService,
} from "../services/rooms";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

export const saveAllRoomAsync = createAsyncThunk(
  "rooms/saveAllRoomAsync",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await saveAllRooms(payload);
      message.success("Success");
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

// update when room type api called

export const updateAllRoomsAsyncThunk = createAsyncThunk(
  "rooms/updateAllRoomsAsyncThunk",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let response = await saveAllRooms(payload.payload);
      message.success(payload.successMessage);
      return fulfillWithValue({
        response: response.data,
        data: payload.data,
        selectedRoomsId: payload.selectedRoomsId,
      });
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const updateRoomByRoomResponse = createAsyncThunk(
  "rooms/updateRoomByRoomResponse",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      return fulfillWithValue(payload);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getAllRoomsAsyncThunk = createAsyncThunk(
  "getAllRooms/rooms",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getAllRooms(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const getInActiveRoomsAsyncThunk = createAsyncThunk(
  "getInactiveRooms/rooms",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getInactiveRooms(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error.message);
      throw rejectWithValue(error.message);
    }
  }
);

export const updateRoomAsyncThunk = createAsyncThunk(
  "updateRoom/rooms",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateRoomService(payload);
      return fulfillWithValue(response.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
      throw rejectWithValue(error.message);
    }
  }
);

// export const getRoomById = createAsyncThunk(
//   "getRoomById",
//   async (
//     payload,
//     { dispatch, getState, rejectWithValue, fulfillWithValue }
//   ) => {
//     try {
//       const endpoint = "/rooms";
//       const response = await getRoomByIdService(endpoint, payload);
//       return fulfillWithValue(response);
//     } catch (error) {
//       throw rejectWithValue(error.message);
//     }
//   }
// );

const initialState = {
  loading: false, // before api call
  response: [], // store  room list json data into it
  apiError: false, // check api status
  data: [],
  inActiveRooms: [], //stores inactive rooms

  // update multiple rooms
  updateMultipleLoading: false,
  updateMultipleError: null,
  singleRoomData: {},
  showRoomNumberModal: false,
  showRoomViewModal: false,
  uploadedFileUrl: [], // after uploaded the file , store the file url,
  addRoomNo: "",
  editRoomDetails: false,
  addRoomDetails: false,
  inputData: {},
  selectedRoomType: "",
  roomForEditAndDelete: null,
  showSettingAddons: false,
  initialRoomData: {},
};

export const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    CLEAR_REDUX_STORE_39: () => initialState,

    resetInitialRoomData: (state, action) => {
      state.initialRoomData = {};
    },
    updateRoomList: (state, action) => {
      state.loading = action.payload.loading;
      state.response = action.payload.response;
      state.apiError = action.payload.apiError;
    },

    updateSelectedHousekeepingStatus: (state, action) => {
      if (state.selectedHousekeepingStatus === action.payload) {
        state.selectedHousekeepingStatus = null;
      } else {
        state.selectedHousekeepingStatus = action.payload;
      }
    },

    updateSelectedRoomType: (state, action) => {
      state.selectedRoomType = action.payload;
    },
    roomDataForEditAndDelete: (state, action) => {
      state.roomForEditAndDelete = action.payload;
    },

    getRoomDetailsForEdit: (state, action) => {
      state.singleRoomData = action.payload;
      state.initialRoomData = action.payload;
    },
    openEditRoomDetails: (state, action) => {
      state.editRoomDetails = action.payload;
    },
    openAddRoomDetails: (state, action) => {
      state.addRoomDetails = action.payload;
    },

    openRoomNumberModal: (state, action) => {
      state.showRoomNumberModal = action.payload.modal;
    },

    openRoomViewModal: (state, action) => {
      state.showRoomViewModal = action.payload.modal;
    },
    updateAddRoomNo: (state, action) => {
      state.addRoomNo = action.payload;
    },
    udateInputData: (state, action) => {
      state.inputData = action.payload;
    },
    openSettingAddons: (state, action) => {
      state.showSettingAddons = !state.showSettingAddons;
    },

    updateUploadId: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) === -1) {
        state.uploadedFileUrl.push(action.payload);
      }
    },

    setUploadedFileUrl: (state, action) => {
      state.uploadedFileUrl = action.payload;
    },
    removeUploadedUrl: (state, action) => {
      if (state.uploadedFileUrl.indexOf(action.payload) > -1) {
        let index = state.uploadedFileUrl.indexOf(action.payload);
        state.uploadedFileUrl.splice(index, 1);
      }
    },

    closeAllSetting: (state, action) => {
      state.editRoomDetails = false;
      state.addRoomDetails = false;
      state.showSettingAddons = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAllRoomAsync.pending, (state, action) => {
      state.loading = true;
      state.apiError = null;
    });
    builder.addCase(saveAllRoomAsync.fulfilled, (state, action) => {
      message.success("Data Successfully Updated!...");
      state.loading = false;
      state.response = action.payload;
      state.error = null;
    });
    builder.addCase(saveAllRoomAsync.rejected, (state, action) => {
      state.loading = false;
      state.apiError = action.error.message;
    });

    builder.addCase(getAllRoomsAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.apiError = false;
      state.data = action.payload;
      state.response = action.payload;
    });
    builder.addCase(getAllRoomsAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.apiError = false;
    });
    builder.addCase(getAllRoomsAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.apiError = true;
    });
    builder.addCase(updateRoomAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      if (action.payload?.status === "inActive") {
        const updatedRoomsData = state.data?.filter(
          (room) => room?.id !== action.payload?.id
        );
        state.data = updatedRoomsData;
        state.inActiveRooms = [...state.inActiveRooms, action.payload];
      } else {
        const foundRoom = state.data?.find(
          (room) => room?.id === action.payload?.id
        );
        if (!foundRoom) {
          state.data = [...state.data, action.payload];
          const updatedRoomsData = state.inActiveRooms?.filter(
            (room) => room?.id !== action.payload?.id
          );
          state.inActiveRooms = updatedRoomsData;
        } else {
          const updatedRoomsData = state.data?.map((room) =>
            room?.id === action.payload?.id ? action.payload : room
          );
          state.data = updatedRoomsData;
        }
      }
    });
    builder.addCase(updateRoomAsyncThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateRoomAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getInActiveRoomsAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.inActiveRooms = action.payload;
    });
    builder.addCase(getInActiveRoomsAsyncThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getInActiveRoomsAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });

    builder.addCase(updateAllRoomsAsyncThunk.pending, (state, action) => {
      state.updateMultipleLoading = true;
    });
    builder.addCase(updateAllRoomsAsyncThunk.fulfilled, (state, action) => {
      const { response, data, selectedRoomsId } = action.payload;
      state.updateMultipleLoading = false;
      state.data = data
        .filter(({ id }) => !selectedRoomsId.includes(id))
        .concat(response);
    });
    builder.addCase(updateAllRoomsAsyncThunk.rejected, (state, action) => {
      state.updateMultipleLoading = false;
      state.updateMultipleError = action.payload;
    });
    builder.addCase(updateRoomByRoomResponse.fulfilled, (state, action) => {
      state.response = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  CLEAR_REDUX_STORE_39,
  updateRoomList,
  getRoomDetailsForEdit,
  updateAddRoomNo,
  openRoomNumberModal,
  openRoomViewModal,
  updateUploadId,
  removeUploadedUrl,
  openEditRoomDetails,
  openAddRoomDetails,
  closeAllSetting,
  udateInputData,
  updateSelectedRoomType,
  roomDataForEditAndDelete,
  openSettingAddons,
  resetInitialRoomData,
  setUploadedFileUrl,
} = roomSlice.actions;

export default roomSlice.reducer;
