import { useDispatch, useSelector } from "react-redux";
import {
  updateBookingListData,
  updateBookingDetails,
  updateInvoiceDetails,
  updateShowMainBookingDetailsDrawer,
  updateActiveTab,
  updateDisplayCheckInDrawer,
  updateShowGrcDrawer,
  updateShowFolioDrawer,
  updateShowRefundPaymentDrawer,
  postPaymentAndUpdateInvoiceDetails,
  updateShowAttachToPartyDrawer,
  updateAttachPartyString,
  updateAttachPartyValue,
  updateDisplayCheckOutDrawer,
  updateShowApplyDiscountDrawer,
  updateMobileBookingDetailsId,
  updateDisplayDeletePartyModal,
  updatePaymentDetails,
  updateDisplayCancelBookingModal,
  resetBookingDetails,
  deleteBookingDetails,
  updateDisplayDeleteBookingModal,
  updateDisplayUndoCompletedBookingModal,
  updateDisplayUndoNoShowBookingModal,
} from "../../reducer/bookingReducer";

import { updateBookingListData as updateReservationCalendarBookingListData } from "../../reducer/bookingReservationReducer";
import {
  adultAssigneFunction,
  bookingDetailsAddOnsCalculation,
  bookingPriceCalculation,
  buildDetailedPriceBreakup,
} from "../../utils/bookingHelper";

import { updateinvoice, postPayment } from "../../services/invoice";

import { message } from "antd";
import { updatePayment, deletePayment } from "../../reducer/paymentReducer";
import { updateActiveFooter } from "../../reducer/appHeaderReducer";

const useAllBookingFunction = (setLoading) => {
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector((store) => store.login);
  const { bookingResponse: bookingReservationResponse } = useSelector(
    (store) => store.bookingReservation
  );

  const {
    showMainBookingDetailsDrawer,
    bookingDetails,
    invoiceDetails,
    paymentDetails,
    detailedPriceBreakup,
    roomPriceStructure,
    response: bookingResponse,
    nightsCount,
  } = useSelector((store) => store.booking);

  const { response: roomResponse } = useSelector((store) => store.rooms);

  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const mandatoryCheckInDetailsDisabled =
    hotelDetails?.mandatoryCheckInDetailsDisabled;

  const bookingId = showMainBookingDetailsDrawer?.bookingId;
  const bookingType = showMainBookingDetailsDrawer?.bookingType;

  const handleCheckIn = async () => {
    setLoading(true);

    const arrival = Boolean(bookingDetails?.arrival)
      ? bookingDetails?.arrival
      : "";
    const destination = Boolean(bookingDetails?.destination)
      ? bookingDetails?.destination
      : "";
    const purposeOfVisit = Boolean(bookingDetails?.purposeOfVisit)
      ? bookingDetails?.purposeOfVisit
      : "";

    if (
      (Boolean(arrival) && Boolean(destination) && Boolean(purposeOfVisit)) ||
      (Boolean(mandatoryCheckInDetailsDisabled) &&
        mandatoryCheckInDetailsDisabled === true)
    ) {
      const updatedData = JSON.parse(JSON.stringify(bookingDetails));

      updatedData.status = "checkedIn";

      const data = await dispatch(updateBookingDetails(updatedData));

      const bookings = bookingReservationResponse?.bookings;

      if (!Boolean(data.error)) {
        let updatedBookingDetails = bookings?.map((Obj) =>
          Obj?.id === data?.payload?.id ? data?.payload : Obj
        );

        const newResponse = JSON.parse(
          JSON.stringify(bookingReservationResponse)
        );

        newResponse.bookings = updatedBookingDetails;

        await dispatch(updateReservationCalendarBookingListData(newResponse));

        dispatch(
          updateShowMainBookingDetailsDrawer({
            visible: false,
            bookingId: "",
            bookingType: "",
            newBookingId: "",
          })
        );
        dispatch(updateMobileBookingDetailsId(""));

        dispatch(updateActiveTab("ongoing"));
        dispatch(updateActiveFooter("Bookings"));

        setLoading(false);
        message.success("Successfully Checked In");
      } else {
        message.error(data.payload);
        setLoading(false);
      }
    } else {
      dispatch(updateDisplayCheckInDrawer());
      setLoading(false);
    }
  };

  const handleCheckOut = async () => {
    setLoading(true);
    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "completed";

    const bookings = bookingReservationResponse?.bookings;

    const data = await dispatch(updateBookingDetails(updatedData));

    if (Boolean(data.payload)) {
      let updatedBookingDetails = bookings?.map((Obj) =>
        Obj?.id === data?.payload?.id ? data?.payload : Obj
      );

      const newResponse = JSON.parse(
        JSON.stringify(bookingReservationResponse)
      );

      newResponse.bookings = updatedBookingDetails;

      await dispatch(updateReservationCalendarBookingListData(newResponse));

      dispatch(updateActiveTab("completed"));

      dispatch(updateMobileBookingDetailsId(""));

      message.success("Successfully Checked Out");
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: showMainBookingDetailsDrawer?.newBookingId,
        })
      );
    } else {
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: "",
        })
      );
      message.error("Something went wrong");
    }

    dispatch(updateActiveFooter("Bookings"));
    setLoading(false);
  };

  const handleUndoCheckIn = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "confirmed";

    const data = await dispatch(updateBookingDetails(updatedData));

    if (!data?.payload?.id) {
      setLoading(false);
      message.info(data?.payload);
      dispatch(updateDisplayUndoNoShowBookingModal(false));
      return;
    }

    const bookings = bookingReservationResponse?.bookings;

    if (data.payload) {
      let updatedBookingDetails = bookings?.map((Obj) =>
        Obj?.id === data?.payload?.id ? data?.payload : Obj
      );

      const newResponse = JSON.parse(
        JSON.stringify(bookingReservationResponse)
      );

      newResponse.bookings = updatedBookingDetails;

      dispatch(updateReservationCalendarBookingListData(newResponse));

      dispatch(updateActiveTab("upcoming"));

      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: showMainBookingDetailsDrawer?.newBookingId,
        })
      );
      dispatch(updateDisplayUndoNoShowBookingModal(false));

      dispatch(updateMobileBookingDetailsId(""));

      message.success("Booking Successfully Updated");
    } else {
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: "",
        })
      );
      dispatch(updateDisplayUndoNoShowBookingModal(false));

      message.error("Something went wrong");
    }

    dispatch(updateActiveFooter("Bookings"));
    setLoading(false);
  };

  const handleUndoCheckOut = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "checkedIn";

    const data = await dispatch(updateBookingDetails(updatedData));

    if (!data?.payload?.id) {
      setLoading(false);
      dispatch(updateDisplayUndoCompletedBookingModal(false));
      message.info(data?.payload);
      return;
    }

    const bookings = bookingReservationResponse?.bookings;

    if (data.payload) {
      let updatedBookingDetails = bookings?.map((Obj) =>
        Obj?.id === data?.payload?.id ? data?.payload : Obj
      );

      const newResponse = JSON.parse(
        JSON.stringify(bookingReservationResponse)
      );

      newResponse.bookings = updatedBookingDetails;

      dispatch(updateReservationCalendarBookingListData(newResponse));

      dispatch(updateActiveTab("ongoing"));

      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: showMainBookingDetailsDrawer?.newBookingId,
        })
      );
      dispatch(updateDisplayUndoCompletedBookingModal(false));
      dispatch(updateMobileBookingDetailsId(""));

      message.success("Booking Successfully Updated");
    } else {
      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: "",
        })
      );
      message.error("Something went wrong");
    }

    dispatch(updateActiveFooter("Bookings"));
    setLoading(false);
  };

  const handleConfirmBooking = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "confirmed";

    const data = await dispatch(updateBookingDetails(updatedData));

    if (!Boolean(data.error)) {
      dispatch(updateActiveTab("upcoming"));

      const bookings = bookingReservationResponse?.bookings;

      let updatedBookingDetails = bookings?.map((Obj) =>
        Obj?.id === data?.payload?.id ? data?.payload : Obj
      );

      const newResponse = JSON.parse(
        JSON.stringify(bookingReservationResponse)
      );

      newResponse.bookings = updatedBookingDetails;

      await dispatch(updateReservationCalendarBookingListData(newResponse));

      message.success("Booking Successfully Updated");

      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: false,
          bookingId: "",
          bookingType: "",
          newBookingId: showMainBookingDetailsDrawer?.newBookingId,
        })
      );

      dispatch(updateActiveFooter("Bookings"));
      dispatch(updateMobileBookingDetailsId(""));
    }

    setLoading(false);
  };

  const handleBookingToBeCanceld = async () => {
    setLoading(true);

    const updatedData = JSON.parse(JSON.stringify(bookingDetails));
    updatedData.status = "cancelled";

    await dispatch(updateBookingDetails(updatedData));

    dispatch(updateDisplayCancelBookingModal());

    const bookingList = bookingResponse?.bookings;

    const newBookingList = bookingList?.filter(
      (booking) => booking.id !== updatedData.id
    );

    const newResponse = { ...bookingResponse, bookings: newBookingList };

    dispatch(updateBookingListData(newResponse));
    dispatch(updateReservationCalendarBookingListData(newResponse));
    message.destroy();
    message.success("Booking Cancelled");

    dispatch(updateActiveFooter("Bookings"));
    dispatch(resetBookingDetails());
    setLoading(false);
  };

  const handleBookingToBeDeleted = async () => {
    setLoading(true);

    const payload = {
      hotelId: hotelDetails?.id,
      id: bookingDetails?.id,
    };

    const response = await dispatch(deleteBookingDetails(payload));

    if (response?.payload?.data) {
      const bookingList = bookingResponse?.bookings;

      const newBookingList = bookingList?.filter(
        (booking) => booking?.id !== bookingDetails?.id
      );

      const newResponse = { ...bookingResponse, bookings: newBookingList };

      dispatch(updateDisplayDeleteBookingModal());
      dispatch(updateBookingListData(newResponse));
      dispatch(updateReservationCalendarBookingListData(newResponse));

      message.destroy();
      message.success("The booking has been successfully deleted.");
      dispatch(updateActiveFooter("Bookings"));
      dispatch(resetBookingDetails());
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleBookingToBeMarkedNoShow = async () => {
    setLoading(true);
    const updatedData = JSON.parse(JSON.stringify(bookingDetails));

    updatedData.status = "noShow";

    await dispatch(updateBookingDetails(updatedData));
    const bookingList = bookingResponse?.bookings;

    const newBookingList = bookingList?.filter(
      (booking) => booking.id !== updatedData.id
    );
    const newResponse = { ...bookingResponse, bookings: newBookingList };
    dispatch(updateBookingListData(newResponse));
    dispatch(updateReservationCalendarBookingListData(newResponse));

    message.destroy();
    message.success("Booking marked as No Show");

    dispatch(updateActiveFooter("Bookings"));
    dispatch(resetBookingDetails());
    setLoading(false);
  };

  const handleFolio = () => {
    dispatch(
      updateShowMainBookingDetailsDrawer({
        visible: "false",
        bookingId: bookingId,
        bookingType: bookingType,
        newBookingId: showMainBookingDetailsDrawer?.newBookingId,
      })
    );
    dispatch(updateShowFolioDrawer(true));
  };

  const handleGrc = () => {
    dispatch(
      updateShowMainBookingDetailsDrawer({
        visible: "false",
        bookingId: bookingId,
        bookingType: bookingType,
        newBookingId: showMainBookingDetailsDrawer?.newBookingId,
      })
    );
    dispatch(updateShowGrcDrawer());
  };

  const refund = async (
    amount,
    setAmount,
    setReason,
    reason,
    paymentMode,
    methodToPartyId
  ) => {
    if (Boolean(amount)) {
      setLoading(true);

      let currentPaidValue = 0;
      let alreadyGivenRefund = 0;

      if (Array.isArray(paymentDetails)) {
        paymentDetails
          ?.map((Obj) => Obj?.amount)
          ?.filter((Obj) => Obj > 0)
          ?.map((Obj) => (currentPaidValue += Math.abs(Obj)));
      } else return false;

      if (Array.isArray(paymentDetails)) {
        paymentDetails
          ?.map((Obj) => Obj?.amount)
          ?.filter((Obj) => Obj < 0)
          ?.map((Obj) => (alreadyGivenRefund += Math.abs(Obj)));
      } else return false;

      const totalRefund = Math.abs(alreadyGivenRefund) + Math.abs(amount);

      if (totalRefund >= currentPaidValue) {
        message.info("Refund should not be greater than paid value");
        setAmount("");
        setReason("");

        setLoading(false);
        return false;
      }

      let roomInvoiceId = Array.isArray(invoiceDetails)
        ? invoiceDetails
            ?.filter((Obj) => Obj?.invoiceType === "roomBill")
            ?.map((Obj) => Obj?.id)?.[0]
        : "";

      const paymentPayload = {
        amount: -amount,
        bookingId: showMainBookingDetailsDrawer?.bookingId,
        hotelId: hotelDetails?.id,
        method: paymentMode,
        remarks: reason,
        invoiceId: roomInvoiceId,
        paymentModePartyId: methodToPartyId[paymentMode],
      };

      const invoiceIdArray = Array.isArray(invoiceDetails)
        ? invoiceDetails?.map((Obj) => Obj?.id)?.filter((Obj) => Boolean(Obj))
        : [];

      const invoicePayload = {
        hotelId: hotelDetails?.id,
        invoiceIds: invoiceIdArray,
      };

      const payload = {
        paymentPayload: paymentPayload,
        invoicePayload: invoicePayload,
      };

      dispatch(postPaymentAndUpdateInvoiceDetails(payload));

      dispatch(updateShowRefundPaymentDrawer());
      setAmount("");
      setReason("");

      setLoading(false);
    } else {
      message.info("Please enter amount");
    }
  };

  const handleAttachToParty = async (
    response,
    balanceDue,
    partyResponse,
    attachPartyValue,
    setLoading
  ) => {
    if (!partyResponse || !partyResponse?.length) {
      message.info("Please select party first");
      return;
    }

    setLoading(true);

    let roomInvoice = invoiceDetails?.find(
      (Obj) => Obj?.invoiceType === "roomBill"
    );

    let partyId = partyResponse?.find(
      (Obj) => Obj?.displayName === attachPartyValue
    )?.id;

    let updatedInvoice = JSON.parse(JSON.stringify(roomInvoice));
    updatedInvoice.partyId = partyId;

    const payload = {
      id: updatedInvoice?.id,
      hotelId: hotelDetails?.id,
      body: updatedInvoice,
    };

    const oldPayment = paymentDetails?.find((Obj) => Obj?.method === "btc");

    if (Boolean(oldPayment)) {
      const paymentPayload = {
        hotelId: hotelDetails?.id,
        message: "Updated",
        paymentObj: {
          ...oldPayment,
          paymentModePartyId: partyId,
        },
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) => {
          if (item.id === data.id) {
            return data;
          } else return item;
        });

        const oldUpdatedPayment = await dispatch(updatePayment(paymentPayload));

        const res = await dispatch(updateInvoiceDetails(updatedData));

        if (res?.type) {
          const newPaymentArray = response?.payments?.map((Obj) =>
            Obj?.id === oldUpdatedPayment?.payload?.id
              ? oldUpdatedPayment?.payload
              : Obj
          );

          const oldUpdatedPaymentDetails = paymentDetails?.map((Obj) =>
            Obj?.id === oldUpdatedPayment?.payload?.id
              ? oldUpdatedPayment?.payload
              : Obj
          );
          const newResponse = { ...response, payments: newPaymentArray };

          dispatch(updatePaymentDetails(oldUpdatedPaymentDetails));
          dispatch(updateBookingListData(newResponse));

          dispatch(updateDisplayCheckOutDrawer(false));
          dispatch(updateShowAttachToPartyDrawer(false));
          dispatch(updateAttachPartyString(""));
          dispatch(updateAttachPartyValue(""));
        } else message.error("Attach to Party failed");
      }
    } else {
      const paymentPayload = {
        amount: balanceDue,
        bookingId: bookingDetails?.id,
        hotelId: hotelDetails?.id,
        invoiceId: updatedInvoice?.id,
        method: "btc",
        paymentModePartyId: partyId,
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) => {
          if (item.id === data.id) return data;
          else return item;
        });

        const newPayment = await postPayment(paymentPayload);
        const res = await dispatch(updateInvoiceDetails(updatedData));

        if (res?.type) {
          const newPaymentArray = [...response.payments, newPayment];
          const newResponse = { ...response, payments: newPaymentArray };

          const updatedPaymentDetails = [...paymentDetails, newPayment];

          dispatch(updatePaymentDetails(updatedPaymentDetails));
          dispatch(updateBookingListData(newResponse));

          dispatch(updateDisplayCheckOutDrawer(false));
          dispatch(updateShowAttachToPartyDrawer(false));

          message.destroy();
          message.success("Party updated");

          dispatch(updateAttachPartyString(""));
          dispatch(updateAttachPartyValue(""));
        } else message.error("Attach to Party failed");
      }
    }

    setLoading(false);
  };

  const handleDeletePayment = async (
    id,
    isBookingDetailsDrawer,
    displayMessage,
    isPosComponent,
    currentPosInvoice
  ) => {
    message.destroy();
    setLoading(true);

    try {
      if (!id) {
        message.info("Something went wrong");
        setLoading(false);
        return;
      }

      const paymentPayload = {
        hotelId: hotelDetails?.id,
        id: id,
      };

      const deletedPaymentResponse = await dispatch(
        deletePayment(paymentPayload)
      );

      if (!deletedPaymentResponse?.payload?.data) {
        message.info("Something went wrong");
        setLoading(false);
        return;
      }

      if (isBookingDetailsDrawer) {
        // Filter out the deleted payment from the response
        const newPaymentArray = bookingResponse?.payments?.filter(
          (Obj) => Obj?.id !== paymentPayload?.id
        );

        // Update the payment details in the local state
        const updatedPaymentDetails = paymentDetails?.filter(
          (Obj) => Obj?.id !== paymentPayload?.id
        );

        const newResponse = { ...bookingResponse, payments: newPaymentArray };

        dispatch(updatePaymentDetails(updatedPaymentDetails));
        dispatch(updateBookingListData(newResponse));
      }

      if (isPosComponent) {
      }

      message.destroy();
      message.success(displayMessage);

      setLoading(false);
      dispatch(
        updateDisplayDeletePartyModal({ display: false, partyName: "" })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoading(false);
      message.error("An error occurred while deleting the attached party");
    }
  };

  const updateInvoicePricesAsDiscount = async (
    discountType,
    discountValue,
    setInitialRender
  ) => {
    setLoading(true);

    const discountInfo = {
      type: discountType,
      value: discountValue,
    };

    let roomInvoice = Array.isArray(invoiceDetails)
      ? invoiceDetails?.find((Obj) => Obj?.invoiceType === "roomBill")
      : null;

    let currentPriceBreakup = Array.isArray(roomInvoice?.priceBreakup)
      ? roomInvoice?.priceBreakup
      : [];

    let roomsInThisBooking = bookingDetails?.rooms;

    let detailedRoomInthisBooking = roomResponse?.filter((Obj) =>
      roomsInThisBooking?.includes(Obj?.id)
    );

    let isOTABooking = Boolean(bookingDetails?.cmId);

    let localDetailedPriceBrekup = buildDetailedPriceBreakup(
      currentPriceBreakup,
      roomTypes,
      detailedRoomInthisBooking,
      nightsCount,
      isOTABooking
    );

    let selectedRoomTypeWithRoom = {};
    roomResponse.forEach((item) => {
      let rooms = bookingDetails?.rooms;
      rooms?.map((room) => {
        if (item.id === room) {
          selectedRoomTypeWithRoom[item.type] =
            selectedRoomTypeWithRoom[item.type] !== undefined
              ? [...selectedRoomTypeWithRoom[item.type], item]
              : [item];
        }
      });
    });

    if (
      Boolean(roomInvoice) &&
      Array.isArray(localDetailedPriceBrekup) &&
      localDetailedPriceBrekup?.length > 0 &&
      roomTypes?.length
    ) {
      let taxInclusionInPrice = roomInvoice?.taxIncludedInPrice;

      let taxObjectArray = roomInvoice?.taxObjects;

      let oldAddOnsArray = roomInvoice?.priceBreakup?.filter(
        (Obj) => Obj?.type === "Addons"
      );

      oldAddOnsArray = Array.isArray(oldAddOnsArray)
        ? oldAddOnsArray?.[0]?.addOns
        : [];

      const alreadyPresentTaxArray = Array.isArray(roomInvoice?.taxObjects)
        ? roomInvoice.taxObjects
        : [];

      const result = bookingPriceCalculation(
        selectedRoomTypeWithRoom,
        roomTypes,
        nightsCount || 1,
        discountInfo,
        [],
        localDetailedPriceBrekup,
        Boolean(taxInclusionInPrice),
        false,
        alreadyPresentTaxArray
      );

      let oldAddOnsCalcResult = bookingDetailsAddOnsCalculation(
        oldAddOnsArray,
        taxObjectArray
      );

      const priceBreakup = roomInvoice?.priceBreakup;

      let requiredPriceBrekupForDiscount = detailedPriceBreakup
        ?.reduce((acc, room) => {
          const existingRoom = acc.find((item) => item.name === room.type);

          if (existingRoom) {
            existingRoom.rate += room.rate;
            existingRoom.count += 1;
          } else {
            acc.push({ name: room.type, rate: room.rate, count: 1 });
          }

          return acc;
        }, [])
        .map((obj) => ({
          name: obj.name,
          rate: obj.rate / obj.count, // Calculate average rate
          count: obj.count,
        }));

      let newPriceBreakup = priceBreakup?.map((Obj) => {
        let currentCmbinedPriceBrekup = requiredPriceBrekupForDiscount?.find(
          (currObj) => Obj?.type === currObj?.name
        );

        let currentdiscount =
          (Obj?.rate / result?.roomTotal) * result?.discount;

        let discountPerNight =
          currentdiscount / nightsCount / currentCmbinedPriceBrekup?.count;

        discountPerNight = Boolean(currentdiscount) ? currentdiscount : 0;

        let discountedRate = Obj?.rate - discountPerNight ?? 0;

        return {
          ...Obj,
          discountedRate: discountedRate,
        };
      });

      const newInvoiceDetails = JSON.parse(JSON.stringify(roomInvoice));

      // Removing existing Room Tax but keeping addOn Tax as it is
      newInvoiceDetails.totalTax =
        oldAddOnsCalcResult?.totalAddOnsTax + result?.totalRoomTax;

      newInvoiceDetails.subTotal = result?.subTotal;
      newInvoiceDetails.total =
        result?.total + oldAddOnsCalcResult?.addOnsTotal;

      newInvoiceDetails.discountInfo = discountInfo;
      newInvoiceDetails.discount = result?.discount;

      newInvoiceDetails.priceBreakup = newPriceBreakup;
      newInvoiceDetails.discountInfo = discountInfo;

      const payload = {
        hotelId: newInvoiceDetails?.hotelId,
        id: newInvoiceDetails?.id,
        body: newInvoiceDetails,
      };

      const data = await updateinvoice(payload);

      if (Boolean(data?.id)) {
        const updatedData = invoiceDetails?.map((item) =>
          Boolean(item.id === data?.id) ? data : item
        );

        message.success("Discount Applied");
        await dispatch(updateInvoiceDetails(updatedData));
        Boolean(setInitialRender) && setInitialRender(true);
        dispatch(updateShowApplyDiscountDrawer());
      } else message.info("Something Went Wrong");
    } else message.info("Something Went Wrong");

    setLoading(false);
  };

  // This below function are common to Create Booking (Mobile and Desktop)  and in Booking details (Mobile and Desktop)

  const handleRoomSelectionModalApplyButton = ({
    tempSelectedRoomWTypeAndNum,
    roomAvailabiltyArray,
    occupancyPlanRate,
    updateDetailedPriceBreakup,
    mealPlan,
    roomPriceStructure,
    updateAdults,
    saveTempSelectedRoomWTypeAndNum,
    updateVisibleRoomNumberModal,
    updateRoomChangeMade,
  }) => {
    let selectedRoomTypeArray = Object.keys(tempSelectedRoomWTypeAndNum);
    let totalAdults = 0;
    let finalDetailedBreakup = [];

    // Use for...of to handle asynchronous calls with await
    for (const roomTypeName of selectedRoomTypeArray) {
      let currentRoomType = roomAvailabiltyArray?.find(
        (currObj) => currObj?.name === roomTypeName
      );

      let numberOfRooms =
        tempSelectedRoomWTypeAndNum[roomTypeName]?.length || 0;

      totalAdults += currentRoomType?.defaultOccupancy
        ? +currentRoomType.defaultOccupancy * numberOfRooms
        : 1;

      let selectedRoom = tempSelectedRoomWTypeAndNum[roomTypeName];

      // Await the async function call
      const priceBreakup = adultAssigneFunction({
        selectedRoomWTypeAndNum: tempSelectedRoomWTypeAndNum,
        roomTypes: roomTypes,
        roomPriceStructure: roomPriceStructure,
        occupancyPlanRate: occupancyPlanRate,
        noOfPeople: currentRoomType?.defaultOccupancy * selectedRoom?.length,
        detailedPriceBreakup: finalDetailedBreakup,
        selectedRoomTypeName: roomTypeName,
        mealPlan: mealPlan,
        combinedDefaultOccupancyOfAllRooms:
          currentRoomType?.defaultOccupancy * selectedRoom?.length,
        combinedMaxOccupancyOfAllRooms:
          currentRoomType?.maxGuestAllowed * selectedRoom?.length,
      });

      finalDetailedBreakup = priceBreakup;
    }

    dispatch(updateDetailedPriceBreakup(finalDetailedBreakup));
    dispatch(updateAdults(totalAdults));
    dispatch(saveTempSelectedRoomWTypeAndNum());
    dispatch(updateRoomChangeMade(true));
    dispatch(updateVisibleRoomNumberModal(false));
  };

  const handleSelectedRoomChangeInRoomSelectionModal = (
    selectedStatus,
    roomType,
    roomNum,
    roomArray,
    tempSelectedRoomWTypeAndNum,
    updateTempSelectedRoomWTypeAndNum
  ) => {
    if (selectedStatus) {
      let removeSelectedRoom = tempSelectedRoomWTypeAndNum[roomType]?.filter(
        (item) => item.name !== roomNum
      );

      dispatch(
        updateTempSelectedRoomWTypeAndNum({
          ...tempSelectedRoomWTypeAndNum,
          [roomType]: removeSelectedRoom,
        })
      );
    } else {
      if (Boolean(tempSelectedRoomWTypeAndNum[roomType])) {
        let selectedRoomArray = tempSelectedRoomWTypeAndNum[roomType];

        dispatch(
          updateTempSelectedRoomWTypeAndNum({
            ...tempSelectedRoomWTypeAndNum,
            [roomType]: [...selectedRoomArray, roomArray],
          })
        );
      } else {
        dispatch(
          updateTempSelectedRoomWTypeAndNum({
            ...tempSelectedRoomWTypeAndNum,
            [roomType]: [roomArray],
          })
        );
      }
    }
  };

  const handleGuestChangeInRoomType = (
    roomTypeObj,
    occupancyPlanRate,
    roomTypeName,
    selectedRoomWTypeAndNum,
    mealPlan,
    detailedPriceBreakup,
    updateAdults,
    updateDetailedPriceBreakup,
    value
  ) => {
    let minPeople = detailedPriceBreakup?.filter(
      (Obj) => Obj?.type === roomTypeObj.name
    )?.length;

    const currentDetailedPriceBrekup = adultAssigneFunction({
      selectedRoomWTypeAndNum: selectedRoomWTypeAndNum,
      roomTypes: roomTypes,
      roomPriceStructure: roomPriceStructure,
      occupancyPlanRate: occupancyPlanRate,

      noOfPeople: value,
      detailedPriceBreakup: detailedPriceBreakup,

      selectedRoomTypeName: roomTypeName,
      mealPlan: mealPlan,

      combinedDefaultOccupancyOfAllRooms:
        roomTypeObj?.defaultOccupancy * minPeople,
      combinedMaxOccupancyOfAllRooms: roomTypeObj?.maxGuestAllowed * minPeople,
    });

    let noOfAdults = 0;
    currentDetailedPriceBrekup?.forEach(
      (Obj) => (noOfAdults += Obj?.noOfPeople)
    );

    dispatch(updateAdults(noOfAdults));
    dispatch(updateDetailedPriceBreakup(currentDetailedPriceBrekup));
  };

  const handleRoomIncDecChangeButton = (
    currentVal,
    type,
    selectedRoomWTypeAndNum,
    roomPriceStructure,
    detailedPriceBreakup,
    updateRoomChangeMade,
    roomTypeObj,
    adults,
    updateAdults,
    updateSelectedRoomWTypeAndNum,
    occupancyPlanRate,
    roomTypeName,
    mealPlan,
    updateDetailedPriceBreakup
  ) => {
    dispatch(updateRoomChangeMade(true));

    if (currentVal > roomTypeObj.availability) {
      message.destroy();
      message.info("No more rooms available for this room type");
      return;
    }

    let localAdults = adults;
    let currentAdults;

    if (!Object?.keys(selectedRoomWTypeAndNum)?.length) {
      localAdults = 0;
      dispatch(updateAdults(0));
    }

    if (type === "inc") {
      currentAdults = localAdults + roomTypeObj?.defaultOccupancy;
      dispatch(updateAdults(currentAdults));
    } else {
      currentAdults = localAdults - roomTypeObj?.defaultOccupancy;
      currentAdults = Boolean(currentAdults) > 0 ? currentAdults : 0;
      dispatch(updateAdults(currentAdults ?? 0));
    }

    let selectedRoom = [];

    for (let i = 0; i < currentVal; i++)
      selectedRoom.push(roomTypeObj?.rooms?.[i]);

    dispatch(
      updateSelectedRoomWTypeAndNum({
        selectedRoomType: roomTypeObj?.name,
        selectedRoomNumber: selectedRoom,
      })
    );

    const selectedRoomType = roomTypeObj?.name;
    const selectedRoomNumber = selectedRoom;

    // Merge the new room type and number into the existing data
    const updatedData = {
      ...selectedRoomWTypeAndNum,
      [selectedRoomType]: selectedRoomNumber,
    };

    // Filter out any entries where the room number array is empty
    const filteredRooms = Object.entries(updatedData)
      .filter(([_, roomNumbers]) => roomNumbers.length > 0)
      .reduce((acc, [roomType, roomNumbers]) => {
        acc[roomType] = roomNumbers;
        return acc;
      }, {});

    let currentDetailedPriceBrekup = adultAssigneFunction({
      selectedRoomWTypeAndNum: filteredRooms,
      roomTypes: roomTypes,
      roomPriceStructure: roomPriceStructure,
      occupancyPlanRate: occupancyPlanRate,
      noOfPeople: roomTypeObj?.defaultOccupancy * selectedRoom?.length,
      detailedPriceBreakup: detailedPriceBreakup,
      selectedRoomTypeName: roomTypeName,
      mealPlan: mealPlan,
      combinedDefaultOccupancyOfAllRooms:
        roomTypeObj?.defaultOccupancy * selectedRoom?.length,
      combinedMaxOccupancyOfAllRooms:
        roomTypeObj?.maxGuestAllowed * selectedRoom?.length,
    });

    let currentTotalAdults = 0;
    currentDetailedPriceBrekup?.forEach((Obj) => {
      currentTotalAdults += Obj?.noOfPeople;
    });

    dispatch(updateAdults(currentTotalAdults));
    dispatch(updateDetailedPriceBreakup(currentDetailedPriceBrekup));
  };

  const handleRoomIncDecChangeInput = (
    currentVal,
    roomPriceStructure,
    selectedRoomWTypeAndNum,
    detailedPriceBreakup,
    updateRoomChangeMade,
    roomTypeObj,
    updateSelectedRoomWTypeAndNum,
    occupancyPlanRate,
    roomTypeName,
    mealPlan,
    updateAdults,
    updateDetailedPriceBreakup
  ) => {
    dispatch(updateRoomChangeMade(true));

    if (currentVal > roomTypeObj.availability) {
      message.destroy();
      message.info(
        `Only ${roomTypeObj?.availability} is avialble for this roomType`
      );
      return;
    }

    let selectedRoom = [];

    for (let i = 0; i < currentVal; i++) {
      selectedRoom.push(roomTypeObj?.rooms?.[i]);
    }

    dispatch(
      updateSelectedRoomWTypeAndNum({
        selectedRoomType: roomTypeObj?.name,
        selectedRoomNumber: selectedRoom,
      })
    );

    const selectedRoomType = roomTypeObj?.name;
    const selectedRoomNumber = selectedRoom;

    // Merge the new room type and number into the existing data
    const updatedData = {
      ...selectedRoomWTypeAndNum,
      [selectedRoomType]: selectedRoomNumber,
    };

    // Filter out any entries where the room number array is empty
    const filteredRooms = Object.entries(updatedData)
      ?.filter(([_, roomNumbers]) => roomNumbers.length > 0)
      ?.reduce((acc, [roomType, roomNumbers]) => {
        acc[roomType] = roomNumbers;
        return acc;
      }, {});

    const currentDetailedPriceBrekup = adultAssigneFunction({
      selectedRoomWTypeAndNum: filteredRooms,
      roomTypes: roomTypes,
      roomPriceStructure: roomPriceStructure,
      occupancyPlanRate: occupancyPlanRate,
      noOfPeople: roomTypeObj?.defaultOccupancy * selectedRoom?.length,
      detailedPriceBreakup: detailedPriceBreakup,
      selectedRoomTypeName: roomTypeName,
      mealPlan: mealPlan,
      combinedDefaultOccupancyOfAllRooms:
        roomTypeObj?.defaultOccupancy * selectedRoom?.length,
      combinedMaxOccupancyOfAllRooms:
        roomTypeObj?.maxGuestAllowed * selectedRoom?.length,
    });

    let currentTotalAdults = 0;
    currentDetailedPriceBrekup?.forEach((Obj) => {
      currentTotalAdults += Obj?.noOfPeople;
    });

    dispatch(updateAdults(currentTotalAdults));
    dispatch(updateDetailedPriceBreakup(currentDetailedPriceBrekup));
  };

  return {
    handleCheckIn,
    handleCheckOut,
    handleUndoCheckIn,
    handleFolio,
    handleGrc,
    handleConfirmBooking,
    refund,
    handleAttachToParty,
    updateInvoicePricesAsDiscount,
    handleDeletePayment,
    handleBookingToBeCanceld,
    handleBookingToBeMarkedNoShow,
    handleUndoCheckOut,
    handleBookingToBeDeleted,
    handleRoomSelectionModalApplyButton,
    handleSelectedRoomChangeInRoomSelectionModal,
    handleGuestChangeInRoomType,
    handleRoomIncDecChangeButton,
    handleRoomIncDecChangeInput,
  };
};

export default useAllBookingFunction;
